import { useState } from "react"
// Components
import Box from "@mui/material/Box"
import { Row, VSpace, Text } from "@layout"
import { VFieldContainer, OneLineVFieldContainer } from "@components/common"
import { InputText } from "@components/inputs"
import File from "@components/file"
// Styles
// Libs
import { t } from "i18next"
import {
  statusOptionList,
  TC_titleOptionList,
  TC_maritalOptionList,
  TC_educationOptionList,
  fieldStatus
} from "@constants"
import { useTheme } from "@mui/styles"

const BasicInfo = ({ state, handleInputChange, handleSelectChange }) => {
  const theme = useTheme()
  const [file, setFile] = useState(null)

  return (
    <Box>
      <Row sx={{ justifyContent: "space-between", alignItems: "start !important" }}>
        <Box>
          <VFieldContainer
            index={0}
            width="296px"
            id={"status"}
            value={state.status.value}
            label={t("MODAL_TMProfile_Field_account_status")}
            type="select"
            list={statusOptionList}
            handleChange={handleSelectChange}
          />
          <VFieldContainer
            index={1}
            width="296px"
            id={"nameZh"}
            value={state.nameZh.value}
            label={t("MODAL_TMProfile_Field_name_ZH")}
            type="input"
            handleChange={handleInputChange}
            error={state.isSaveChecking && state.nameZh.fieldStatus !== fieldStatus.VALID}
            required={state.nameZh.isRequired}
          />
          <VFieldContainer
            index={2}
            width="296px"
            id={"nameEn"}
            value={state.nameEn.value}
            label={t("MODAL_TMProfile_Field_name_EN")}
            type="input"
            handleChange={handleInputChange}
          />
          <VFieldContainer
            index={3}
            width="296px"
            id={"staffNo"}
            value={state.staffNo.value}
            label={t("MODAL_TMProfile_Field_staffNo")}
            type="input"
            handleChange={handleInputChange}
            error={state.isSaveChecking && state.staffNo.fieldStatus !== fieldStatus.VALID}
            required={state.staffNo.isRequired}
          />
          <OneLineVFieldContainer
            width="296px"
            index={4}
            label={t("MODAL_TMProfile_Field_phone")}
            error={state.isSaveChecking && state.phone.fieldStatus !== fieldStatus.VALID}
            required={state.phone.isRequired}
          >
            <Text width="60px" textalign="center" style={{ borderRight: `1px solid ${theme.border.gray_3}` }}>
              852
            </Text>
            <InputText
              sx={{ width: "231px", height: "29px", border: "none !important" }}
              value={state.phone.value}
              onChange={(event) => handleInputChange("phone", "input", event.target.value)}
            />
          </OneLineVFieldContainer>
          <OneLineVFieldContainer
            width="296px"
            index={5}
            label={t("MODAL_TMProfile_Field_other_phone")}
            error={state.isSaveChecking && state.phone2.fieldStatus !== fieldStatus.VALID}
          >
            <Text width="60px" textalign="center" style={{ borderRight: `1px solid ${theme.border.gray_3}` }}>
              852
            </Text>
            <InputText
              sx={{ width: "231px", height: "29px", border: "none !important" }}
              value={state.phone2.value}
              onChange={(event) => handleInputChange("phone2", "input", event.target.value)}
            />
          </OneLineVFieldContainer>
          <VFieldContainer
            index={6}
            width="296px"
            id={"title"}
            value={state.title.value}
            label={t("MODAL_TMProfile_Field_title")}
            type="select"
            list={TC_titleOptionList}
            handleChange={handleSelectChange}
          />
          <VFieldContainer
            index={7}
            width="296px"
            id={"dob"}
            value={state.dob.value}
            label={t("MODAL_TMProfile_Field_date_of_birth")}
            type="datePicker"
            handleChange={handleSelectChange}
          />
          <VFieldContainer
            index={8}
            width="296px"
            id={"hkid"}
            value={state.hkid.value}
            label={t("MODAL_TMProfile_Field_hkid")}
            type="input"
            handleChange={handleInputChange}
          />
          <VFieldContainer
            index={9}
            width="296px"
            id={"nationality"}
            value={state.nationality.value}
            label={t("MODAL_TMProfile_Field_place_of_birth")}
            type="input"
            handleChange={handleInputChange}
          />
          <VFieldContainer
            index={10}
            width="296px"
            id={"marital"}
            value={state.marital.value}
            label={t("MODAL_TMProfile_Field_marital")}
            type="select"
            list={TC_maritalOptionList}
            handleChange={handleSelectChange}
          />
          <VFieldContainer
            index={11}
            width="296px"
            id={"education"}
            value={state.education.value}
            label={t("MODAL_TMProfile_Field_education")}
            type="select"
            list={TC_educationOptionList}
            handleChange={handleSelectChange}
          />
          <VFieldContainer
            index={12}
            width="296px"
            id={"email"}
            value={state.email.value}
            label={t("MODAL_TMProfile_Field_email")}
            type="input"
            handleChange={handleInputChange}
            error={state.isSaveChecking && state.email.fieldStatus !== fieldStatus.VALID}
          />
          <VFieldContainer
            index={13}
            width="296px"
            id={"emergencyContactName"}
            value={state.emergencyContactName.value}
            label={t("MODAL_TMProfile_Field_emergency_name")}
            type="input"
            handleChange={handleInputChange}
          />
          <VFieldContainer
            index={14}
            width="296px"
            id={"emergencyContactRelation"}
            value={state.emergencyContactRelation.value}
            label={t("MODAL_TMProfile_Field_emergency_relation")}
            type="input"
            handleChange={handleInputChange}
          />
          <OneLineVFieldContainer
            width="296px"
            index={15}
            label={t("MODAL_TMProfile_Field_emergency_phone")}
            error={state.isSaveChecking && state.emergencyContactPhone.fieldStatus !== fieldStatus.VALID}
          >
            <Text width="60px" textalign="center" style={{ borderRight: `1px solid ${theme.border.gray_3}` }}>
              852
            </Text>
            <InputText
              sx={{ width: "231px", height: "29px", border: "none !important" }}
              value={state.emergencyContactPhone.value}
              onChange={(event) => handleInputChange("emergencyContactPhone", "input", event.target.value)}
            />
          </OneLineVFieldContainer>
          <VFieldContainer
            index={16}
            height="70px"
            width="296px"
            id={"remark"}
            value={state.remark.value}
            label={t("MODAL_TMProfile_Field_remark")}
            type="input-textarea"
            handleChange={handleInputChange}
          />
        </Box>
        <Box>
          <Text size="14px" weight="500">
            {t("MODAL_TMProfile_Field_photo")}
          </Text>
          <VSpace length={45} />
          <File
            id={"profileImageUrl"}
            error={false}
            fileURL={state.profileImageUrl.value}
            fileName="TM profile photo"
            handleAddFile={handleSelectChange}
            handleRemoveFile={handleSelectChange}
          />
        </Box>
      </Row>
    </Box>
  )
}

export default BasicInfo
