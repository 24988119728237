import { createSlice } from "@reduxjs/toolkit"
import { isInvalidDigits } from "@utils/validator"
import { cloneDeep, findIndex } from "lodash"

const makeFieldsBase = (list = [], detailIds = []) => {
  let noCheckedIndex = detailIds.length + 1

  detailIds.forEach((item, index) => {
    let findItemIndex = findIndex(list, (listItem) => listItem.serviceDetailId.toString() == item.toString())

    if (findItemIndex >= 0) {
      list[findItemIndex].checked = true
      list[findItemIndex].sequence = index + 1
    }
  })

  let state = list.map((item, index) => {
    if (!item.checked) {
      item.checked = false
      item.sequence = noCheckedIndex
      noCheckedIndex++
    }

    return item
  })

  return state
}

export const initialState = makeFieldsBase([])

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    setInitialState(state, { payload }) {
      let { list, detailIds } = payload

      return makeFieldsBase(cloneDeep(list), detailIds)
    },
    checkFieldChange(state, { payload }) {
      state[payload.index].checked = !state[payload.index].checked
    },
    inputFieldChange(state, { payload }) {
      state[payload.index].sequence = payload.value
    }
  }
})

export const convertToPayload = (state) => {
  let checkedList = state.filter((item) => item.checked)

  checkedList = checkedList.sort((a, b) => a.sequence - b.sequence)

  return checkedList.map((item) => item.serviceDetailId)
}

export const isInvalidSaveChecking = (state) => {
  return state.some((item) => item.checked && isInvalidDigits(item.sequence))
}

export const { setInitialState, checkFieldChange, inputFieldChange, isSaveChecking } = mainReducer.actions

export default mainReducer.reducer
