import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeFieldWithInvalid } from "@utils/makeField"
import { invalidateEmail, invalidatePassword } from "@utils/validator"
import { forEach, every } from "lodash"

const makeFieldsBase = (payload) => {
  return {
    name: makeFieldWithInvalid(payload.name, "name", fieldStatus.VALID, true, false),
    email: makeFieldWithInvalid(payload.email, "email", fieldStatus.VALID, true, false),
    typeId: makeFieldWithInvalid(payload.typeId, "typeId", fieldStatus.VALID, true, false),
    state: makeFieldWithInvalid(payload.state, "state", fieldStatus.VALID, true, false),
    password: makeFieldWithInvalid("", "password", fieldStatus.VALID, false, false),
    confirmPassword: makeFieldWithInvalid("", "confirmPassword", fieldStatus.VALID, false, false)
  }
}

export const initialState = makeFieldsBase({
  name: "",
  email: "",
  typeId: "",
  state: "",
  password: "",
  confirmPassword: ""
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState,
  reducers: {
    defaultSetting(state, { payload }) {
      return payload ? makeFieldsBase(payload) : initialState
    },
    fieldChange(state, { payload }) {
      let { id, value } = payload
      state[id].value = value
      state[id].fieldStatus = fieldStatus.VALID
    },
    validator(state, { payload }) {
      if (payload) {
        state.password.isRequired = true
        state.confirmPassword.isRequired = true
      }

      forEach(state, (item, path) => {
        if (item.isRequired) {
          if (item.value === "") {
            state[path].fieldStatus = fieldStatus.EMPTY
          } else if (path === "email" && invalidateEmail(item.value)) {
            state[path].fieldStatus = fieldStatus.ERROR
          } else if (path === "password" && invalidatePassword(item.value)) {
            state[path].fieldStatus = fieldStatus.ERROR
          } else if (path === "confirmPassword" && state.password.value !== item.value) {
            state[path].fieldStatus = fieldStatus.ERROR
          }
        } else if (path === "password" && item.value && invalidatePassword(item.value)) {
          state[path].fieldStatus = fieldStatus.ERROR
        } else if (path === "confirmPassword" && state.password.value !== item.value) {
          state[path].fieldStatus = fieldStatus.ERROR
        }
        state[path].invalid = item.fieldStatus !== fieldStatus.VALID
      })
    }
  }
})

export const validateCheck = (state, { isCreate }) => {
  return every(state, ({ value, isRequired }, path) => {
    if (isRequired) {
      return !(value === "" || (path === "email" && invalidateEmail(value)))
    } else {
      if (isCreate) {
        return !(
          (path === "password" && (value === "" || invalidatePassword(value))) ||
          (path === "confirmPassword" && (value === "" || state.password.value !== value))
        )
      } else {
        return !(
          (path === "password" && value && invalidatePassword(value)) ||
          (path === "confirmPassword" && state.password.value !== value)
        )
      }
    }
  })
}
export const convertToPayload = (state) => {
  let payload = {}
  forEach(state, ({ value }, key) => {
    if (value) {
      payload[key] = value
    }
  })
  payload.confirmPassword && delete payload.confirmPassword
  return payload
}

export const { fieldChange, validator, defaultSetting } = mainReducer.actions
export default mainReducer.reducer
