import { useState } from "react"

import { ButtonBase } from "@components/buttons"
import { PopoverScore } from "@components/popover"

const PopoverScoreField = ({ fieldPath, fieldProps, value }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const config = {
    id: value.orderNo,
    name: value.mainOrder.customer.name,
    service: value.mainOrder.service.name,
    date: value.date,
    rating: value.tmUser.rating / 100
  }

  return (
    <>
      <ButtonBase label={fieldProps.text} onClick={handlePopoverClick} {...fieldProps.style} />
      <PopoverScore
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onConfirm={(popoverData) => fieldProps.handleClickConfirm({ ...popoverData, ...value })}
        config={config}
      />
    </>
  )
}

export default PopoverScoreField
