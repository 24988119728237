import { createSlice } from "@reduxjs/toolkit"
import { t } from "i18next"

export const tabInitialState = {
  currentTab: 0,
  list: [
    { label: t("TAB_basic_info"), error: false },
    { label: t("TAB_address"), error: false },
    { label: t("TAB_certificate"), error: false },
    { label: t("TAB_bank_info"), error: false },
    { label: t("TAB_other_info"), error: false }
  ]
}

const tabReducer = createSlice({
  name: "tabReducer",
  initialState: tabInitialState,
  reducers: {
    tabResetAll() {
      return tabInitialState
    },
    setCurrentTab(state, { payload }) {
      state.currentTab = payload
    },
    setErrorTab(state, { payload }) {
      state.list = payload
    }
  }
})

export const { tabResetAll, setCurrentTab, setErrorTab } = tabReducer.actions
export default tabReducer.reducer
