import { useEffect, useState, useReducer } from "react"
import { useDispatch } from "react-redux"

import { VFieldContainer } from "@components/common"
import { ModalOverlay, ModalMessage } from "@components/modals"
import BasicTabs from "@components/tabs"
import { Column } from "@layout"

import { stateOptionList } from "@constants"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"
import { getAdminTypes } from "@slices/admin-types"
import { getAdminById, postAdmin, putAdminById } from "@slices/admins"
import mainReducer, {
  initialState,
  fieldChange,
  validator,
  defaultSetting,
  validateCheck,
  convertToPayload
} from "./reducers"

const ModalAdminProfile = ({ profile, update, ...props }) => {
  const dispatch = useDispatch()

  const [state, reducerDispatch] = useReducer(mainReducer, initialState)
  const [adminTypeList, setAdminTypeList] = useState()

  const [errorMessage, setErrorMessage] = useState()

  const convertToOptionList = (payload) => {
    return payload.map(({ adminTypeId, name }) => ({ id: adminTypeId, label: name }))
  }

  useEffect(() => {
    if (props.open) {
      reducerDispatch(defaultSetting(profile))
      dispatchGetAdminTypes()
    }
  }, [props.open])

  const dispatchGetAdminTypes = async () => {
    const { results } = await dispatch(getAdminTypes()).unwrap()
    console.log(results)
    setAdminTypeList(convertToOptionList(results))
  }

  let fieldsList = [
    { id: "name", label: t("MODAL_AdminProfile_name"), type: "input" },
    { id: "email", label: t("MODAL_AdminProfile_email"), type: "input" },
    {
      id: "typeId",
      label: t("MODAL_AdminProfile_operator_type"),
      type: "select",
      list: adminTypeList
    },
    {
      id: "state",
      label: t("MODAL_AdminProfile_state"),
      type: "select",
      list: stateOptionList
    },
    { id: "password", label: t(`MODAL_AdminProfile_${Boolean(profile) ? "re" : ""}set_password`), type: "input" },
    {
      id: "confirmPassword",
      label: t(`MODAL_AdminProfile_${Boolean(profile) ? "re" : ""}set_password_confirm`),
      type: "input"
    }
  ]

  const handleChange = (id, type, value) => {
    // console.log("handleChange : ", { id, type, value })
    reducerDispatch(fieldChange({ id, value }))
  }

  // console.log(state)

  const handleSave = () => {
    // console.log("handleSave : ", { state }, validateCheck(state, profile))
    if (validateCheck(state, profile)) {
      dispatchProfile()
    } else {
      reducerDispatch(validator(!Boolean(profile)))
    }
  }

  const dispatchProfile = async () => {
    try {
      if (profile) {
        const payload = {
          adminId: profile.adminId,
          adminInfo: convertToPayload(state)
        }
        await dispatch(putAdminById(payload)).unwrap()
      } else {
        const payload = convertToPayload(state)
        console.log({ payload })
        await dispatch(postAdmin(payload)).unwrap()
      }
      update()
      dispatch(getAdminById()).unwrap()
      props.onClose()
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSave }]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <BasicTabs list={[{ label: t("TAB_basic_info") }]}>
          <Column>
            {state &&
              fieldsList.map((config, index) => (
                <VFieldContainer
                  key={`modal field ${config.id}`}
                  index={index}
                  width="200px"
                  value={state[config.id]?.value}
                  error={state[config.id]?.invalid}
                  handleChange={handleChange}
                  required={state[config.id]?.isRequired}
                  {...config}
                />
              ))}
          </Column>
        </BasicTabs>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalAdminProfile
