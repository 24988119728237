import { styled } from "@mui/styles"
import { t } from "i18next"
import { formatDateOnly } from "@utils/formatter"

import { Popover, Box } from "@mui/material"
import { ButtonWithImage } from "@components/buttons"
import { Text, VSpace, HSpace, Row, Column, Link } from "@layout"
import { InputBase } from "@components/inputs"
import { useState } from "react"

export const PopoverBase = styled((props) => (
  <Popover
    {...props}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: "30px",
    borderRadius: "3px",
    border: "solid 1px",
    borderColor: theme.border.gray_3
  }
}))

export const Content = styled(Box)(({ theme }) => ({
  padding: "34px 20px",
  backgroundColor: theme.palette.background.default
}))

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "16px 18px",
  backgroundColor: theme.palette.background.gray_opacity,
  gap: "20px"
}))

export const PopoverWrapper = styled(Box)(() => ({
  position: "relative",
  width: "400px"
}))

export const ScoreContent = ({ config, onClose, handleConfirmClick }) => {
  const { id, name, service, date, rating } = config

  const initialState = { adjustment: "", remark: "" }
  const [ratingInfo, setRatingInfo] = useState(initialState)

  const handleChange = (id, value) => {
    setRatingInfo({ ...ratingInfo, [id]: value })
  }

  const onConfirm = () => {
    handleConfirmClick(ratingInfo)
    setRatingInfo(initialState)
  }

  return (
    <>
      <Content>
        {onClose && (
          <>
            <Text size="16px" weight="500">
              {t("POPOVER_SCORE_title")}-<Link>{id}</Link>
            </Text>
            <VSpace length="13" />
            <Row sx={{ justifyContent: "space-between" }}>
              <Column>
                <Text weight="500">{t("POPOVER_SCORE_user_name")}</Text>
                <Text weight="300">{name}</Text>
              </Column>
              <Column>
                <Text weight="500">{t("POPOVER_SCORE_type")}</Text>
                <Text weight="300">{service}</Text>
              </Column>
              <Column>
                <Text weight="500">{t("POPOVER_SCORE_date")}</Text>
                <Text weight="300">{formatDateOnly(date)}</Text>
              </Column>
            </Row>
            <VSpace length="22" />
          </>
        )}
        <Row sx={{ justifyContent: "space-between" }}>
          <Text weight="500">
            {t("POPOVER_SCORE_score_total")}:{rating}
          </Text>
          <Row>
            <Text weight="500">{t("POPOVER_SCORE_modify_score")}:</Text>
            <HSpace length="10" />
            <InputBase
              type="number"
              value={ratingInfo.adjustment}
              sx={{ height: "25px", width: "120px" }}
              bordercolor="gray_3"
              radius="4px"
              size="14px"
              weight="500"
              onChange={(event) => handleChange("adjustment", event.target.value)}
            />
          </Row>
        </Row>
        <VSpace length="25" />
        <Column>
          <Text weight="500">{t("TABLE_score_remark")}</Text>
          <VSpace length="5" />
          <InputBase
            value={ratingInfo.remark}
            sx={{ height: "25px" }}
            bordercolor="gray_3"
            radius="4px"
            size="14px"
            weight="500"
            onChange={(event) => handleChange("remark", event.target.value)}
          />
        </Column>
      </Content>
      <ButtonWrapper>
        {onClose && <ButtonWithImage label={t("TEXT_COMMON_cancel")} onClick={onClose} src="no" color="gray_white" />}
        <ButtonWithImage
          imgW="15px"
          imgH="15px"
          label={t("POPOVER_SCORE_confirm")}
          onClick={onConfirm}
          src="vector"
          color="success_light"
        />
      </ButtonWrapper>
    </>
  )
}
