import React, { useState } from "react"
import { Tab, Tabs, TabDivider, TabPanel } from "./index.styles"
import Box from "@mui/material/Box"

export default function BasicTabs({
  color = "primary",
  list,
  divider,
  children,
  handleTabChange,
  defaultTab = 0,
  height = "100%",
  ...rest
}) {
  // color : primary = yellow, secondary = gray
  const [value, setValue] = useState(defaultTab)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    handleTabChange && handleTabChange(newValue)
  }

  let childrenList = children.length ? children.filter((item) => item) : []

  return (
    <Box sx={{ width: "100%", height, position: "relative" }}>
      <Box sx={{ position: "relative" }}>
        <Tabs color={color} value={value} onChange={handleChange}>
          {list?.map((tab, index) => {
            let { error, ...rest } = tab
            return <Tab key={`tab ${tab.label} ${index}`} color={color} error={error ? 1 : 0} {...rest} />
          })}
        </Tabs>
        <TabDivider divider={divider} />
      </Box>
      {childrenList.length > 1 ? (
        childrenList.map((child, index) => (
          <TabPanel key={`tab ${index}`} value={value} index={index}>
            {child}
          </TabPanel>
        ))
      ) : (
        <TabPanel value={0} index={0}>
          {children}
        </TabPanel>
      )}
    </Box>
  )
}
