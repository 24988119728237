import { useState, useReducer, useEffect } from "react"
// Components
import BasicTabs from "@components/tabs"
import { HSpace, Row } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import ModalBase from "../modal-base"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
import BasicInfo from "./basic-info"
import ServiceAddress from "./service-address"
import HealthCondition from "./health-condition"
import AddNewAddress from "./add-new-address"
// Styles
// Libs
import { t } from "i18next"
import mainReducer, {
  initialState,
  setProfileByApi,
  saveNewAddress,
  isSaveChecking,
  resetAll,
  handleSaveChecking
} from "./reducers"
import addrReducer, {
  addrInitialState,
  newAddrIsSaveChecking,
  newAddrResetAll,
  handleSaveNewAddrChecking
} from "./reducers/add-new-address"
import tabReducer, { tabInitialState, tabResetAll, setCurrentTab, setErrorTab } from "./reducers/tab-list"
import { cloneDeep } from "lodash"
import { convertToPayload } from "./reducers"

// linkToAddress = ["new", "userAddressId"]

const ModalUserProfile = ({ open, profile, onClose, onSave, linkToAddress = null }) => {
  const [state, reducerDispatch] = useReducer(mainReducer, initialState)
  const [newAddrState, addrReducerDispatch] = useReducer(addrReducer, addrInitialState)
  const [tabState, tabReducerDispatch] = useReducer(tabReducer, tabInitialState)

  const [showAddAddrBtn, setShowAddAddrBtn] = useState(false)
  const [showNewAddrContent, setShowNewAddrContent] = useState(false)

  useEffect(() => {
    if (open) {
      if (linkToAddress) {
        handleTabChange(1)
        if (linkToAddress === "new") {
          setShowNewAddrContent(true)
        }
      }
      if (profile?.userId) {
        reducerDispatch(setProfileByApi({ profile: cloneDeep(profile), linkToAddress }))
      }
    }
  }, [open, profile, linkToAddress])

  const handleTabChange = (index) => {
    if (index === 1) {
      setShowAddAddrBtn(true)
    } else {
      setShowAddAddrBtn(false)
    }
    tabReducerDispatch(setCurrentTab(index))
  }

  const handleSaveClick = () => {
    if (showNewAddrContent) {
      addrReducerDispatch(newAddrIsSaveChecking(true))
      if (handleSaveNewAddrChecking(newAddrState)) {
        handleNewAddressClose()
        reducerDispatch(saveNewAddress(newAddrState))
      }
    } else {
      reducerDispatch(isSaveChecking(true))
      let { isPass, tabList } = handleSaveChecking(state)
      if (isPass) {
        console.log(convertToPayload(state))
        onSave && onSave(convertToPayload(state))
        handleCloseClick()
      } else {
        console.log(state)
        console.log({ isPass, tabList })
        tabReducerDispatch(setErrorTab(tabList))
      }
    }
  }

  const handleNewAddressClose = () => {
    setShowNewAddrContent(false)
    addrReducerDispatch(newAddrResetAll())
  }

  const handleCloseClick = () => {
    tabReducerDispatch(tabResetAll())
    reducerDispatch(resetAll())
    handleTabChange(0)
    onClose && onClose()
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="720px" expand={true} sx={{ border: "none !important" }}>
        <Close onClick={handleCloseClick} />

        {showNewAddrContent ? (
          <AddNewAddress
            state={newAddrState}
            reducerDispatch={addrReducerDispatch}
            handleBackClick={() => handleNewAddressClose()}
          />
        ) : (
          <BasicTabs list={tabState.list} handleTabChange={handleTabChange} defaultTab={tabState.currentTab}>
            <BasicInfo state={state} reducerDispatch={reducerDispatch} />
            <ServiceAddress state={state} reducerDispatch={reducerDispatch} />
            <HealthCondition state={state} reducerDispatch={reducerDispatch} />
          </BasicTabs>
        )}

        <ModalButtonWrapper color="yellow">
          <Row>
            {showAddAddrBtn && !showNewAddrContent ? (
              <ButtonWithImage {...buttonStyle} srcx="add" alt="add icon" onClick={() => setShowNewAddrContent(true)}>
                {t("MODAL_AddUser_Button_add_address")}
              </ButtonWithImage>
            ) : null}
            <HSpace length={10} />
            <ButtonWithImage {...buttonStyle} srcx="save-black" alt="save icon" onClick={() => handleSaveClick()}>
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  textcolor: "primary",
  borderradius: "13px",
  padding: "7px 33px",
  border: "transparent"
}

export default ModalUserProfile
