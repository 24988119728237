import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeField } from "@utils/makeField"
import assignFieldToPayload from "@utils/assignFieldToPayload"
import { cloneDeep, forEach } from "lodash"
import { isEmpty } from "@utils/validator"

const makeFieldsBase = (payload) => {
  let state = {
    isSaveChecking: false,
    bank: makeField(payload.bank, "bank", fieldStatus.EMPTY, true),
    number: makeField(payload.number, "number", fieldStatus.EMPTY, true),
    amount: makeField(payload.amount, "amount", fieldStatus.EMPTY, true),
    remarks: makeField(payload.remarks, "remarks", fieldStatus.VALID, false)
  }

  forEach(state, (field, key) => {
    if (field?.isRequired && field.fieldStatus) {
      field.fieldStatus = field.value ? fieldStatus.VALID : fieldStatus.EMPTY
    }
  })

  return state
}

export const initialState = makeFieldsBase({
  bank: "",
  number: "",
  amount: "",
  remarks: ""
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    resetAll() {
      return initialState
    },
    inputFieldChange(state, { payload }) {
      let { id, type, data } = payload

      if (id === "amount") {
        state[id] = {
          ...state[id],
          value: isEmpty(data) ? "" : data,
          fieldStatus: fieldStatus.VALID
        }
      } else {
        state[id] = {
          ...state[id],
          value: data,
          fieldStatus: fieldStatus.VALID
        }
      }

      if (state[id].isRequired) {
        state[id].fieldStatus = data ? fieldStatus.VALID : fieldStatus.EMPTY
      }
    },
    isSaveChecking(state, { payload }) {
      state.isSaveChecking = payload
    }
  }
})

export const convertToPayload = (state) => {
  let payload = {}

  forEach(state, (data, key) => {
    if (data?.fieldPath) {
      if (key === "amount") {
        assignFieldToPayload(payload, data.value ? data.value * 100 : 0, data.fieldPath)
      } else {
        assignFieldToPayload(payload, data.value, data.fieldPath)
      }
    }
  })

  return payload
}

export const handleSaveChecking = (state) => {
  let isPass = true

  forEach(state, (field, key) => {
    if (field && (field.fieldStatus === fieldStatus.EMPTY || field.fieldStatus === fieldStatus.ERROR)) {
      isPass = false
    }
  })

  return isPass
}

export const { resetAll, inputFieldChange, isSaveChecking } = mainReducer.actions

export default mainReducer.reducer
