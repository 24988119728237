import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ModalOverlay from "../modal-overlay"
import ModalMessage from "../modal-message"
import { Column, Row, Text, HSpace } from "@layout"
import { ImgBase } from "@components/images"
import { PerfectMatchItem, CounterOfferItem } from "./index.styles"

import { isEmpty } from "@utils/validator"
import { t } from "i18next"
import { getMatching } from "@slices/orders"

const ModalAutoMatch = ({ orderId, customerNo, onConfirm, selected, ...props }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState()
  const [perfectMatches, setPerfectMatches] = useState()
  const [counterOffers, setCounterOffers] = useState()

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      dispatcGetMatching()
      setState(selected)
    }
  }, [props.open])

  const dispatcGetMatching = async () => {
    let results = await dispatch(getMatching(orderId)).unwrap()

    setPerfectMatches(results.perfectMatches.map(({ tm }) => ({ ...tm })))
    setCounterOffers(results.counterOffers.map(({ tm, ...rest }) => ({ ...tm, ...rest })))
  }

  const handleChange = (value) => {
    setState(value.id === state?.id && value.date === state?.date ? "" : value)
  }

  const handleConfirm = () => {
    if (state) {
      onConfirm(state)
    } else {
      setErrorMessage({ message: t("ERROR_COMMON_please_select_match") })
    }
  }

  const buttonList = [
    {
      label: t("OPTION_COMMON_no"),
      src: "white-delete",
      textcolor: "white",
      onClick: props.onClose,
      imgW: "15px",
      color: "secondary"
    },
    {
      label: t("OPTION_COMMON_yes"),
      src: "check-white",
      textcolor: "white",
      onClick: handleConfirm,
      imgW: "15px"
    }
  ]

  const fieldList = [
    { label: t("MODAL_Manual_Match_perfect_match"), list: perfectMatches, Component: PerfectMatchItem, show: true },
    {
      label: t("MODAL_Manual_Match_counter_offer"),
      list: counterOffers,
      Component: CounterOfferItem,
      show: isEmpty(perfectMatches)
    }
  ]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList} buttonWrapperColor="gray">
        <Column sx={{ padding: "22px", overflowY: "auto", height: "100%" }}>
          {fieldList.map(({ label, list, Component, show }, key) => (
            <Fragment key={`${label} ${key}`}>
              {show && (
                <>
                  <Row sx={{ margin: "20px 0" }}>
                    <ImgBase src="cursor-black" width="15px" />
                    <HSpace length={18} />
                    <Text size="18px">{label}</Text>
                  </Row>
                  {isEmpty(list) ? (
                    <Column sx={{ height: "60px", paddingLeft: "30px" }}>
                      <Text color="gray_3" size="16px" weight="500">
                        {t("MODAL_Manual_Match_no_suitable_tm")}
                      </Text>
                    </Column>
                  ) : (
                    list?.map((item, key) => (
                      <Component
                        key={`${label} ${key}`}
                        handleChange={() => handleChange(item)}
                        checked={item.id === state?.id && item.date === state?.date}
                        {...item}
                      />
                    ))
                  )}
                </>
              )}
            </Fragment>
          ))}
        </Column>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalAutoMatch
