import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ModalOverlay from "../modal-overlay"
import ModalAutoMatch from "./modal-auto-match"
import ModalMessage from "../modal-message"
import ModalExceed418 from "../modal-exceed-418"
import { Column, VSpace, Row, Text } from "@layout"
import { InputSearch } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"
import { ButtonWithImage } from "@components/buttons"
import { PerfectMatchItem, CounterOfferItem } from "./index.styles"

import { getOrderTMsById, postCheck418 } from "@slices/orders"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"

const ModalMatchListing = ({ tm, orderId, customerNo, onConfirm, ...props }) => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")

  const [state, setState] = useState()
  const [list, setList] = useState([])
  const [under418Limit, setUnder418Limit] = useState(true)

  const [open, setOpen] = useState(false)

  const [errorMessage, setErrorMessage] = useState()
  const [modalExceed418Open, setModalExceed418Open] = useState(false)

  useEffect(() => {
    if (props.open) {
      setList([])
      dispatcGetOrderTMsById()
      setSearch("")
      setState(tm)
    }
  }, [props.open])

  useEffect(() => {
    if (state) {
      dispatch(postCheck418({ orderId, tmUserId: state.id }))
        .unwrap()
        .then(({ under418Limit }) => {
          setUnder418Limit(under418Limit)
          if (!under418Limit) {
            setModalExceed418Open(true)
          }
        })
    }
  }, [state])

  const dispatcGetOrderTMsById = async (keyword) => {
    const { results } = await dispatch(getOrderTMsById({ orderId, keyword })).unwrap()
    setList(results)
  }

  const handleChange = (value) => {
    setState(value.id === state?.id && value.date === state?.date ? "" : value)
  }

  const handleConfirm = () => {
    if (state) {
      onConfirm({ tm: state, under418Limit })
    } else {
      setErrorMessage({ message: t("ERROR_COMMON_please_select_match") })
    }
  }

  const buttonList = [{ label: t("TEXT_COMMON_confirm"), srcx: "save-black", onClick: handleConfirm }]

  const modalAutoMatchConfig = {
    orderId,
    customerNo,
    open,
    selected: state,
    onClose: () => setOpen(false),
    onConfirm: (data) => {
      setState(data)
      setOpen(false)
    }
  }

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  const modalExceed418Config = {
    onClose: () => setModalExceed418Open(false),
    onCancal: () => {
      setState()
      setUnder418Limit(true)
    },
    open: modalExceed418Open
  }

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <Column sx={{ padding: "22px", height: "-webkit-fill-available" }}>
          <Row sx={{ justifyContent: "space-between" }}>
            <ButtonWithImage
              label={t("MODAL_Manual_Match_auto_match")}
              onClick={() => setOpen(true)}
              src="cursor-black"
              width="152px"
              color="primary_dark"
            />
            {state && (
              <Column>
                <Text weight="500">{t("MODAL_Manual_Match_selected_match")}</Text>
                <VSpace length="40" />
                {state?.date ? (
                  <CounterOfferItem {...state} checked={null} />
                ) : (
                  <PerfectMatchItem {...state} checked={null} />
                )}
              </Column>
            )}
          </Row>
          {!state && <VSpace length="30" />}
          <Text weight={500}>{t("MODAL_Manual_Match_manual_match")}</Text>
          <VSpace length="10" />
          <InputSearch
            width="100%"
            value={search}
            handleChange={setSearch}
            handleSearch={() => dispatcGetOrderTMsById(search)}
          />
          <VSpace length="15" />
          <Column sx={{ overflowY: "auto" }}>
            {list.map((item) => (
              <Row key={`modal manual listing ${item.id}`} sx={{ margin: "10px 0" }}>
                <CheckboxBase
                  round={false}
                  label={`${item.nameZh}#${item.phone}`}
                  onChange={() => handleChange(item)}
                  checked={item.id === state?.id && item.date === state?.date}
                />
              </Row>
            ))}
          </Column>
        </Column>
      </ModalOverlay>
      <ModalAutoMatch {...modalAutoMatchConfig} />
      <ModalMessage {...modalMessageConfig} />
      <ModalExceed418 {...modalExceed418Config} />
    </>
  )
}

export default ModalMatchListing
