import { useState, useReducer, useEffect } from "react"
import { useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import BasicTabs from "@components/tabs"
import { ButtonWithImage } from "@components/buttons"
import { HSpace, Row } from "@layout"
import ModalBase from "../modal-base"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
import { LineButton, ImgDelete } from "./index.styles"
import { VFieldContainer } from "@components/common"
// Styles
// Libs
import { t } from "i18next"
import { useTheme } from "@mui/styles"
import mainReducer, {
  initialState,
  resetAll,
  setInitialState,
  setSelectedIndex,
  selectFieldChange,
  addNewRoute,
  deleteRoute,
  isSaveChecking,
  convertToPayload,
  isInvalidSaveChecking
} from "./reducers"
import { fieldStatus } from "@constants"
import { getServiceLinesById, putServiceLinesById } from "@slices/service-lines"
import { getServicesForRoute } from "@slices/services"

const tabList = [{ label: t("TAB_service_level") }]

const ModalServiceLevel = ({ open, onClose, onError, serviceId, serviceType }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [state, reducerDispatch] = useReducer(mainReducer, initialState)
  const [serviceList, setServiceList] = useState(null)

  useEffect(() => {
    if (open) {
      dispatchGetServices()
      if (serviceId) {
        dispatchGetLinesByServiceId(serviceId)
      }
    }
  }, [open, serviceId])

  const dispatchGetServices = async () => {
    let response = await dispatch(getServicesForRoute({ serviceType })).unwrap()
    setServiceList(response)
  }

  const dispatchGetLinesByServiceId = async (serviceId) => {
    let { results } = await dispatch(getServiceLinesById(serviceId)).unwrap()

    reducerDispatch(setInitialState({ list: results, serviceId }))
  }

  const handleLevelSelect = (id, type, value, selectItem) => {
    reducerDispatch(selectFieldChange({ id, value }))
  }

  const handleSaveClick = () => {
    if (isInvalidSaveChecking(state)) {
      reducerDispatch(isSaveChecking(true))
    } else if (serviceId) {
      dispatch(putServiceLinesById({ serviceId, lineInfo: { lines: convertToPayload(state) } }))
        .unwrap()
        .then((res) => {
          handleCloseClick()
        })
        .catch((error) => {
          onError && onError(error)
        })
    }
  }

  const handleCloseClick = () => {
    reducerDispatch(resetAll())
    setServiceList(null)
    onClose && onClose()
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="1110px" expand={true} sx={{ border: "none !important" }}>
        <Close onClick={handleCloseClick} />

        <BasicTabs list={tabList}>
          <Row sx={{ alignItems: "start !important", height: "100%" }}>
            <Box
              sx={{
                width: "254px",
                minHeight: "100%",
                paddingRight: "15px",
                marginRight: "45px",
                borderRight: `1px solid ${theme.border.gray}`
              }}
            >
              {state.routeList?.map((item, index) => (
                <Box sx={{ position: "relative" }} key={`LineButton_${index}`}>
                  <LineButton
                    selected={state.selectedIndex == index}
                    onClick={() => reducerDispatch(setSelectedIndex(index))}
                    error={state.isSaveChecking && item.lv1.fieldStatus !== fieldStatus.VALID ? "true" : "false"}
                  >
                    {t("MODAL_ServiceLevel_Field_route", { route: index + 1 })}
                  </LineButton>
                  {state.routeList.length > 1 ? (
                    <ImgDelete
                      src="yellow-delete"
                      alt="delete route"
                      width="10px"
                      height="10px"
                      onClick={() => reducerDispatch(deleteRoute(index))}
                    />
                  ) : null}
                </Box>
              ))}
            </Box>
            {serviceList ? (
              <Row sx={{ gap: "29px" }}>
                <VFieldContainer
                  index={0}
                  width="212px"
                  id={"lv1"}
                  value={getRouteState(state, "lv1", true)}
                  label={t("MODAL_ServiceLevel_Field_level", { level: 1 })}
                  type="select"
                  multiple={true}
                  list={serviceList.lv1}
                  handleChange={handleLevelSelect}
                  required={true}
                  error={
                    state.isSaveChecking && state.routeList[state.selectedIndex].lv1.fieldStatus !== fieldStatus.VALID
                  }
                />
                <VFieldContainer
                  index={0}
                  width="212px"
                  id={"lv2"}
                  value={getRouteState(state, "lv2", true)}
                  label={t("MODAL_ServiceLevel_Field_level", { level: 2 })}
                  type="select"
                  multiple={true}
                  list={serviceList.lv2}
                  handleChange={handleLevelSelect}
                />
                <VFieldContainer
                  index={0}
                  width="212px"
                  id={"lv3"}
                  value={getRouteState(state, "lv3", true)}
                  label={t("MODAL_ServiceLevel_Field_level", { level: 3 })}
                  type="select"
                  multiple={true}
                  list={serviceList.lv3}
                  handleChange={handleLevelSelect}
                />
              </Row>
            ) : null}
          </Row>
        </BasicTabs>

        <ModalButtonWrapper color="yellow">
          <Row>
            <ButtonWithImage {...buttonStyle} srcx="add" alt="add icon" onClick={() => reducerDispatch(addNewRoute())}>
              {t("TEXT_COMMON_add_route")}
            </ButtonWithImage>
            <HSpace length={10} />
            <ButtonWithImage {...buttonStyle} srcx="save-black" alt="save icon" onClick={() => handleSaveClick()}>
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const getRouteState = (state, level, multiple) => {
  if (state.routeList[state.selectedIndex][level].value.length) {
    if (multiple) {
      return state.routeList[state.selectedIndex][level].value
    }
    return state.routeList[state.selectedIndex][level].value[0]
  }
  return multiple ? [] : ""
}

const buttonStyle = {
  weight: 500,
  textcolor: "primary",
  borderradius: "13px",
  padding: "7px 33px",
  border: "transparent"
}

export default ModalServiceLevel
