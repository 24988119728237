import { Text } from "@layout"
import { isEmpty } from "@utils/validator"
import { formatDateWithTime } from "@utils/formatter"

const DateField = ({ fieldPath, fieldProps, value }) => {
  let displayValue = isEmpty(value) ? "-" : formatDateWithTime(value)

  if (fieldProps?.format) {
    displayValue = isEmpty(value) ? "-" : fieldProps.format(value)
  }

  return (
    <Text whitespace="nowrap" {...fieldProps.style}>
      {displayValue}
    </Text>
  )
}

export default DateField
