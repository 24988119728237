import { t } from "i18next"

import { ButtonWithImage } from "@components/buttons"
import { Row, Column } from "@layout"

const ActionOrderField = ({ fieldPath, fieldProps = {}, value }) => {
  const buttonConfig = {
    borderradius: "13px",
    padding: "5px 0px",
    color: "secondary_black"
  }
  const { hideBtn } = value

  const handleClick = (event) => {
    const { id } = event.target
    fieldProps?.handleActionClick({ type: id, data: value })
  }
  return (
    <Column sx={{ gap: "5px" }}>
      <Row sx={{ gap: "5px" }}>
        <ButtonWithImage id="copy" hide={Number(hideBtn.copy)} onClick={handleClick} {...buttonConfig}>
          {t("TABLE_Button_copy")}
        </ButtonWithImage>
        {hideBtn.refund ? (
          <ButtonWithImage id="cancel" hide={Number(hideBtn.cancel)} onClick={handleClick} {...buttonConfig}>
            {t("TABLE_Button_cancel")}
          </ButtonWithImage>
        ) : (
          <ButtonWithImage id="refund" hide={Number(hideBtn.refund)} onClick={handleClick} {...buttonConfig}>
            {t("TABLE_Button_refund")}
          </ButtonWithImage>
        )}
        <ButtonWithImage id="addTime" hide={Number(hideBtn.addTime)} onClick={handleClick} {...buttonConfig}>
          {t("TABLE_Button_add_time")}
        </ButtonWithImage>
      </Row>
      {hideBtn.invoice ? null : (
        <ButtonWithImage id="createInvoice" fullWidth={true} onClick={handleClick} {...buttonConfig}>
          {t("TABLE_Button_create_invoices")}
        </ButtonWithImage>
      )}
    </Column>
  )
}

export default ActionOrderField
