import { PopoverBase, PopoverWrapper, ScoreContent } from "./index.styles"

const PopoverScore = ({ config, onConfirm, ...props }) => {
  const handleConfirmClick = (data) => {
    onConfirm && onConfirm(data)
    props.onClose()
  }

  return (
    <PopoverBase {...props}>
      <PopoverWrapper>
        <ScoreContent config={config} onClose={props.onClose} handleConfirmClick={handleConfirmClick} />
      </PopoverWrapper>
    </PopoverBase>
  )
}

export default PopoverScore
