import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { TMUsersApi } from "../apis"

export const getTMUsers = createAsyncThunk("TMUsers/getTMUser", async (payload) => {
  return await TMUsersApi.getTMUsers(payload)
})

export const postTMUser = createAsyncThunk("TMUsers/postTMUser", async (payload, { rejectWithValue }) => {
  try {
    await TMUsersApi.postTMUser(payload)
    return TMUsersApi.getTMUsers()
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getTMUserById = createAsyncThunk("TMUsers/getTMUserById", async (payload) => {
  return await TMUsersApi.getTMUserById(payload)
})

export const putTMUserById = createAsyncThunk(
  "TMUsers/putTMUserById",
  async ({ TMUserId, TMUserInfo }, { rejectWithValue }) => {
    try {
      return await TMUsersApi.putTMUserById({ TMUserId, TMUserInfo })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getTMPreferences = createAsyncThunk("TMUsers/getTMPreferences", async (payload, { rejectWithValue }) => {
  try {
    return await TMUsersApi.getTMPreferences(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const putTMPreferences = createAsyncThunk("TMUsers/putTMPreferences", async (payload, { rejectWithValue }) => {
  try {
    return await TMUsersApi.putTMPreferences(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getTMGroups = createAsyncThunk("TMUsers/getTMGroups", async (payload) => {
  return await TMUsersApi.getTMGroups(payload)
})

export const putTMGroup = createAsyncThunk("TMUsers/putTMGroup", async (payload) => {
  return await TMUsersApi.putTMGroup(payload)
})

const initialState = {}

const TMUsersSlice = createSlice({
  name: "tm users",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = TMUsersSlice.actions
export default TMUsersSlice.reducer
