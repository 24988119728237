import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeField } from "@utils/makeField"
import { forEach } from "lodash"

export const makeAddrFieldsBase = (payload) => {
  let petTypes = payload.pet ? payload.pet.types : []
  let petIsCaged = payload.pet ? payload.pet.isCaged : true

  let state = {
    isSaveChecking: false,
    district: makeField(payload.district, "district", fieldStatus.EMPTY, true),
    partition: makeField(payload.partition, "partition", fieldStatus.EMPTY, true),
    street: makeField(payload.street, "street", fieldStatus.VALID, false),
    estate: makeField(payload.estate, "estate", fieldStatus.VALID, false),
    building: makeField(payload.building, "building", fieldStatus.EMPTY, true),
    block: makeField(payload.block, "block", fieldStatus.VALID, false),
    floor: makeField(payload.floor, "floor", fieldStatus.EMPTY, true),
    unit: makeField(payload.unit, "unit", fieldStatus.EMPTY, true),
    buildingType: makeField(payload.buildingType, "buildingType", fieldStatus.VALID, true),
    saleableArea: makeField(payload.saleableArea, "saleableArea", fieldStatus.EMPTY, true),
    hasLift: makeField(payload.hasLift, "hasLift", fieldStatus.VALID, true),
    hasPet: makeField(payload.hasPet, "hasPet", fieldStatus.VALID, true),
    petTypes: makeField(petTypes, "pet.types", fieldStatus.VALID, payload.hasPet),
    petIsCaged: makeField(petIsCaged, "pet.isCaged", fieldStatus.VALID, false)
  }

  if (payload.userAddressId) {
    state.userAddressId = payload.userAddressId
  }

  forEach(state, (field, key) => {
    if (field?.isRequired && field.fieldStatus) {
      if (key == "petTypes") {
        field.fieldStatus = field.value.length == 0 ? fieldStatus.EMPTY : fieldStatus.VALID
      } else {
        field.fieldStatus = field.value === "" ? fieldStatus.EMPTY : fieldStatus.VALID
      }
    }
  })

  return state
}

export const addrInitialState = makeAddrFieldsBase({
  district: "",
  partition: "",
  street: "",
  estate: "",
  building: "",
  block: "",
  floor: "",
  unit: "",
  buildingType: "tong lau",
  saleableArea: "",
  hasLift: true,
  hasPet: true,
  pet: {
    types: [],
    isCaged: true
  }
})

const addrReducer = createSlice({
  name: "addrReducer",
  initialState: addrInitialState,
  reducers: {
    newAddrResetAll() {
      return addrInitialState
    },
    newAddrFieldChange(state, { payload }) {
      let { id, data } = payload

      state[id].value = data
      state[id].fieldStatus = state[id].isRequired && data === "" ? fieldStatus.EMPTY : fieldStatus.VALID
    },
    newAddrCheckBoxFieldChange(state, { payload }) {
      let { id, selected, data } = payload

      state[id].value = data

      if (id === "hasPet" || id === "petTypes") {
        let hasPet = state.hasPet.value

        state.petTypes = {
          ...state.petTypes,
          value: hasPet ? state.petTypes.value : [],
          isRequired: hasPet,
          fieldStatus: hasPet && state.petTypes.value.length == 0 ? fieldStatus.EMPTY : fieldStatus.VALID
        }
      }
    },
    newAddrIsSaveChecking(state, { payload }) {
      state.isSaveChecking = payload
    }
  }
})

export const handleSaveNewAddrChecking = (state) => {
  let isPass = true

  Object.entries(state).forEach((entry) => {
    const [key, field] = entry
    if (field && field.fieldStatus === fieldStatus.EMPTY) {
      isPass = false
    }
  })

  return isPass
}

export const {
  newAddrResetAll,
  newAddrFieldChange,
  newAddrCheckBoxFieldChange,
  newAddrIsSaveChecking
} = addrReducer.actions
export default addrReducer.reducer
