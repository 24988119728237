import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { createModalCreateInvoiceColumns } from "@utils/tableConfigs/modal"
import { TableList } from "@components/tables"
import { ModalMessage } from "@components/modals"
// Styles
// Libs
import { t } from "i18next"
import { postInvoices } from "@slices/invoices"

const ModalCreateInvoice = ({ open, onClose, onSave, customerId, selectedList = [] }) => {
  const dispatch = useDispatch()

  const [amount, setAmount] = useState("")
  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (open) {
      console.log(selectedList)
      let listAmount = 0
      selectedList.forEach((item) => {
        listAmount = listAmount + item.amount / 100
      })
      setAmount(listAmount)
    }
  }, [open])

  const handleSaveClick = async () => {
    try {
      let orderIdList = []
      selectedList.forEach((item) => orderIdList.push({ id: item.id }))
      
      await dispatch(postInvoices({ customerId, orders: orderIdList })).unwrap()
      
      onSave && onSave()
      onClose && onClose()
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const columnsConfig = createModalCreateInvoiceColumns()

  const modalMessageConfig = {
    message,
    errorMessage,
    open: !!errorMessage || !!message,
    onClose: () => {
      setMessage(null)
      setErrorMessage(null)
    }
  }

  return (
    <>
      <ModalBase open={open} onClose={onClose}>
        <ModalContent maxwidth="990px" expand={true} sx={{ border: "none !important" }}>
          <Close onClick={onClose} />

          <Box sx={{ height: "100%" }}>
            <Box sx={{ padding: "0px 12px" }}>
              <Text size="28px" weight="500">
                {t("MODAL_Create_Invoice_title")}
              </Text>
              <VSpace length={48} />
              <Row sx={{ justifyContent: "flex-end", padding: "0px 55px" }}>
                <Text size="14px" weight="500">
                  {t("MODAL_Create_Invoice_amount", { amount })}
                </Text>
              </Row>
              <VSpace length={25} />
            </Box>
            <Box sx={{ padding: "0px 12px", height: "calc(100% - 53px)", overflowY: "auto", overflowX: "hidden" }}>
              <TableList columns={columnsConfig} rows={selectedList} maxHeight="unset" />
            </Box>
          </Box>

          <ModalButtonWrapper justifycontent="flex-end">
            <ButtonWithImage
              {...buttonStyle}
              imgW="20px"
              imgH="20px"
              color="paper_brown_1"
              src="save-black"
              alt="save icon"
              onClick={() => handleSaveClick()}
            >
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "10px 22px",
  border: "transparent",
  imgW: "15px",
  imgH: "15px"
}

export default ModalCreateInvoice
