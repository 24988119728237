// Components
import Box from "@mui/material/Box"
import { Row, Text } from "@layout"
import { AddressButton } from "./service-address.styles"
import AddressContent from "./address-content"
// Styles
// Libs
import { t } from "i18next"
import { useTheme } from "@mui/styles"
import { addrIndexChange, addrFieldChange, addrCheckBoxFieldChange } from "./reducers"
import { formatAddressToField } from "@utils/formatter"
import { forEach } from "lodash"
import { fieldStatus } from "@constants"

const ServiceAddress = ({ state, reducerDispatch }) => {
  const theme = useTheme()
  const { addresses, addressIndex } = state

  const handleChange = (id, type, data) => {
    reducerDispatch(addrFieldChange({ id, data }))
  }

  const handleCheckBoxChange = ({ id, selected, data }) => {
    reducerDispatch(addrCheckBoxFieldChange({ id, selected, data }))
  }

  return (
    <Box sx={{ padding: "10px" }}>
      {addresses?.length ? (
        <Row sx={{ alignItems: "start !important" }}>
          <Box
            sx={{
              width: "165px",
              height: "560px",
              paddingRight: "10px",
              marginRight: "20px",
              borderRight: `1px solid ${theme.border.gray}`
            }}
          >
            {addresses.map((item, index) => (
              <AddressButton
                selected={addressIndex === index}
                key={`addressItem_${index}`}
                onClick={() => reducerDispatch(addrIndexChange({ index }))}
                error={state.isSaveChecking && isInvalidAddress(item) ? "true" : "false"}
              >
                {formatAddressToField({
                  district: item.district.value,
                  partition: item.partition.value,
                  street: item.street.value,
                  estate: item.estate.value,
                  building: item.building.value,
                  block: item.block.value,
                  floor: item.floor.value,
                  unit: item.unit.value
                })}
              </AddressButton>
            ))}
          </Box>
          <AddressContent
            state={addresses[addressIndex]}
            handleChange={handleChange}
            handleCheckBoxChange={handleCheckBoxChange}
          />
        </Row>
      ) : (
        <Text>{t("MODAL_AddUser_Title_no_address")}</Text>
      )}
    </Box>
  )
}

const isInvalidAddress = (state) => {
  let isInvalie = false

  forEach(state, (field, key) => {
    if (field?.isRequired && field.fieldStatus !== fieldStatus.VALID) {
      isInvalie = true
    }
  })

  return isInvalie
}

export default ServiceAddress
