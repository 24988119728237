import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useRouter } from "next/router"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { ModalMessage } from "@components/modals"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonBase, ButtonWithNumberClose } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
// Styles
// Libs
import { t } from "i18next"
import { CmsSettings } from "@selector"
import { putAdminById, getAdminById } from "@slices/admins"
import { findIndex, cloneDeep } from "lodash"

const ModalDisplaySetting = ({ children, onClose, onConfirm, itemList = [], ...props }) => {
  const dispatch = useDispatch()
  const { pathname } = useRouter()

  const cmsSettings = useSelector(CmsSettings)

  const [showBackup, setShowBackup] = useState(0)
  const [hideListState, setHideList] = useState(filterItemByShow(itemList, false))
  const [showListState, setShowList] = useState(filterItemByShow(itemList, true))
  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleItemClick = (selectdItem) => {
    let cloneItem = cloneDeep(selectdItem)
    let selectdIndex = findIndex(
      selectdItem.show ? showListState : hideListState,
      (item) => item.label === selectdItem.label
    )

    if (cloneItem.show) {
      cloneItem.show = false
      setShowList(showListState.filter((item, index) => index !== selectdIndex))
      setHideList([...hideListState, cloneItem])
    } else {
      cloneItem.show = true
      setHideList(hideListState.filter((item, index) => index !== selectdIndex))
      setShowList([...showListState, cloneItem])
    }
  }

  const handleAllClick = (type) => {
    let cloneList = cloneDeep(itemList)

    if (type == "SHOW") {
      cloneList.forEach((item) => (item.show = true))
      setShowList(cloneList)
      setHideList([])
    } else {
      cloneList.forEach((item) => (item.show = false))
      setHideList(cloneList)
      setShowList([])
    }
  }

  const handleShowBackup = (index) => {
    setShowBackup(index)

    if (index && cmsSettings && Array.isArray(cmsSettings[pathname]) && cmsSettings[pathname][index - 1]) {
      setHideList(filterItemByShow(cmsSettings[pathname][index - 1], false))
      setShowList(filterItemByShow(cmsSettings[pathname][index - 1], true))
    } else {
      setHideList(filterItemByShow(itemList, false))
      setShowList(filterItemByShow(itemList, true))
    }
  }

  const handleSaveClick = async () => {
    try {
      let presetDate = []
      if (cmsSettings && Array.isArray(cmsSettings[pathname])) {
        presetDate = cloneDeep(cmsSettings[pathname])
      }
      presetDate[showBackup - 1] = [...showListState, ...hideListState]

      let presetPayload = { [pathname]: presetDate }
      let payload = { cmsSettings: cmsSettings ? { ...cmsSettings, ...presetPayload } : presetPayload }
      await dispatch(putAdminById({ adminInfo: payload })).unwrap()
      await dispatch(getAdminById()).unwrap()
      setMessage(t("TEXT_COMMON_save_success"))
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const handleConfirmClick = () => {
    onConfirm && onConfirm([...showListState, ...hideListState])
    onClose && onClose()
  }

  const modalMessageConfig = {
    message,
    errorMessage,
    open: !!errorMessage || !!message,
    onClose: () => {
      setMessage(null)
      setErrorMessage(null)
    }
  }

  return (
    <>
      <ModalBase {...props}>
        <ModalContent maxwidth="817px" sx={{ border: "none !important" }}>
          <Close onClick={onClose} />

          <Box sx={{ padding: "13px 13px 29px 13px" }}>
            <Text size="16px" weight="500">
              {t("MODAL_DisplaySetting_Title_backup")}
            </Text>
            <VSpace length={8} />
            <Row sx={{ justifyContent: "space-between" }}>
              <Row>
                <ButtonBase
                  label="Default"
                  {...buttonStyle}
                  {...backupButtonStyle(showBackup == 0)}
                  onClick={() => handleShowBackup(0)}
                />
                <HSpace length={7} />
                <ButtonBase
                  label="Preset 1"
                  {...buttonStyle}
                  {...backupButtonStyle(showBackup == 1)}
                  onClick={() => handleShowBackup(1)}
                />
                <HSpace length={7} />
                <ButtonBase
                  label="Preset 2"
                  {...buttonStyle}
                  {...backupButtonStyle(showBackup == 2)}
                  onClick={() => handleShowBackup(2)}
                />
              </Row>
              {showBackup == 0 ? (
                <div />
              ) : (
                <ButtonBase
                  label={t("MODAL_DisplaySetting_Button_save_setting")}
                  {...buttonStyle}
                  variant="outlined"
                  textcolor="primary"
                  border="brown"
                  onClick={() => handleSaveClick()}
                />
              )}
            </Row>
            <VSpace length={15} />
            <Text size="16px" weight="500">
              {t("MODAL_DisplaySetting_Title_hide")}
            </Text>
            <VSpace length={12} />
            <Row sx={{ gap: "2px", flexWrap: "wrap" }}>
              {hideListState.map((item, index) => (
                <ButtonWithNumberClose
                  key={`hide_item_${index}_${showBackup}`}
                  label={t(item.label)}
                  disabled={showBackup === 0}
                  onClick={() => handleItemClick(item)}
                />
              ))}
            </Row>
            <VSpace length={14} />
            <Text size="16px" weight="500">
              {t("MODAL_DisplaySetting_Title_show")}
            </Text>
            <VSpace length={18} />
            <Row sx={{ gap: "24px 10px", flexWrap: "wrap" }}>
              {showListState.map((item, index) => (
                <ButtonWithNumberClose
                  key={`show_item_${item.label}_${showBackup}`}
                  label={t(item.label)}
                  show={true}
                  selected={true}
                  nubmerLabel={index + 1}
                  disabled={showBackup === 0}
                  onClick={() => handleItemClick(item)}
                />
              ))}
            </Row>
          </Box>

          <ModalButtonWrapper color="gray" justifycontent="space-between">
            <Row>
              {showBackup ? (
                <>
                  <ButtonBase
                    {...buttonStyle}
                    label={t("MODAL_DisplaySetting_Button_show_all")}
                    color="gray_black"
                    onClick={() => handleAllClick("SHOW")}
                  />
                  <HSpace length={18} />
                  <ButtonBase
                    {...buttonStyle}
                    label={t("MODAL_DisplaySetting_Button_hide_all")}
                    color="gray_black"
                    onClick={() => handleAllClick("HIDE")}
                  />
                </>
              ) : null}
            </Row>
            <ButtonBase
              label={t("TEXT_COMMON_confirm")}
              weight={500}
              {...buttonStyle}
              padding="7px 43px"
              onClick={() => handleConfirmClick()}
            />
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

const filterItemByShow = (itemList, show) => {
  if (show) {
    return itemList.filter((item) => item.show)
  }
  return itemList.filter((item) => !item.show)
}

const backupButtonStyle = (choosed) => {
  if (choosed) {
    return {
      variant: "outlined",
      textcolor: "primary",
      border: "yellow"
    }
  }
  return { color: "secondary" }
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 33px",
  border: "transparent"
}

export default ModalDisplaySetting
