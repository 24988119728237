import { Text, Row, HSpace } from "@layout"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/styles"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"

const DashboardViewField = ({ fieldPath, fieldProps, value = "-" }) => {
  const theme = useTheme()

  let displayValue = value

  if (fieldProps?.format && value) {
    displayValue = fieldProps.format(value)
  }

  return (
    <Box sx={{ display: "flex", height: "100%", padding: "0px 7px" }}>
      <Row sx={{ width: "100%", justifyContent: "space-between" }}>
        <Text size="28px" weight="500">
          {displayValue}
        </Text>
        <HSpace length={10} />
        {displayValue !== null ? (
          <Text
            style={{
              padding: "2px 11px",
              borderRadius: "12px",
              cursor: "pointer",
              background: theme.palette.background.gray_7
            }}
            onClick={() => fieldProps.handleClick(value)}
          >
            {t("TABLE_Button_view")}
          </Text>
        ) : null}
      </Row>
    </Box>
  )
}

export default DashboardViewField
