import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { DiscountsApi } from "../apis"

export const postUsableDiscounts = createAsyncThunk("discounts/postUsableDiscounts", async (payload) => {
  return await DiscountsApi.postUsableDiscounts(payload)
})

export const postUsableDiscountByCode = createAsyncThunk(
  "discounts/postUsableDiscountByCode",
  async (payload, { rejectWithValue }) => {
    try {
      return await DiscountsApi.postUsableDiscountByCode(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {}

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = discountsSlice.actions
export default discountsSlice.reducer
