import { useEffect, useState } from "react"
import { SelectBase } from "@components/selects"

const SelectField = ({ fieldPath, fieldProps, value = "" }) => {
  let defauleValue = value[fieldPath] ? value[fieldPath] : ""

  const [select, setSelect] = useState(defauleValue)

  useEffect(() => setSelect(defauleValue), [value])

  const handleSelect = (event) => {
    fieldProps.handleChange(event.value, value)
    setSelect(event.value)
  }

  return <SelectBase {...fieldProps.style} list={fieldProps.list} onSelect={handleSelect} value={select} />
}

export default SelectField
