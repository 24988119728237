import { InputTextArea } from "@components/inputs"

const InputTextAreaField = ({ fieldPath, fieldProps, value = "" }) => {
  let defaultValue = value,
    disabled = false

  if (defaultValue.hasOwnProperty(fieldPath)) {
    defaultValue = defaultValue[fieldPath]
  }

  if (fieldProps?.format) {
    defaultValue = fieldProps.format(defaultValue)
  }

  if (fieldProps?.disabled) {
    disabled = fieldProps?.disabled(value)
  }

  return (
    <InputTextArea
      {...fieldProps?.style}
      onChange={(event) => fieldProps?.handleChange({ event, value })}
      value={defaultValue}
      disabled={disabled}
    />
  )
}

export default InputTextAreaField
