import { styled } from "@mui/styles"
import Checkbox from "@mui/material/Checkbox"
import { ImgBase } from "@components/images"

const CheckboxBase = styled(Checkbox)(({ disabled }) => ({
  opacity: disabled ? 0.3 : 1
}))

const CheckboxIconBase = ({ round = true, height = "20px", width = "20px", cursor, sx, ...rest }) => {
  return (
    <CheckboxBase
      icon={
        <ImgBase
          src={round ? "bi-uncheck" : "checkbox"}
          alt="uncheck"
          height={height}
          width={width}
          cursor={cursor}
          objectfit="cover"
        />
      }
      checkedIcon={
        <ImgBase
          src={round ? "bi-check" : "checkboxed"}
          alt="checked"
          height={height}
          width={width}
          cursor={cursor}
          objectfit="cover"
        />
      }
      sx={{ padding: "0px", ...sx }}
      {...rest}
    />
  )
}

export default CheckboxIconBase
