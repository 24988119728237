import { styled } from "@mui/styles"

export const UploadWrapper = styled("div")(({ theme, error }) => ({
  width: "296px",
  height: "173px",
  border: "2px dashed",
  borderColor: theme.border[error ? "red" : "gray"],
  borderRadius: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
}))
