import { Fragment } from "react"
// Components
import Box from "@mui/material/Box"
import { Text, VSpace } from "@layout"
import { ImgExternal } from "@components/images"
import { InputText } from "@components/inputs"
// Styles
// Libs
import { t } from "i18next"

const CertificateInfo = ({ state, handleInputChange }) => {
  console.log(state);
  return (
    <Box sx={{ width: "281px" }}>
      {state.certs?.length ? (
        state.certs.map((item, index) => {
          return (
            <Fragment key={`cert_${index}`}>
              <Box>
                <ImgExternal src={item.url.value} alt={item.name.value} />
                <VSpace length={15} />
                <InputText
                  value={item.name.value}
                  onChange={(event) => handleInputChange("certs", "input", { index, value: event.target.value })}
                />
              </Box>
              <VSpace length={15} />
            </Fragment>
          )
        })
      ) : (
        <Text>{t("MODAL_TMProfile_Field_no_certificate")}</Text>
      )}
    </Box>
  )
}

export default CertificateInfo
