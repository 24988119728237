import { Text, Column } from "@layout"
import { isEmpty } from "@utils/validator"
import { isArray } from "lodash"

const TextField = ({ fieldPath, fieldProps, value }) => {
  let displayValue = isEmpty(value) ? "-" : value

  if (fieldProps?.format) {
    displayValue = isEmpty(value) ? "-" : fieldProps.format(value)
  }
  return (
    <Column>
      {isArray(displayValue) ? (
        displayValue.map(
          (e, key) =>
            e && (
              <Text key={e + key} {...fieldProps?.style}>
                {e}
              </Text>
            )
        )
      ) : (
        <Text {...fieldProps?.style}> {displayValue}</Text>
      )}
    </Column>
  )
}

export default TextField
