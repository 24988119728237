import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { TMMiscChargesApi } from "../apis"

export const getTMMiscCharges = createAsyncThunk("TMMiscCharges/getTMMiscCharges", async (params) => {
  return await TMMiscChargesApi.getTMMiscCharges(params)
})

export const postTMMiscCharges = createAsyncThunk(
  "TMMiscCharges/postTMMiscCharges",
  async (payload, { rejectWithValue }) => {
    try {
      return await TMMiscChargesApi.postTMMiscCharges(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteTMMiscChargesById = createAsyncThunk(
  "TMMiscCharges/deleteTMMiscChargesById",
  async (payload, { rejectWithValue }) => {
    try {
      return await TMMiscChargesApi.deleteTMMiscChargesById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {}

const TMMiscChargeSlice = createSlice({
  name: "TMMiscCharges",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = TMMiscChargeSlice.actions
export default TMMiscChargeSlice.reducer
