import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// Components
import ModalOverlay from "./modal-overlay"
import { Column, VSpace, Row, Text } from "@layout"
import { InputSearch } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"
// Libs
import { t } from "i18next"
import { cloneDeep } from "lodash"
import { isEmpty } from "@utils/validator"
import VisibilitySensor from "react-visibility-sensor"

const ModalSearchListing = ({
  apiAction,
  apiPayload,
  title,
  open,
  onSave,
  onClose,
  onAdd,
  getLabel,
  idKey,
  multiple = false,
  ...rest
}) => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState([])
  const [list, setList] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (open) {
      setList([])
      setSelected([])
      setSearch("")
    }
  }, [open])

  const dispatcGet = async (keyword) => {
    const { results } = await dispatch(apiAction({ keyword, ...apiPayload })).unwrap()
    setList(results.map((item) => ({ ...item, id: item[idKey] })))
  }

  const handleSelect = (item) => {
    if (multiple) {
      let payload = cloneDeep(selected) || [],
        indexOf = payload.map(({ id }) => id).indexOf(item.id)
      if (indexOf > -1) {
        payload.splice(indexOf, 1)
      } else {
        payload.push(item)
      }
      setSelected(payload)
    } else {
      setSelected([item])
    }
  }

  const handleAddClick = () => {
    onAdd && onAdd()
  }

  const handleSaveClick = () => {
    if (isEmpty(selected)) return

    onSave && onSave(multiple ? selected : selected[0])
  }

  let buttonList = []
  if (rest?.page === "CREATE_ORDER") {
    buttonList = [
      { label: t("MODAL_Button_add_customer"), srcx: "add", onClick: handleAddClick },
      { label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSaveClick }
    ]
  } else {
    buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSaveClick }]
  }

  return (
    <ModalOverlay open={open} onClose={onClose} buttonList={buttonList}>
      <Column sx={{ height: "100%", padding: "13px" }}>
        <Text weight={500}>{title}</Text>
        <VSpace length="20" />
        <InputSearch width="60%" value={search} handleChange={setSearch} handleSearch={() => dispatcGet(search)} />
        <VSpace length="15" />
        <Column sx={{ height: "calc(100% - 100px)", overflowY: "auto" }}>
          {list.map((item, index) => (
            <VisibilitySensor key={`modal search listing ${index}`} partialVisibility={true}>
              {({ isVisible }) =>
                isVisible ? (
                  <Row key={`modal search listing ${index}`} sx={{ height: "41px", padding: "10px 0px" }}>
                    <CheckboxBase
                      label={getLabel(item)}
                      onChange={() => handleSelect(item)}
                      checked={selected?.some(({ id }) => id === item.id)}
                    />
                  </Row>
                ) : (
                  <div style={{ height: "41px", padding: "10px 0px", visibility: "hidden" }}>loading...</div>
                )
              }
            </VisibilitySensor>
          ))}
        </Column>
      </Column>
    </ModalOverlay>
  )
}

export default ModalSearchListing
