import { useState, useReducer, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import ModalBase from "../modal-base"
import { Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, Close, HFieldContainer } from "../common"
import { Grid } from "./index.styles"
// Styles
// Libs
import { t } from "i18next"
import { setSearchField } from "./config"
import { getTMJobTypes } from "@slices/TM-job-types"
import { getServices } from "@slices/services"
import { resetSearchTool, setSearchToolData, setSearchToolOpen } from "@slices/search-tool"
import { SearchToolParams } from "@selector"
import mainReducer, {
  getInitialState,
  setInitialState,
  setInitialStateByStore,
  inputFieldChange,
  selectFieldChange
} from "./reducers"

const ModalSearchTool = () => {
  const dispatch = useDispatch()

  const open = useSelector((state) => state.searchTool.open)
  const { searchType, onConfirm } = useSelector((state) => state.header.searchToolConfig)

  const onClose = () => dispatch(setSearchToolOpen(false))

  const fieldList = setSearchField(searchType)
  const initialState = getInitialState(fieldList)

  const [jobTypesList, setJobTypesList] = useState([])
  const [subServiceList, setSubServiceList] = useState([])

  const searchToolParams = useSelector(SearchToolParams)

  const [state, reducerDispatch] = useReducer(mainReducer, initialState)

  const apiTimeOutRef = useRef()

  useEffect(() => {
    dispatch(resetSearchTool())

    return () => {
      dispatch(resetSearchTool())
    }
  }, [])

  useEffect(() => {
    if (!Object.keys(searchToolParams).length) {
      // reset all/filter from table list
      reducerDispatch(setInitialState(initialState))
    }
  }, [searchToolParams])

  useEffect(() => {
    if (open) {
      if (Object.keys(searchToolParams).length) {
        // from query
        reducerDispatch(setInitialStateByStore({ initialState, searchToolParams }))
      } else {
        // initialize
        reducerDispatch(setInitialState(initialState))
      }
      if (fieldList.some((item) => isJobTypeField(item.id))) {
        dispatchGetTMJobTypes()
      }
    }
  }, [open])

  useEffect(() => {
    if (state.serviceType) {
      setSubServiceList([])
      dispatchGetServices(state.serviceType)
    }
  }, [state.serviceType])

  const dispatchGetTMJobTypes = async () => {
    const { results } = await dispatch(getTMJobTypes()).unwrap()
    setJobTypesList(results.map(({ id, name }) => ({ id, label: name })))
  }

  const dispatchGetServices = async (serviceType) => {
    let { results } = await dispatch(getServices({ orderType: serviceType })).unwrap()
    setSubServiceList(results.map(({ serviceId, name }) => ({ id: name, label: name })))
  }

  const handleChange = (id, type, value, selectItem) => {
    switch (type) {
      case "select":
        reducerDispatch(selectFieldChange({ id, data: value, selectItem }))
        break
      case "text":
      case "datePicker":
        reducerDispatch(inputFieldChange({ id, data: value }))
        break
    }
  }

  const handleConfirmClick = () => {
    onClose && onClose()
    dispatch(setSearchToolData(state))
    onConfirm && onConfirm(state)
    document.activeElement.blur()
  }

  const onKeyPress = (key) => {
    if (key === "Enter") {
      clearTimeout(apiTimeOutRef.current)
      apiTimeOutRef.current = setTimeout(handleConfirmClick, 100)
    }
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="512px" sx={{ border: "none !important", paddingBottom: "0px !important" }}>
        <Close onClick={onClose} />

        <Box sx={{ padding: "10px" }}>
          <Text size="16px" weight="500">
            {t("HEAD_Button_search_tool")}
          </Text>
          <VSpace length={10} />
          <Grid>
            {fieldList.map((item, index) => {
              switch (item.id) {
                case "approvedJobTypeIds":
                case "activeJobTypeIds":
                case "tmJobTypeIds":
                  return (
                    <HFieldContainer
                      state={state}
                      key={`field_${index}`}
                      handleChange={handleChange}
                      list={jobTypesList}
                      {...item}
                    />
                  )
                case "subService":
                  return (
                    <HFieldContainer
                      state={state}
                      key={`field_${index}`}
                      handleChange={handleChange}
                      list={subServiceList}
                      {...item}
                    />
                  )
                default:
                  return (
                    <HFieldContainer
                      state={state}
                      key={`field_${index}`}
                      handleChange={handleChange}
                      onKeyPress={onKeyPress}
                      {...item}
                    />
                  )
              }
            })}
          </Grid>
          <VSpace length={12} />
          <Row sx={{ justifyContent: "flex-end" }}>
            <ButtonWithImage src="search" alt="search icon" {...buttonStyle} onClick={handleConfirmClick}>
              {t("TEXT_COMMON_search")}
            </ButtonWithImage>
          </Row>
        </Box>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 33px",
  border: "transparent"
}

const isJobTypeField = (id) => {
  return id === "approvedJobTypeIds" || id === "activeJobTypeIds" || id === "tmJobTypeIds"
}

export default ModalSearchTool
