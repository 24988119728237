import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { OrdersApi } from "../apis"
import { orderStatusDoc, typeOptionDoc, paymentStatusOptionDoc, orderTypeDoc } from "@constants"
import { setModalSettingsState, modalSettingsObj } from "@slices/modal-settings"

export const getOrders = createAsyncThunk("order/getOrders", async (payload) => {
  let res = await OrdersApi.getOrders(payload)
  if (res?.results?.length) {
    res.results.forEach((item) => {
      item.hideBtn = {
        copy: hideCopyBtn(item),
        cancel: hideCancelBtn(item),
        refund: hideRefundBtn(item),
        addTime: hideAddTimeBtn(item),
        invoice: hideInvoiceBtn(item),
        manual: hideManualBtn(item)
      }
    })
  }
  return res
})

export const postOrder = createAsyncThunk("order/postOrder", async (payload, { rejectWithValue }) => {
  try {
    return await OrdersApi.postOrder(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getOrderById = createAsyncThunk("order/getOrderById", async (payload) => {
  return await OrdersApi.getOrderById(payload)
})

export const putOrderById = createAsyncThunk("order/putOrderById", async (payload, { rejectWithValue }) => {
  try {
    return await OrdersApi.putOrderById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const postOrderCancelById = createAsyncThunk(
  "order/postOrderCancelById",
  async (payload, { rejectWithValue }) => {
    try {
      return await OrdersApi.postOrderCancelById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postOrderConfirmRefundById = createAsyncThunk("order/postOrderConfirmRefundById", async (payload) => {
  return await OrdersApi.postOrderConfirmRefundById(payload)
})

export const postOrderRejectRefundById = createAsyncThunk("order/postOrderRejectRefundById", async (payload) => {
  return await OrdersApi.postOrderRejectRefundById(payload)
})

export const postOrderOT = createAsyncThunk("order/postOrderOT", async ({ orderId, duration }, { rejectWithValue }) => {
  try {
    return await OrdersApi.postOrderOT({ orderId, duration })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const postOrderPricingById = createAsyncThunk("order/postOrderPricingById", async (payload) => {
  return await OrdersApi.postOrderPricingById(payload)
})

export const postOrderCheckoutById = createAsyncThunk(
  "order/postOrderCheckoutById",
  async (payload, { rejectWithValue }) => {
    try {
      return await OrdersApi.postOrderCheckoutById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getOrderTMsById = createAsyncThunk("order/getOrderTMsById", async (payload) => {
  return await OrdersApi.getOrderTMsById(payload)
})

export const postOrderReviewById = createAsyncThunk(
  "order/postOrderReviewById",
  async (payload, { rejectWithValue }) => {
    try {
      return await OrdersApi.postOrderReviewById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const putOrderCommentById = createAsyncThunk(
  "order/putOrderCommentById",
  async (payload, { rejectWithValue }) => {
    try {
      return await OrdersApi.putOrderCommentById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postOrderFulfilmentPdfById = createAsyncThunk(
  "order/postOrderFulfilmentPdfById",
  async (payload, { rejectWithValue }) => {
    try {
      return await OrdersApi.postOrderFulfilmentPdfById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postCheck418 = createAsyncThunk("order/postCheck418", async (payload, { rejectWithValue }) => {
  try {
    return await OrdersApi.postCheck418(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getMatching = createAsyncThunk("order/getMatching", async (payload, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "order/getMatching" }))
    return await OrdersApi.getMatching(payload)
  } catch (error) {
    return rejectWithValue(error)
  } finally {
    dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "order/getMatching" }))
  }
})

const hideCopyBtn = (data) => {
  return (
    data.type === orderTypeDoc.ot ||
    data.status === orderStatusDoc.cancelled.id ||
    data.status === orderStatusDoc.request_refund.id ||
    data.status === orderStatusDoc.refunded.id
  )
}

const hideCancelBtn = (data) => {
  if (data.customer?.type === typeOptionDoc.organization.id) {
    return data.paymentStatus !== paymentStatusOptionDoc.unpaid.id
  } else if (data.type === orderTypeDoc.ot) {
    return data.paymentStatus === paymentStatusOptionDoc.paid.id || data.status === orderStatusDoc.cancelled.id
  }
  return data.status !== orderStatusDoc.created.id && data.status !== orderStatusDoc.pairing.id
}

const hideRefundBtn = (data) => {
  if (data.customer?.type === typeOptionDoc.organization.id) {
    return data.paymentStatus === paymentStatusOptionDoc.unpaid.id
  } else if (data.type === orderTypeDoc.ot) {
    return data.paymentStatus === paymentStatusOptionDoc.unpaid.id || data.status === orderStatusDoc.refunded.id
  }
  return (
    data.status !== orderStatusDoc.matched.id &&
    data.status !== orderStatusDoc.processing.id &&
    data.status !== orderStatusDoc.finished.id
  )
}

const hideAddTimeBtn = (data) => {
  return (
    data.otOrderId ||
    data.type === orderTypeDoc.ot ||
    (data.status !== orderStatusDoc.matched.id &&
      data.status !== orderStatusDoc.processing.id &&
      data.status !== orderStatusDoc.finished.id)
  )
}

const hideInvoiceBtn = (data) => {
  return (
    data.customer?.type === typeOptionDoc.individual.id ||
    data.paymentStatus === paymentStatusOptionDoc.paid.id ||
    data.invoiceId
  )
}

const hideManualBtn = (data) => {
  return data.status !== orderStatusDoc.pairing.id
}

const initialState = {}

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: {
    [getOrderById.pending]: (state, action) => {},
    [getOrderById.fulfilled]: (state, { payload }) => {},
    [getOrderById.rejected]: (state, { error }) => {}
  }
})

export const {} = orderSlice.actions
export default orderSlice.reducer
