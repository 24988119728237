import { useState, useEffect } from "react"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { SelectBase } from "@components/selects"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { ImgBase } from "@components/images"
// Styles
// Libs
import { t } from "i18next"
import { statusOptionList } from "@constants"

const ModalSetStatus = ({ open, accountStatus, onClose, onSave }) => {
  const [status, setStatus] = useState(accountStatus)

  useEffect(() => {
    if (open) {
      setStatus(accountStatus)
    }
  }, [open])

  const handleSaveClick = () => {
    onSave && onSave(status)
    onClose && onClose()
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="534px" sx={{ border: "none !important" }}>
        <Close onClick={onClose} />

        <Box sx={{ padding: "9px 25px 15px 25px" }}>
          <Row>
            <ImgBase src="edit-yellow" alt="exceed 418" width="37px" height="33px" />
            <HSpace length={20} />
            <Text size="18px" weight="400">
              {t("MODAL_Set_Status_title")}
            </Text>
          </Row>
          <VSpace length={34} />
          <SelectBase
            size="14px"
            weight="300"
            value={status}
            list={statusOptionList}
            onSelect={(event, selectItem) => setStatus(event.value)}
            padding="16px 6px"
          />
        </Box>

        <ModalButtonWrapper color="gray" justifycontent="flex-end">
          <ButtonWithImage
            {...buttonStyle}
            imgW="17px"
            imgH="17px"
            srcx="save"
            alt="save icon"
            color="success"
            onClick={() => handleSaveClick()}
          >
            {t("TEXT_COMMON_save")}
          </ButtonWithImage>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 28px",
  border: "transparent"
}

export default ModalSetStatus
