import { useEffect, useState, useReducer } from "react"
import { useDispatch } from "react-redux"

import { VFieldContainer } from "@components/common"
import { ModalOverlay, ModalMessage } from "@components/modals"
import BasicTabs from "@components/tabs"
import { Column, Row } from "@layout"

import mainReducer, {
  initialState,
  fieldChange,
  validator,
  defaultSetting,
  validateCheck,
  convertToPayload
} from "./reducers"
import { publishedOptionList } from "@constants"
import { t } from "i18next"
import { postTMJobType, putTMJobTypeById } from "@slices/TM-job-types"
import { isEmpty } from "@utils/validator"

const ModalTMType = ({ type, update, ...props }) => {
  const dispatch = useDispatch()

  const [state, reducerDispatch] = useReducer(mainReducer, initialState)

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      reducerDispatch(defaultSetting(type))
    }
  }, [props.open])

  let fieldsList = [
    { id: "name", label: t("MODAL_TMType_name"), type: "input" },
    { id: "imageUrl", label: t("MODAL_TMType_imageUrl"), type: "file", width: "300px" },
    {
      id: "roles",
      label: t("MODAL_TMType_roles"),
      type: "input"
    },
    {
      id: "responsibility",
      label: t("MODAL_TMType_responsibility"),
      type: "editor",
      width: "500px"
    },
    { id: "order", label: t("MODAL_TMType_order"), type: "input", inputType: "number" },
    {
      id: "published",
      label: t("MODAL_TMType_published"),
      type: "select",
      list: publishedOptionList
    }
  ]

  const handleChange = (id, type, value) => {
    // console.log("handleChange : ", { id, type, value })
    switch (type) {
      case "file":
        reducerDispatch(fieldChange({ id, value: value.url }))
        break
      default:
        reducerDispatch(fieldChange({ id, value }))
        break
    }
  }
  console.log(state)

  const handleSave = () => {
    // console.log("handleSave : ", { state })
    if (validateCheck(state)) {
      dispatchTMType()
    } else {
      reducerDispatch(validator())
    }
  }
  const dispatchTMType = async () => {
    try {
      if (type) {
        const payload = {
          jobTypeId: type.id,
          jobTypeInfo: convertToPayload(state)
        }
        await dispatch(putTMJobTypeById(payload)).unwrap()
      } else {
        const payload = convertToPayload(state)
        console.log({ payload })
        await dispatch(postTMJobType(payload)).unwrap()
      }
      update()
      props.onClose()
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSave }]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <BasicTabs list={[{ label: t("TAB_basic_info") }]}>
          <Row>
            <Column>
              {state &&
                fieldsList.map((config, index) => (
                  <VFieldContainer
                    key={`modal field ${config.id}`}
                    index={index}
                    width={config.width || "200px"}
                    value={state[config.id]?.value}
                    error={state[config.id]?.invalid}
                    handleChange={handleChange}
                    required={state[config.id]?.isRequired}
                    {...config}
                  />
                ))}
            </Column>
          </Row>
        </BasicTabs>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalTMType
