import { useSelector } from "react-redux"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { ImgBase } from "@components/images"
import { Text, VSpace } from "@layout"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
// Libs
import { t } from "i18next"
import { ModalSettingsLoading } from "@selector"

const ModalLoading = () => {
  const modalSettingsLoading = useSelector(ModalSettingsLoading)

  return (
    <>
      <ModalBase open={Boolean(modalSettingsLoading?.length)}>
        <ModalContent style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
          <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            <ImgBase src="loading" alt="loading icon" width="190px" height="190px" />
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Text size="28px" weight="500">
                {t("TEXT_COMMON_please_wait")}
              </Text>
            </Box>
          </Box>
        </ModalContent>
      </ModalBase>
    </>
  )
}

export default ModalLoading
