import { styled } from "@mui/styles"
import { ImgButton } from "@components/images"

export const BackButton = styled((props) => <ImgButton {...props} width="100%" />)(({ theme }) => ({
  flexGrow: 0,
  width: "41px",
  height: "41px",
  padding: "12px",
  marginRight: "15px",
  border: "solid 1px",
  borderRadius: 0,
  borderColor: theme.border.gray,
  backgroundColor: theme.palette.background.gray_4
}))
