import { Link, Text } from "@layout"
import { isEmpty } from "@utils/validator"

const IdLinkField = ({ fieldPath, fieldProps, value }) => {
  let displayValue = isEmpty(value) ? "-" : value,
    href = { pathname: "" },
    domain = fieldProps.domain || ""

  if (fieldProps.setDomain) {
    domain = fieldProps.setDomain(value)
  }

  if (fieldProps.path) {
    href.pathname = `${domain}/${fieldProps.path(value)}`
  } else if (value?.hasOwnProperty(fieldPath)) {
    href.pathname = `${domain}${isEmpty(value[fieldPath]) ? "" : `/${value[fieldPath]}`}`
  } else {
    href.pathname = `${domain}${isEmpty(value) ? "" : `/${value}`}`
  }

  if (fieldProps.query) {
    if (typeof fieldProps.query === "function") {
      href.query = fieldProps.query(value)
    } else {
      href.query = fieldProps.query
    }
  }

  if (fieldProps.format) {
    displayValue = fieldProps.format(displayValue)
  }

  return <>{value === "-" ? <Text>{value}</Text> : <Link href={href}>{displayValue}</Link>}</>
}

export default IdLinkField
