import { useState } from "react"
import { t } from "i18next"

import { ButtonWithImage } from "@components/buttons"
import { Text, VSpace, Row } from "@layout"
import { InputBase, InputTextArea } from "@components/inputs"
import { ImgButton } from "@components/images"
import { PopoverBase, PopoverWrapper, ButtonWrapper, Content } from "./index.styles"

const PopoverCash = ({ cash = 0, text = "", onConfirm, ...props }) => {
  const [values, setValues] = useState({ cash, text })

  const handleCashChange = (event) => {
    setValues({ ...values, cash: event.target.value.replace(/[^(\-)0-9]/g, "") })
  }

  const handlePlusClick = () => {
    setValues({ ...values, cash: Number(values.cash) + 1 })
  }

  const handleMinusClick = () => {
    setValues({ ...values, cash: Number(values.cash) - 1 })
  }

  const handleTextChange = (event) => {
    setValues({ ...values, text: event.target.value })
  }
  const handleConfirmClick = () => {
    console.log("confirm")
    onConfirm && onConfirm(values)
    props.onClose()
  }
  return (
    <PopoverBase {...props}>
      <PopoverWrapper>
        <Content>
          <Text weight="500">{t("POPOVER_CASH_cash_title")}</Text>
          <VSpace length={5} />
          <Text weight="300">{t("POPOVER_CASH_cash_subtitle")}</Text>
          <VSpace length={38} />
          <Row sx={{ gap: "15px", justifyContent: "center" }}>
            <ImgButton width="30px" src="icon-minus" onClick={handleMinusClick} />
            <InputBase
              value={values.cash}
              onChange={(event) => handleCashChange(event)}
              sx={{ maxWidth: "100px" }}
              padding="2px"
              bordercolor="gray"
              center="1"
            />
            <ImgButton width="30px" src="icon-plus" onClick={handlePlusClick} />
          </Row>
          <VSpace length={20} />
          <InputTextArea width="100%" height="40px" onChange={(event) => handleTextChange(event)} defaultValue={text} />
          <VSpace length={5} />
        </Content>
        <ButtonWrapper>
          <ButtonWithImage label={t("TEXT_COMMON_cancel")} onClick={props.onClose} color="gray_white" />
          <ButtonWithImage label={t("TEXT_COMMON_confirm")} onClick={handleConfirmClick} src="vector" />
        </ButtonWrapper>
      </PopoverWrapper>
    </PopoverBase>
  )
}

export default PopoverCash
