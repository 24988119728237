// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { ImgBase } from "@components/images"
// Styles
// Libs
import { t } from "i18next"

const ModalYesNo = ({ icon, title, content, open, onClose, onSave }) => {
  const handleSaveClick = () => {
    onSave && onSave()
    onClose && onClose()
  }
  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="534px" sx={{ border: "none !important" }}>
        <Close onClick={() => onClose()} />

        <Box sx={{ padding: "12px 27px 65px 27px" }}>
          <Row>
            {icon && (
              <>
                <ImgBase src={icon} width="27px" height="27px" />
                <HSpace length={20} />
              </>
            )}
            <Text size="18px" weight="400">
              {title}
            </Text>
          </Row>
          <VSpace length={34} />
          <Text size="18px" weight="400" dangerouslySetInnerHTML={{ __html: content }} />
        </Box>

        <ModalButtonWrapper color="gray" justifycontent="flex-end">
          <Row>
            <ButtonWithImage
              {...buttonStyle}
              imgW="15px"
              imgH="15px"
              color="gray_white"
              srcx="white-delete"
              alt="cancel icon"
              onClick={() => onClose()}
            >
              {t("TEXT_COMMON_no")}
            </ButtonWithImage>
            <HSpace length={33} />
            <ButtonWithImage
              {...buttonStyle}
              imgW="15px"
              imgH="15px"
              srcx="check-white"
              alt="save icon"
              onClick={() => handleSaveClick()}
            >
              {t("TEXT_COMMON_yes")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 28px",
  border: "transparent"
}

export default ModalYesNo
