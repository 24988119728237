import { ModalContent, ModalButtonWrapper, Close } from "./common"
import ModalBase from "./modal-base"
import { ButtonWithImage } from "@components/buttons"

const ModalOverlay = ({ buttonList, buttonWrapperColor = "yellow", children, ...props }) => {
  return (
    <ModalBase {...props}>
      <ModalContent expand maxwidth="720px">
        <Close onClick={props.onClose} />
        {children && children}
        <ModalButtonWrapper color={buttonWrapperColor}>
          {buttonList?.map(({ textcolor = "brown_1", width = "115px", ...btnProp }) => (
            <ButtonWithImage textcolor={textcolor} sx={{ width }} key={`modal button ${btnProp.label}`} {...btnProp} />
          ))}
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

export default ModalOverlay
