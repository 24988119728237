import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus, statusOptionDoc } from "@constants"
import { makeField } from "@utils/makeField"
import assignFieldToPayload from "@utils/assignFieldToPayload"
import { isInvalidPhone, invalidateEmail } from "@utils/validator"
import { tabInitialState } from "./tab-list"
import { cloneDeep, forEach } from "lodash"
import { formatPhoneToField } from "@utils/formatter"
import { makeCertFieldsBase } from "./add-new-certificate"

const pageOneList = ["phone", "phone2", "staffNo", "emergencyContactPhone", "email", "nameZh"]
const pageFiveList = ["contractStartDate", "contractEndDate"]

const makeFieldsBase = (payload) => {
  let phone = formatPhoneToField(payload.phone)
  let phone2 = formatPhoneToField(payload.phone2)
  let emergencyContactPhone = formatPhoneToField(payload.emergencyContactPhone)
  let isStatusActive = payload.status === statusOptionDoc.Active.id

  let state = {
    isSaveChecking: false,
    phone: makeField(phone.number, "phone", fieldStatus.EMPTY, true),
    phone2: makeField(phone2.number, "phone2", fieldStatus.VALID, false),
    staffNo: makeField(payload.staffNo || "", "staffNo", fieldStatus.EMPTY, true),
    nameZh: makeField(payload.nameZh, "nameZh", fieldStatus.EMPTY, true),
    nameEn: makeField(payload.nameEn, "nameEn", fieldStatus.VALID, false),
    title: makeField(payload.title || "", "title", fieldStatus.VALID, false),
    email: makeField(payload.email, "email", fieldStatus.VALID, false),
    dob: makeField(payload.dob, "dob", fieldStatus.VALID, false),
    hkid: makeField(payload.hkid, "hkid", fieldStatus.VALID, false),
    marital: makeField(payload.marital || "", "marital", fieldStatus.VALID, false),
    education: makeField(payload.education || "", "education", fieldStatus.VALID, false),
    source: makeField(payload.source, "source", fieldStatus.VALID, false),
    nationality: makeField(payload.nationality, "nationality", fieldStatus.VALID, false),
    funnel: makeField(payload.funnel || "", "funnel", fieldStatus.VALID, false),
    remark: makeField(payload.remark || "", "remark", fieldStatus.VALID, false),
    profileImageUrl: makeField(payload.profileImageUrl, "profileImageUrl", fieldStatus.VALID, false),
    emergencyContactName: makeField(payload.emergencyContactName, "emergencyContactName", fieldStatus.VALID, false),
    emergencyContactRelation: makeField(
      payload.emergencyContactRelation,
      "emergencyContactRelation",
      fieldStatus.VALID,
      false
    ),
    emergencyContactPhone: makeField(emergencyContactPhone.number, "emergencyContactPhone", fieldStatus.VALID, false),
    addressRegion: makeField(payload.addressRegion, "addressRegion", fieldStatus.VALID, false),
    addressDistrict: makeField(payload.addressDistrict, "addressDistrict", fieldStatus.VALID, false),
    addressEstate: makeField(payload.addressEstate, "addressEstate", fieldStatus.VALID, false),
    addressStreet: makeField(payload.addressStreet, "addressStreet", fieldStatus.VALID, false),
    addressBuilding: makeField(payload.addressBuilding, "addressBuilding", fieldStatus.VALID, false),
    addressBlock: makeField(payload.addressBlock, "addressBlock", fieldStatus.VALID, false),
    addressFloor: makeField(payload.addressFloor, "addressFloor", fieldStatus.VALID, false),
    addressUnit: makeField(payload.addressUnit, "addressUnit", fieldStatus.VALID, false),
    bankName: makeField(payload.bankName, "bankName", fieldStatus.VALID, false),
    bankAccountName: makeField(payload.bankAccountName, "bankAccountName", fieldStatus.VALID, false),
    bankAccountNumber: makeField(payload.bankAccountNumber, "bankAccountNumber", fieldStatus.VALID, false),
    joinDate: makeField(payload.joinDate, "joinDate", fieldStatus.VALID, false),
    contractStartDate: makeField(payload.contractStartDate, "contractStartDate", fieldStatus.VALID, isStatusActive),
    contractEndDate: makeField(payload.contractEndDate, "contractEndDate", fieldStatus.VALID, isStatusActive),
    status: makeField(payload.status, "status", fieldStatus.VALID, false),
    certs: [],
    applyJobTypes: payload.applyJobTypes
  }

  forEach(state, (field, key) => {
    if (field?.isRequired && field.fieldStatus) {
      field.fieldStatus = field.value ? fieldStatus.VALID : fieldStatus.EMPTY
    }
  })

  return state
}

export const initialState = makeFieldsBase({
  phone: "",
  phone2: "",
  staffNo: "",
  nameZh: "",
  nameEn: "",
  // gender: "M", //
  title: "",
  email: "",
  dob: "",
  hkid: "",
  nationality: "",
  marital: "",
  education: "",
  source: "",
  funnel: "",
  remark: "",
  profileImageUrl: "",
  emergencyContactName: "",
  emergencyContactRelation: "",
  emergencyContactPhone: "",
  addressRegion: "",
  addressDistrict: "",
  addressEstate: "",
  addressStreet: "",
  addressBuilding: "",
  addressBlock: "",
  addressFloor: "",
  addressUnit: "",
  bankName: "",
  bankAccountName: "",
  bankAccountNumber: "",
  joinDate: "",
  contractStartDate: "",
  contractEndDate: "",
  // applyStatus: "未完成", //
  // interviewStatus: "待跟進", //
  status: "",
  certs: [],
  // { name: "保健員証書", url: "https://ehs-dev.redso.com.hk/resources/951b08e9-8c40-4c1a-b3c0-6215ef9a19bd.png" },
  // { name: "長者護理証書", url: "https://ehs-dev.redso.com.hk/resources/951b08e9-8c40-4c1a-b3c0-6215ef9a19bd.png" }
  applyJobTypes: []
  // approvedJobTypes: [] //
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    resetAll() {
      return initialState
    },
    setProfileByApi(state, { payload }) {
      let mainState = makeFieldsBase(payload)

      mainState.certs =
        payload.certs?.map((item) => {
          let result = makeCertFieldsBase(item)
          return {
            name: result.name,
            url: result.url
          }
        }) || []

      forEach(mainState, (field, key) => {
        if (key === "certs") {
          field.forEach((certItem, certIndex) => {
            forEach(certItem, (certItemField, certItemKey) => {
              if (certItemField && certItemField.fieldStatus) certItemField.fieldStatus = fieldStatus.VALID
            })
          })
        }
      })

      return mainState
    },
    selecFieldChange(state, { payload }) {
      if (payload.id === "applyJobTypes") {
        state.applyJobTypes = payload.data ? [].concat(payload.data) : []
      } else {
        if (payload.id === "status") {
          if (payload.data === statusOptionDoc.Active.id) {
            state.contractStartDate.isRequired = true
            state.contractStartDate.fieldStatus = state.contractStartDate.value ? fieldStatus.VALID : fieldStatus.EMPTY
            state.contractEndDate.isRequired = true
            state.contractEndDate.fieldStatus = state.contractEndDate.value ? fieldStatus.VALID : fieldStatus.EMPTY
          } else {
            state.contractStartDate.isRequired = false
            state.contractStartDate.fieldStatus = fieldStatus.VALID
            state.contractEndDate.isRequired = false
            state.contractEndDate.fieldStatus = fieldStatus.VALID
          }
        }
        state[payload.id] = {
          ...state[payload.id],
          value: payload.data,
          fieldStatus: fieldStatus.VALID
        }
      }
    },
    fileFieldChange(state, { payload }) {
      let { id, type, data } = payload

      if (type === "file") {
        if (id === "profileImageUrl" && data?.url) {
          state[id].value = data.url
        }
      } else if (type === "removeFile") {
        if (id === "profileImageUrl") {
          state[id].value = ""
        }
      } else if (type === "saveNewFile") {
        state[id].push(data)
      }
    },
    inputFieldChange(state, { payload }) {
      let { id, type, data } = payload

      if (id === "certs") {
        state.certs[data.index].name = {
          ...state.certs[data.index].name,
          value: data.value,
          fieldStatus: data.value ? fieldStatus.VALID : fieldStatus.EMPTY
        }
      } else {
        state[id] = {
          ...state[id],
          value: data,
          fieldStatus: fieldStatus.VALID
        }
        if (id === "email") {
          if (data) {
            state[id].fieldStatus = invalidateEmail(data) ? fieldStatus.ERROR : fieldStatus.VALID
          }
        } else if (id === "phone" || id === "phone2" || id === "emergencyContactPhone") {
          if (data) {
            state[id].fieldStatus = isInvalidPhone(`852-${data}`, true) ? fieldStatus.ERROR : fieldStatus.VALID
          } else if (id === "phone") {
            state[id].fieldStatus = fieldStatus.EMPTY
          }
        } else if (state[id].isRequired) {
          state[id].fieldStatus = data ? fieldStatus.VALID : fieldStatus.EMPTY
        }
      }
    },
    isSaveChecking(state, { payload }) {
      state.isSaveChecking = payload
    }
  }
})

export const convertToPayload = (state) => {
  let payload = {},
    fieldValue

  forEach(state, (data, key) => {
    if (data && data.fieldPath) {
      if (data.fieldPath === "phone" || data.fieldPath === "phone2" || data.fieldPath === "emergencyContactPhone") {
        assignFieldToPayload(payload, `852-${data.value}`, data.fieldPath)
      } else {
        assignFieldToPayload(payload, data.value, data.fieldPath)
      }
    }
    if (key === "certs") {
      payload.certs = []
      data.forEach((certItem, certIndex) => {
        payload.certs[certIndex] = {}
        forEach(certItem, (certItemData, certItemKey) => {
          if (certItemData && certItemData.fieldPath) {
            assignFieldToPayload(payload.certs[certIndex], certItemData.value, certItemData.fieldPath)
          }
        })
      })
    }
    if (key === "applyJobTypes" && data.length) {
      payload.applyJobTypes = [{ id: data[0].id }]
    }
  })

  return payload
}

export const handleSaveChecking = (state) => {
  let isPass = true,
    tabList = cloneDeep(tabInitialState.list)

  forEach(state, (field, key) => {
    if (field && (field.fieldStatus === fieldStatus.EMPTY || field.fieldStatus === fieldStatus.ERROR)) {
      isPass = false
      if (pageOneList.includes(key)) {
        tabList[0].error = true
      } else if (pageFiveList.includes(key)) {
        tabList[4].error = true
      }
    }
    if (key === "certs") {
      field.forEach((item) => {
        forEach(item, (itemField, itemKey) => {
          if (itemField && itemField.fieldStatus === fieldStatus.EMPTY) {
            isPass = false
            tabList[2].error = true
          }
        })
      })
      if (field?.length === 0) {
        isPass = false
        tabList[2].error = true
      }
    }
  })

  return { isPass, tabList }
}

export const { resetAll, setProfileByApi, selecFieldChange, inputFieldChange, fileFieldChange, isSaveChecking } =
  mainReducer.actions

export default mainReducer.reducer
