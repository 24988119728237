import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// Components
import ModalOverlay from "../modal-overlay"
import { Column, VSpace, Row, Text } from "@layout"
import { InputSearch } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"
import BasicTabs from "@components/tabs"
import { ButtonWithImage } from "@components/buttons"
import { ImgButton } from "@components/images"
// Libs
import { t } from "i18next"
import { getCustomers, getCustomerById } from "@slices/customers"
import { cloneDeep, findIndex } from "lodash"
import { isEmpty } from "@utils/validator"
import VisibilitySensor from "react-visibility-sensor"

const ModalCustomerList = ({ data, open, onSave, onClose }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState([])
  const [selected, setSelected] = useState([])
  const [list, setList] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (open) {
      setState([])
      setSelected([])
      setSearch("")
      setList([])
      getSelectedInfo(data)
    }
  }, [open])

  const getSelectedInfo = (ids) => {
    if (isEmpty(ids)) return
    let payload = []

    Promise.all(
      ids.map(async (id) =>
        dispatch(getCustomerById(id))
          .unwrap()
          .then((res) => payload.push(res))
      )
    ).then(() => {
      setState(payload)
      setSelected(payload)
    })
  }

  const dispatcGet = async (keyword) => {
    const { results } = await dispatch(getCustomers({ limit: 0, keyword })).unwrap()
    setList(results)
  }

  const handleAddClick = () => {
    setSelected(state)
  }

  const handleDeleteClick = (index) => {
    let payload = cloneDeep(selected) || []
    payload.splice(index, 1)
    setSelected(payload)
  }

  const handleChange = (value) => {
    let payload = cloneDeep(state) || [],
      indexOf = findIndex(payload, ["customerId", value.customerId])

    if (indexOf > -1) {
      payload.splice(indexOf, 1)
    } else {
      payload.push(value)
    }
    setState(payload)
  }

  const handleSaveClick = () => {
    const payload = selected.map(({ customerId }) => customerId)
    onSave && onSave(payload)
  }

  const buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSaveClick }]

  return (
    <ModalOverlay open={open} onClose={onClose} buttonList={buttonList}>
      <BasicTabs list={[{ label: t("MODAL_Search_client_list") }]}>
        <Row align="flex-start" sx={{ height: "100%", padding: "13px" }}>
          <Column sx={{ width: "60%", height: "100%" }}>
            <InputSearch width="100%" value={search} handleChange={setSearch} handleSearch={() => dispatcGet(search)} />
            <VSpace length="15" />
            <Column sx={{ height: "calc(100% - 100px)", overflowY: "auto", marginBottom: "auto" }}>
              {list.map((item, index) => (
                <VisibilitySensor key={`modal customer listing ${item.customerId}`} partialVisibility={true}>
                  {({ isVisible }) =>
                    isVisible ? (
                      <Row sx={{ height: "41px", padding: "10px 0px" }}>
                        <CheckboxBase
                          label={`${item.name} #${item.phone}`}
                          onChange={() => handleChange(item)}
                          checked={state.some((e) => e.customerId === item.customerId)}
                        />
                      </Row>
                    ) : (
                      <div style={{ height: "41px", padding: "10px 0px", visibility: "hidden" }}>loading...</div>
                    )
                  }
                </VisibilitySensor>
              ))}
            </Column>
            <Row sx={{ justifyContent: "flex-end" }}>
              <ButtonWithImage
                width="115px"
                srcx="add"
                textcolor="primary"
                label={t("TEXT_COMMON_add_on")}
                onClick={handleAddClick}
              />
            </Row>
          </Column>
          <Column
            sx={{
              marginLeft: "15px",
              borderLeft: "1px solid #CFCFD9",
              paddingLeft: "15px",
              width: "40%",
              height: "100%"
            }}
          >
            <Text weight="500" color="yellow">
              {t("MODAL_Customer_List_selected_customer_lise")}
            </Text>
            <VSpace length="15" />
            {selected.map((item, index) => (
              <Row key={`modal customer listing selected ${item.customerId}`} sx={{ gap: "13px" }}>
                <ImgButton width="12px" src="yellow-delete" onClick={() => handleDeleteClick(index)} />
                <Text>{`${item.name} #${item.phone}`}</Text>
              </Row>
            ))}
          </Column>
        </Row>
      </BasicTabs>
    </ModalOverlay>
  )
}

export default ModalCustomerList
