import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import { VSpace, Text } from "@layout"
import { VFieldContainer } from "@components/common"
// Styles
// Libs
import { t } from "i18next"
import { funnelOptionList, fieldStatus } from "@constants"
import { formatDateWithTime } from "@utils/formatter"
import { getTMJobTypes } from "@slices/TM-job-types"

const OtherInfo = ({ state, handleInputChange, handleSelectChange, createdAt }) => {
  const dispatch = useDispatch()
  const [jobTypesList, setJobTypesList] = useState([])

  useEffect(() => dispatchGetTMJobTypes(), [])

  const dispatchGetTMJobTypes = async () => {
    const { results } = await dispatch(getTMJobTypes()).unwrap()
    setJobTypesList(results.map(({ id, name }) => ({ id, label: name })))
  }

  const handleSelectJobType = (id, type, value, selectItem) => {
    handleSelectChange(id, type, selectItem)
  }

  return (
    <Box>
      {createdAt ? (
        <Text>{t("MODAL_TMProfile_Field_account_create_date", { date: formatDateWithTime(createdAt) })}</Text>
      ) : null}
      <VSpace length={15} />
      <VFieldContainer
        index={0}
        width="296px"
        id="applyJobTypes"
        value={state.applyJobTypes.length && jobTypesList.length ? state.applyJobTypes[0].id : ""}
        label={t("MODAL_TMProfile_Field_apply_job")}
        type="select"
        list={jobTypesList}
        handleChange={handleSelectJobType}
      />
      <VFieldContainer
        index={1}
        width="296px"
        id="joinDate"
        value={state.joinDate.value}
        label={t("MODAL_TMProfile_Field_join_date")}
        type="datePicker"
        handleChange={handleSelectChange}
      />
      <VFieldContainer
        index={2}
        width="296px"
        id="contractStartDate"
        value={state.contractStartDate.value}
        label={t("MODAL_TMProfile_Field_contract_effective_date")}
        type="datePicker"
        required={state.contractStartDate.isRequired}
        error={state.isSaveChecking && state.contractStartDate.fieldStatus !== fieldStatus.VALID}
        handleChange={handleSelectChange}
      />
      <VFieldContainer
        index={3}
        width="296px"
        id="contractEndDate"
        value={state.contractEndDate.value}
        label={t("MODAL_TMProfile_Field_contract_expiry_date")}
        type="datePicker"
        required={state.contractEndDate.isRequired}
        error={state.isSaveChecking && state.contractEndDate.fieldStatus !== fieldStatus.VALID}
        handleChange={handleSelectChange}
      />
      <VFieldContainer
        index={4}
        width="296px"
        id="source"
        value={state.source.value}
        label={t("MODAL_TMProfile_Field_how_kow_job")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={5}
        width="296px"
        id="funnel"
        value={state.funnel.value}
        label={t("MODAL_TMProfile_Field_Funnels")}
        type="select"
        list={funnelOptionList}
        handleChange={handleSelectChange}
      />
    </Box>
  )
}

export default OtherInfo
