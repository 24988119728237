// Components
import Box from "@mui/material/Box"
import { Row, VSpace } from "@layout"
import AddressContent from "./address-content"
import { BackButton } from "./add-new-address.styles"
// Styles
// Libs
import { newAddrFieldChange, newAddrCheckBoxFieldChange } from "./reducers/add-new-address"

const AddNewAddress = ({ state, reducerDispatch, handleBackClick }) => {
  const handleChange = (id, type, data) => {
    reducerDispatch(newAddrFieldChange({ id, data }))
  }

  const handleCheckBoxChange = ({ id, selected, data }) => {
    reducerDispatch(newAddrCheckBoxFieldChange({ id, selected, data }))
  }

  return (
    <Box sx={{ padding: "10px", height: "100%" }}>
      <BackButton onClick={handleBackClick} src="back" alt="back" />
      <VSpace length={20} />
      <Row sx={{ alignItems: "start !important", maxHeight: "calc(100% - 65px)", overflowY: "auto" }}>
        <AddressContent state={state} handleChange={handleChange} handleCheckBoxChange={handleCheckBoxChange} />
      </Row>
    </Box>
  )
}

export default AddNewAddress
