import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"
import { makeField } from "@utils/makeField"
import { fieldStatus } from "@constants"

const makeFieldsBase = (payload) => {
  payload.routeList.forEach((item, index) => {
    item.lv1 = makeField(item.lv1, "lv1", item.lv1?.length ? fieldStatus.VALID : fieldStatus.EMPTY, true)
    item.lv2 = makeField(item.lv2, "lv2", fieldStatus.VALID, false)
    item.lv3 = makeField(item.lv3, "lv3", fieldStatus.VALID, false)
  })

  return payload
}

export const initialState = makeFieldsBase({
  isSaveChecking: false,
  selectedIndex: 0,
  serviceId: "",
  routeList: [
    {
      lv1: [],
      lv2: [],
      lv3: []
    }
  ]
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    resetAll(state) {
      return initialState
    },
    setInitialState(state, { payload }) {
      let { list, serviceId } = payload
      let routeList = []

      if (list?.length) {
        list.forEach((item) => {
          routeList.push({
            lv1: item.lv1,
            lv2: item.lv2,
            lv3: item.lv3
          })
        })
      } else {
        routeList = [
          {
            lv1: [],
            lv2: [],
            lv3: []
          }
        ]
      }

      return makeFieldsBase({
        isSaveChecking: false,
        selectedIndex: 0,
        serviceId,
        routeList
      })
    },
    setSelectedIndex(state, { payload }) {
      state.selectedIndex = payload
    },
    selectFieldChange(state, { payload }) {
      let { id, value } = payload

      state.routeList[state.selectedIndex][id].value = value
      if (id === "lv1") {
        if (value.length && value.includes(state.serviceId)) {
          state.routeList[state.selectedIndex][id].fieldStatus = fieldStatus.VALID
        } else {
          state.routeList[state.selectedIndex][id].fieldStatus = fieldStatus.EMPTY
        }
      }
    },
    addNewRoute(state, { payload }) {
      state.routeList.push(initialState.routeList[0])
    },
    deleteRoute(state, { payload }) {
      state.selectedIndex = payload >= 1 ? payload - 1 : 0
      state.routeList.splice(payload, 1)
    },
    isSaveChecking(state, { payload }) {
      state.isSaveChecking = payload
    }
  }
})

export const convertToPayload = (state) => {
  let lines = cloneDeep(state.routeList)

  lines.forEach((item) => {
    item.lv1 = item.lv1.value
    item.lv2 = item.lv2.value
    item.lv3 = item.lv3.value
  })

  return lines
}

export const isInvalidSaveChecking = (state) => {
  return state.routeList.some((item) => item.lv1.fieldStatus !== fieldStatus.VALID)
}

export const {
  resetAll,
  setInitialState,
  setSelectedIndex,
  selectFieldChange,
  addNewRoute,
  deleteRoute,
  isSaveChecking
} = mainReducer.actions

export default mainReducer.reducer
