import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import Router from "next/router"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonBase, ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { SelectBase } from "@components/selects"
import { ModalMessage } from "@components/modals"
// Styles
// Libs
import { t } from "i18next"
import { addTimeList } from "@constants"
import { postOrderOT } from "@slices/orders"
import { isEmpty } from "@utils/validator"

const ModalAddTime = ({ open, onClose, orderId }) => {
  const dispatch = useDispatch()

  const [time, setTime] = useState(0.5)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (open) {
      setTime(0.5)
    }
  }, [open])

  const handleSaveClick = () => {
    if (orderId) {
      dispatch(postOrderOT({ orderId, duration: time * 60 }))
        .unwrap()
        .then((res) => {
          if (res?.id) {
            Router.push({ pathname: `/order/list/${res.id}` })
          }
          onClose && onClose()
        })
        .catch((error) => {
          if (error?.data?.message) {
            setErrorMessage({ message: error.data.message })
          }
        })
    }
  }

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <ModalBase open={open}>
        <ModalContent maxwidth="306px" sx={{ border: "none !important" }}>
          <Close onClick={onClose} />

          <Box sx={{ padding: "0px 8px 35px 8px" }}>
            <Text weight="500">{t("MODAL_Add_Time_title")}</Text>
            <VSpace length={48} />
            <Row sx={{ width: "100%", justifyContent: "center" }}>
              <SelectBase
                value={time}
                list={addTimeList}
                onSelect={(event, selectItem) => {
                  console.log(selectItem)
                  setTime(selectItem.id)
                }}
                {...selectStyle}
              />
            </Row>
          </Box>

          <ModalButtonWrapper color="gray" justifycontent="flex-end">
            <Row>
              <ButtonBase
                {...buttonStyle}
                padding="7px 43px"
                label={t("TEXT_COMMON_cancel")}
                color="gray_white"
                onClick={() => onClose()}
              />
              <HSpace length={33} />
              <ButtonWithImage
                {...buttonStyle}
                imgW="15px"
                imgH="15px"
                srcx="check-white"
                alt="save icon"
                onClick={() => handleSaveClick()}
              >
                {t("TEXT_COMMON_confirm")}
              </ButtonWithImage>
            </Row>
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 28px",
  border: "transparent"
}

const selectStyle = {
  size: "14px",
  weight: "400",
  width: "103px",
  placeHolder: "--"
}

export default ModalAddTime
