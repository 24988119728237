import { t } from "i18next"
import { formatFieldToServiceName, formatAddressToField, convertIdToLabel } from "@utils/formatter"
import { servicesTypeOptionDoc, orderStatusDoc } from "@constants"

const createModalCreateInvoiceColumns = () => {
  let resultList = [
    {
      fieldType: "text",
      fieldPath: "status",
      fieldProps: {
        format: (value) => orderStatusDoc[value]?.label
      },
      label: t("TABLE_order_state"),
      minWidth: 144
    },
    {
      fieldType: "idLink",
      fieldPath: "id",
      fieldProps: {
        domain: "/order/list",
        format: ({ orderNo }) => orderNo
      },
      passWholeRowData: true,
      label: t("TABLE_orderId"),
      minWidth: 200
    },
    {
      fieldType: "text",
      fieldPath: "user",
      fieldProps: {
        format: (value) => value?.name || "-"
      },
      label: t("TABLE_user_name"),
      minWidth: 144
    },
    {
      fieldType: "text",
      fieldPath: "service",
      fieldProps: {
        format: formatFieldToServiceName
      },
      label: t("TABLE_service"),
      minWidth: 123
    },
    {
      fieldType: "text",
      fieldPath: "date",
      fieldProps: {},
      label: t("TABLE_order_date"),
      minWidth: 137
    },
    {
      fieldType: "text",
      fieldPath: "time",
      fieldProps: {},
      label: t("TABLE_orderAt"),
      minWidth: 137
    },
    {
      fieldType: "idLink",
      fieldPath: "tmUserId",
      fieldProps: {
        domain: "/TM-user/list",
        format: ({ tmUser }) => tmUser?.nameZh
      },
      passWholeRowData: true,
      label: t("TABLE_TM_user_name"),
      minWidth: 137
    },
    {
      fieldType: "text",
      fieldPath: "amount",
      fieldProps: {
        format: (value) => `$${value / 100}`
      },
      label: t("TABLE_service_money"),
      minWidth: 137
    }
  ]

  return resultList
}

export default createModalCreateInvoiceColumns
