import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeField } from "@utils/makeField"
import assignFieldToPayload from "@utils/assignFieldToPayload"
import { isInvalidPhone } from "@utils/validator"
import { makeAddrFieldsBase } from "./add-new-address"
import { tabInitialState } from "./tab-list"
import { cloneDeep, forEach } from "lodash"

const pageOneList = ["title", "name", "phone", "yob"]
const pageTwoList = ["weight"]

const makeFieldsBase = (payload) => {
  let phone = payload.phone ? payload.phone : "852-"
  let carerPhone = payload.carer.phone ? payload.carer.phone : "852-"

  let state = {
    isSaveChecking: false,
    userId: payload.userId ? payload.userId : null,
    title: makeField(payload.title, "title", fieldStatus.EMPTY, true),
    name: makeField(payload.name, "name", fieldStatus.EMPTY, true),
    phone: makeField(phone, "phone", fieldStatus.EMPTY, true),
    yob: makeField(payload.yob, "yob", fieldStatus.EMPTY, true),
    relationship: makeField(payload.relationship, "relationship", fieldStatus.VALID, false),
    weight: makeField(payload.weight, "weight", fieldStatus.EMPTY, true),
    hasIncontinence: makeField(payload.hasIncontinence, "hasIncontinence", fieldStatus.VALID, true),
    conditionMoving: makeField(payload.condition.moving, "condition.moving", fieldStatus.VALID, true),
    conditionMental: makeField(payload.condition.mental, "condition.mental", fieldStatus.VALID, true),
    conditionPhysical: makeField(payload.condition.physical, "condition.physical", fieldStatus.VALID, true),
    remarks: makeField(payload.remarks, "remarks", fieldStatus.VALID, false),
    hasMaid: makeField(payload.hasMaid, "hasMaid", fieldStatus.VALID, true),
    carerName: makeField(payload.carer.name, "carer.name", fieldStatus.VALID, false),
    carerTitle: makeField(payload.carer.title, "carer.title", fieldStatus.VALID, false),
    carerPhone: makeField(carerPhone, "carer.phone", fieldStatus.VALID, false),
    carerRelationship: makeField(payload.carer.relationship, "carer.relationship", fieldStatus.VALID, false),
    addressIndex: 0,
    addresses: []
  }

  forEach(state, (field, key) => {
    if (field?.isRequired && field.fieldStatus) {
      field.fieldStatus = field.value === "" ? fieldStatus.EMPTY : fieldStatus.VALID
    }
  })

  return state
}

export const initialState = makeFieldsBase({
  title: "",
  name: "",
  phone: "",
  yob: "",
  relationship: "",
  weight: "",
  hasIncontinence: true,
  condition: {
    moving: "normal",
    mental: "normal",
    physical: ["normal"]
  },
  remarks: "",
  hasMaid: true,
  carer: {
    name: "",
    title: "",
    phone: "",
    relationship: ""
  },
  addressIndex: 0,
  addresses: []
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    resetAll() {
      return initialState
    },
    setProfileByApi(state, { payload }) {
      let { profile, linkToAddress } = payload
      let mainState = makeFieldsBase(profile)

      mainState.addresses = profile.addresses.map((item, index) => {
        if (linkToAddress && item.userAddressId === linkToAddress) {
          mainState.addressIndex = index
        }
        return makeAddrFieldsBase(item)
      })

      return mainState
    },
    fieldChange(state, { payload }) {
      let { id, data } = payload

      state[id].value = data
      state[id].fieldStatus = fieldStatus.VALID

      if (state[id].isRequired) {
        if (data === "") {
          state[id].fieldStatus = fieldStatus.EMPTY
        } else if (id === "phone" && isInvalidPhone(state[id].value)) {
          state[id].fieldStatus = fieldStatus.EMPTY
        }
      }
    },
    checkBoxFieldChange(state, { payload }) {
      let { id, selected, data } = payload

      if (id === "conditionPhysical") {
        if (data.length > 0) {
          state[id].value = data
        }
      } else {
        state[id].value = data
      }
    },
    addrIndexChange(state, { payload }) {
      state.addressIndex = payload.index
    },
    addrFieldChange(state, { payload }) {
      let { id, data } = payload

      state.addresses[state.addressIndex][id].value = data
      state.addresses[state.addressIndex][id].fieldStatus =
        state.addresses[state.addressIndex][id].isRequired && data === "" ? fieldStatus.EMPTY : fieldStatus.VALID
    },
    addrCheckBoxFieldChange(state, { payload }) {
      let { id, selected, data } = payload

      state.addresses[state.addressIndex][id].value = data

      if (id === "hasPet" || id === "petTypes") {
        let hasPet = state.addresses[state.addressIndex].hasPet.value
        let petTypesValue = state.addresses[state.addressIndex].petTypes.value

        state.addresses[state.addressIndex].petTypes = {
          ...state.addresses[state.addressIndex].petTypes,
          value: hasPet ? petTypesValue : [],
          isRequired: hasPet,
          fieldStatus: hasPet && petTypesValue.length == 0 ? fieldStatus.EMPTY : fieldStatus.VALID
        }
      }
    },
    saveNewAddress(state, { payload }) {
      state.addresses.push(payload)
    },
    isSaveChecking(state, { payload }) {
      state.isSaveChecking = payload
      state.addresses.forEach((item) => {
        item.isSaveChecking = true
      })
    }
  }
})

export const convertToPayload = (state) => {
  let payload = {},
    fieldValue
  Object.entries(state).forEach((entry) => {
    const [key, field] = entry
    if (key == "userId") {
      payload.userId = field
    }
    if (field && field.fieldPath) {
      if (key === "carerPhone" && (field.value === "852-" || field.value === "86-")) {
        assignFieldToPayload(payload, "", field.fieldPath)
      } else {
        assignFieldToPayload(payload, field.value, field.fieldPath)
      }
    }
    if (key === "addresses") {
      payload.addresses = []
      field.forEach((addrItem, addrIndex) => {
        payload.addresses[addrIndex] = {}
        Object.entries(addrItem).forEach((addrEntry) => {
          const [addrKey, addrField] = addrEntry
          if ((addrKey === "petTypes" || addrKey === "petIsCaged") && addrItem.hasPet.value === false) {
            payload.addresses[addrIndex].pet = null
          } else if (addrField && addrField.fieldPath) {
            assignFieldToPayload(payload.addresses[addrIndex], addrField.value, addrField.fieldPath)
          } else if (addrKey === "userAddressId") {
            payload.addresses[addrIndex].userAddressId = addrField
          }
        })
      })
    }
  })
  return payload
}

export const handleSaveChecking = (state) => {
  let isPass = true,
    tabList = cloneDeep(tabInitialState.list)
  Object.entries(state).forEach((entry) => {
    const [key, field] = entry
    if (field && field.fieldStatus === fieldStatus.EMPTY) {
      isPass = false
      if (pageOneList.includes(key)) {
        tabList[0].error = true
      } else if (pageTwoList.includes(key)) {
        tabList[2].error = true
      }
    }
    if (key === "addresses") {
      field.forEach((addrItem, addrIndex) => {
        Object.entries(addrItem).forEach((addrEntry) => {
          const [addrKey, addrField] = addrEntry
          if (addrField && addrField.fieldStatus === fieldStatus.EMPTY) {
            isPass = false
            tabList[1].error = true
          }
        })
      })
      if (field.length === 0) {
        tabList[1].error = true
      }
    }
  })

  return { isPass, tabList }
}

export const {
  resetAll,
  setProfileByApi,
  fieldChange,
  checkBoxFieldChange,
  addrIndexChange,
  addrFieldChange,
  addrCheckBoxFieldChange,
  saveNewAddress,
  isSaveChecking
} = mainReducer.actions

export default mainReducer.reducer
