import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { AdminTypesApi } from "../apis"

export const getAdminTypes = createAsyncThunk("adminTypes/getAdminTypes", async (payload) => {
  return await AdminTypesApi.getAdminTypes(payload)
})

export const postAdminTypes = createAsyncThunk("adminTypes/postAdminTypes", async (adminTypeInfo) => {
  return await AdminTypesApi.postAdminTypes(adminTypeInfo)
})

export const getAdminTypesById = createAsyncThunk("adminTypes/getAdminTypesById", async (adminTypeId) => {
  return await AdminTypesApi.getAdminTypesById(adminTypeId)
})

export const putAdminTypesById = createAsyncThunk("adminTypes/putAdminTypesById", async (payload) => {
  return await AdminTypesApi.putAdminTypesById(payload)
})

const initialState = {
  list: []
}

const adminTypesSlice = createSlice({
  name: "adminTypesSlice",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = adminTypesSlice.actions
export default adminTypesSlice.reducer
