import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// Components
import ModalOverlay from "./modal-overlay"
import { Box } from "@mui/material"
import { Column, VSpace, Row, Text } from "@layout"
import { CheckboxBase } from "@components/checkbox"
// Libs
import { t } from "i18next"
import { postOrderCancelById } from "@slices/orders"
import { getCustomers } from "@slices/customers"

const reasonList = [
  { id: 0, label: t("OPTION_COMMON_order_cancel_reason_0") },
  { id: 1, label: t("OPTION_COMMON_order_cancel_reason_1") },
  { id: 2, label: t("OPTION_COMMON_order_cancel_reason_2") },
  { id: 3, label: t("OPTION_COMMON_order_cancel_reason_3") },
  { id: 4, label: t("OPTION_COMMON_order_cancel_reason_4") },
  { id: 5, label: t("OPTION_COMMON_order_cancel_reason_5") },
  { id: 6, label: t("OPTION_COMMON_order_cancel_reason_6") },
  { id: 7, label: t("OPTION_COMMON_order_cancel_reason_7") },
  { id: 8, label: t("OPTION_COMMON_order_cancel_reason_8") },
  { id: 9, label: t("OPTION_COMMON_order_cancel_reason_9") },
  { id: 10, label: t("OPTION_COMMON_order_cancel_reason_10") },
  { id: 11, label: t("OPTION_COMMON_order_cancel_reason_11") },
  { id: 12, label: t("OPTION_COMMON_order_cancel_reason_12") },
  { id: 13, label: t("OPTION_COMMON_order_cancel_reason_13") },
  { id: 14, label: t("OPTION_COMMON_order_cancel_reason_14") },
  { id: 15, label: t("OPTION_COMMON_order_cancel_reason_15") },
  { id: 16, label: t("OPTION_COMMON_order_cancel_reason_16") },
  { id: 17, label: t("OPTION_COMMON_order_cancel_reason_17") },
  { id: 18, label: t("OPTION_COMMON_order_cancel_reason_18") },
  { id: 19, label: t("OPTION_COMMON_order_cancel_reason_19") },
  { id: 20, label: t("OPTION_COMMON_order_cancel_reason_20") },
  { id: 21, label: t("OPTION_COMMON_order_cancel_reason_21") },
  { id: 22, label: t("OPTION_COMMON_order_cancel_reason_22") },
  { id: 23, label: t("OPTION_COMMON_order_cancel_reason_23") }
]

const ModalCancelOrder = ({ open, onSave, onClose, onError, orderId }) => {
  const dispatch = useDispatch()
  const [selectedItem, setSelectedItem] = useState({})

  useEffect(() => {
    if (open) {
      setSelectedItem({})
    }
  }, [open])

  const handleCheckBoxClick = (item, checked) => {
    setSelectedItem(checked ? item : {})
  }

  const handleSaveClick = () => {
    // console.log(orderId)
    // console.log(selectedItem)
    if (orderId && selectedItem.label) {
      dispatch(postOrderCancelById({ orderId, reason: { cancelReason: selectedItem.label } }))
        .unwrap()
        .then((res) => {
          console.log(res)
          onSave && onSave(res)
        })
        .catch((error) => {
          onError && onError(error)
        })
    }
  }

  const handleCloseClick = () => {
    onClose && onClose()
  }

  const buttonList = [{ label: t("TEXT_COMMON_confirm"), srcx: "save-black", onClick: handleSaveClick }]

  return (
    <ModalOverlay open={open} onClose={handleCloseClick} buttonList={buttonList}>
      <Column sx={{ height: "100%", padding: "13px" }}>
        <Text weight={500}>{t("MODAL_Cancel_Order_title")}</Text>
        <VSpace length="15" />
        <Column sx={{ height: "calc(100% - 50px)", overflowY: "auto" }}>
          {reasonList.map((item, index) => (
            <Row key={`modal cancel reason ${index}`} sx={{ margin: "10px 0" }}>
              <CheckboxBase
                round={false}
                label={item.label}
                onChange={(event) => handleCheckBoxClick(item, event.target.checked)}
                checked={selectedItem.id === item.id}
              />
            </Row>
          ))}
        </Column>
      </Column>
    </ModalOverlay>
  )
}

export default ModalCancelOrder
