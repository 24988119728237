import { useRef } from "react"
import { useDispatch } from "react-redux"
import Router from "next/router"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { ImgBase } from "@components/images"
// Styles
// Libs
import { t } from "i18next"
import { postOrderConfirmRefundById, postOrderRejectRefundById } from "@slices/orders"

const ModalCheckRefund = ({ open, onClose, orderId, parentOrderId, signInAt, signOutAt }) => {
  const dispatch = useDispatch()
  const apiTimeOutRef = useRef()

  const handleRejectClick = () => {
    dispatch(postOrderRejectRefundById({ orderId }))
      .unwrap()
      .finally(() => {
        Router.push({ pathname: "/order/list" })
        onClose && onClose()
      })
  }

  const handleSaveClick = () => {
    clearTimeout(apiTimeOutRef.current)
    apiTimeOutRef.current = setTimeout(async () => {
      let res = await dispatch(postOrderConfirmRefundById({ orderId })).unwrap()
      if (res.parentOrderId) {
        Router.push({ pathname: `/order/list/${res.parentOrderId}` })
      } else {
        Router.push({ pathname: "/order/list" })
      }
      onClose && onClose()
    }, 1000)
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="534px" sx={{ border: "none !important" }}>
        <Close onClick={onClose} />

        <Box sx={{ padding: "12px 27px 65px 27px" }}>
          <Row>
            <ImgBase src={"yellow-circle-add"} alt={"exceed 418"} width={"27px"} height={"27px"} />
            <HSpace length={20} />
            <Text size="18px" weight="400">
              {t("MODAL_Check_Refund_title")}
            </Text>
          </Row>
          <VSpace length={34} />
          <Text size="18px" weight="400" dangerouslySetInnerHTML={{ __html: t("MODAL_Check_Refund_subTitle") }} />
        </Box>

        <ModalButtonWrapper color="gray" justifycontent="flex-end">
          <Row>
            <ButtonWithImage
              {...buttonStyle}
              imgW="15px"
              imgH="15px"
              color="gray_white"
              srcx="white-delete"
              alt="cancel icon"
              onClick={() => handleRejectClick()}
            >
              {t("TEXT_COMMON_reject")}
            </ButtonWithImage>
            <HSpace length={33} />
            <ButtonWithImage
              {...buttonStyle}
              imgW="15px"
              imgH="15px"
              srcx="check-white"
              alt="save icon"
              onClick={() => handleSaveClick()}
              disabled={Boolean(apiTimeOutRef.current)}
            >
              {t("TEXT_COMMON_accept")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 28px",
  border: "transparent"
}

export default ModalCheckRefund
