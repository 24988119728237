import { ModalContent, ModalButtonWrapper } from "./common"
import ModalBase from "./modal-base"
import { ButtonWithImage } from "@components/buttons"
import { Box } from "@mui/material"
import { Text, Column } from "@layout"
import { ImgBase } from "@components/images"

const Modal = ({ title, titleSize, src, buttonList, children, ...props }) => {
  return (
    <ModalBase {...props}>
      <ModalContent maxwidth="534px">
        <Column sx={{ padding: "12px 18px", minHeight: "150px" }}>
          <Box sx={{ gap: "12px", display: "flex", alignItems: "center" }}>
            {src && <ImgBase src={src} />}
            <Text size={titleSize} weight="500">
              {title}
            </Text>
          </Box>
          {children && children}
        </Column>
        <ModalButtonWrapper color="gray">
          {buttonList?.map((btnProp) => (
            <ButtonWithImage sx={{ width: "115px" }} key={`modal button ${btnProp.label}`} {...btnProp} />
          ))}
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

export default Modal
