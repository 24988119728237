import { styled, useTheme } from "@mui/styles"

import TableCell from "@mui/material/TableCell"
import { ImgBase } from "@components/images"
import { Row, Text } from "@layout"

const TableCellHeader = styled(({ text, handleSortChange, ...rest }) => {
  const theme = useTheme()
  return (
    <TableCell align="center" {...rest}>
      {handleSortChange ? (
        <Row sx={{ justifyContent: "space-between" }}>
          <ImgBase src={"cms-sort-icon"} alt={"sort icon"} width={"20px"} height={"20px"} />
          <Text whitespace="nowrap" margin="0 8px" color="gray_8" weight={500}>
            {text}
          </Text>
          <ImgBase
            src={"cms-filter"}
            alt={"filter icon"}
            width={"19px"}
            height={"19px"}
            cursor="pointer"
            onClick={() => handleSortChange()}
          />
        </Row>
      ) : (
        <Text whitespace="nowrap" color="gray_8" weight={500}>
          {text}
        </Text>
      )}
    </TableCell>
  )
})(({ theme, width, height }) => ({
  height: height || "unset",
  width: width,
  minWidth: width,
  backgroundColor: `${theme.palette.background.dark}`,
  border: `1px solid ${theme.border.brown_1}`,
  padding: "10px 5px !important",
  boxSizing: "border-box"
}))

export default TableCellHeader
