import { Fragment } from "react"
import { ButtonWithImage } from "@components/buttons"

import { VSpace, Row } from "@layout"

const ActionField = ({ fieldPath, fieldProps = [], value }) => {
  return (
    <Row sx={{ flexWrap: "wrap", gap: "10px" }}>
      {fieldProps.map((action, index) => {
        const styles = typeof action.style === "function" ? action.style(value[fieldPath]) : action.style
        const text = typeof action.text === "function" ? action.text(value[fieldPath]) : action.text
        const hide = typeof action.hide === "function" ? action.hide(value) : false
        return (
          <Fragment key={`action_${index}`}>
            {hide ? null : (
              <ButtonWithImage
                borderradius="13px"
                imgW="15px"
                imgH="15px"
                padding="7px 0px"
                fullWidth={true}
                {...styles}
                onClick={() => action.handleClick(value)}
              >
                {text}
              </ButtonWithImage>
            )}
          </Fragment>
        )
      })}
    </Row>
  )
}

export default ActionField
