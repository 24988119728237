import CheckboxIconBase from "./checkbox-icon-base"
import { Row, Text } from "@layout"

const CheckboxBase = ({ boxProps, label, ...rest }) => {
  return (
    <Row sx={{ gap: "10px", overflow: "hidden", ...boxProps }}>
      <CheckboxIconBase {...rest} />
      <Text whitespace="nowrap" textoverflow="ellipsis" overflow="hidden">
        {label}
      </Text>
    </Row>
  )
}

export default CheckboxBase
