import { Modal } from "@mui/material"
import { styled } from "@mui/styles"

const ModalBase = styled(Modal)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: theme.palette.background.yellow_opacity,
    backdropFilter: "blur(10px)",
    "-webkit-backdrop-filter": "blur(10px)"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}))
export default ModalBase
