import { useEffect, useState } from "react"
import { t } from "i18next"

import { ModalContent, ModalButtonWrapper } from "./common"
import ModalBase from "./modal-base"
import { ButtonWithImage } from "@components/buttons"
import { Text, Column, Divider, Row } from "@layout"
import File from "@components/file"
import { ModalMessage } from "@components/modals"

import { isEmpty } from "@utils/validator"

const ModalFile = ({ title, open, onSave, onClose, ...props }) => {
  const [state, setState] = useState()

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (open) setState()
  }, [open])

  const handleFileChange = (id, type, data) => {
    // console.log("handleFileChange : ", { id, type, data })
    setState(data.url)
  }

  const handleSave = () => {
    if (state === "") {
      setErrorMessage({ message: t("ERROR_COMMON_select_file") })
    } else {
      onSave && onSave(state)
      onClose && onClose()
    }
  }

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <ModalBase open={open} onClose={onClose}>
        <ModalContent maxwidth="534px">
          <Column sx={{ padding: "16px 30px 5px" }}>
            <Text weight="500">{title}</Text>
          </Column>
          <Divider color="yellow" />
          <Row sx={{ width: "100%", paddingTop: "20px", paddingBottom: "20px", justifyContent: "center" }}>
            <File
              id="imageUrl"
              fileURL={state}
              error={false}
              fileName="medical certificate"
              handleAddFile={handleFileChange}
              handleRemoveFile={handleFileChange}
            />
          </Row>
          <ModalButtonWrapper color="yellow">
            <ButtonWithImage
              sx={{ width: "115px" }}
              textcolor="brown_1"
              src="save-black"
              label={t("TEXT_COMMON_save")}
              onClick={handleSave}
            />
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalFile
