import CheckboxIconBase from "./checkbox-icon-base"
import { ImgBase } from "@components/images"
import { HSpace, Row, Text } from "@layout"
import { useTheme } from "@mui/styles"

const CheckboxWithImage = ({ imageProps, boxProps, children, error = false, ...rest }) => {
  const theme = useTheme()

  return (
    <Row
      sx={{
        width: "300px",
        padding: "6px 22px 6px 10px",
        border: `1px solid ${error ? theme.border.red : theme.border.gray}`,
        borderRadius: "12px",
        gap: "10px",
        ...boxProps
      }}
    >
      <CheckboxIconBase {...rest} />
      <Row sx={{ width: "100%" }}>
        {imageProps && (
          <>
            <ImgBase {...imageProps} />
            <HSpace length={10} />
          </>
        )}
        {children && children}
      </Row>
    </Row>
  )
}

export default CheckboxWithImage
