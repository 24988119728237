import { useState, useEffect } from "react"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonBase, ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { ImgBase } from "@components/images"
import { InputTextArea } from "@components/inputs"
// Styles
// Libs
import { t } from "i18next"

const ModalAddWithTextInput = ({ title, open, onClose, onSave }) => {
  const [serviceName, setServiceName] = useState("")
  const [error, setError] = useState(false)

  useEffect(() => {
    if (open) {
      setServiceName("")
      setError(false)
    }
  }, [open])

  const handleSaveClick = () => {
    if (serviceName === "") {
      setError(true)
    } else {
      onSave && onSave(serviceName)
      onClose && onClose()
    }
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="534px" sx={{ border: "none !important" }}>
        <Close onClick={onClose} />

        <Box sx={{ padding: "12px 27px" }}>
          <Row>
            <ImgBase src={"yellow-circle-add"} alt={"add service detail"} width={"27px"} height={"27px"} />
            <HSpace length={20} />
            <Text size="18px" weight="400">
              {title}
            </Text>
          </Row>
          <VSpace length={36} />
          <InputTextArea
            value={serviceName}
            error={error}
            height="52px"
            width="100%"
            onChange={(event) => setServiceName(event.target.value)}
          />
        </Box>

        <ModalButtonWrapper color="gray" justifycontent="flex-end">
          <Row>
            <ButtonBase
              {...buttonStyle}
              padding="7px 43px"
              label={t("TEXT_COMMON_cancel")}
              color="gray_white"
              onClick={() => {}}
            />
            <HSpace length={33} />
            <ButtonWithImage {...buttonStyle} srcx="save" alt="save icon" onClick={() => handleSaveClick()}>
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  color: "success",
  borderradius: "13px",
  padding: "7px 28px",
  border: "transparent"
}

export default ModalAddWithTextInput
