import { useState } from "react"
import { useDispatch } from "react-redux"
// Components
import { Text } from "@layout"
import { ModalUserProfile, ModalMessage } from "@components/modals"
// Libs
import { getUserById, putUserById } from "@slices/users"
import { isEmpty } from "@utils/validator"
import { t } from "i18next"

const UserProfileField = ({ fieldPath, fieldProps, value = "-" }) => {
  const dispatch = useDispatch()

  let { userName, userId, customerId } = fieldProps.getData(value)

  const [updateProfileState, setUpdateProfileState] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [message, setMessage] = useState(null)

  const [addUserOpen, setAddUserOpen] = useState(false)

  const handleOpenProfile = () => {
    dispatch(getUserById({ customerId, userId }))
      .unwrap()
      .then((res) => {
        setUpdateProfileState(res)
        setAddUserOpen(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleUserProfileConfirm = (userInfo) => {
    dispatch(putUserById({ customerId, userId: userInfo.userId, userInfo }))
      .unwrap()
      .then((res) => {
        fieldProps.handleConfirm && fieldProps.handleConfirm(value)
        setMessage(t("TEXT_COMMON_save_success"))
      })
      .catch(({ data }) => {
        console.log(data)
        setErrorMessage(data)
      })
  }

  const modalUserProfileConfig = {
    onClose: () => {
      setUpdateProfileState({})
      setAddUserOpen(false)
    },
    onSave: handleUserProfileConfirm,
    open: addUserOpen,
    profile: updateProfileState
  }

  const modalMessageConfig = {
    message,
    errorMessage,
    open: !isEmpty(errorMessage) || !isEmpty(message),
    onClose: () => {
      setMessage(null)
      setErrorMessage(null)
    }
  }

  return (
    <>
      {userId && customerId ? (
        <Text color="blue_light" size="16px" weight="500" cursor="pointer" onClick={() => handleOpenProfile()}>
          {userName}
        </Text>
      ) : (
        <Text color="blue_light" size="16px" weight="500">
          {userName}
        </Text>
      )}
      <ModalUserProfile {...modalUserProfileConfig} />
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default UserProfileField
