import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import Modal from "../modal"
import ModalMessage from "../modal-message"
import { VSpace, Row, Text } from "@layout"
import { ImgButton } from "@components/images"
import { InputBase } from "@components/inputs"
import { VFieldContainer } from "@components/common"

import { postEHSDollarLogs } from "@slices/ehs-dollar-logs"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"

const initalState = { adjustment: 0, remarks: "" }

const ModalAdjustDollar = ({ customerId, amount, onSave, ...props }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState(initalState)

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      setState(initalState)
    }
  }, [props.open])

  const handleChange = (type, value) => {
    if (type === "adjustment") {
      let reg = new RegExp(/^(\-)?\d+(\.\d{1})?$/)
      if (value && !reg.test(value)) return
    }
    setState({ ...state, [type]: value })
  }

  const handleManualChange = (type) => {
    if (type === "plus") {
      setState({ ...state, adjustment: Number(state.adjustment) + 1 })
    } else {
      setState({ ...state, adjustment: Number(state.adjustment) - 1 })
    }
  }

  const handleSave = () => {
    let paylaod = {
      customerId,
      adjustment: state.adjustment * 100,
      remarks: state.remarks
    }
    console.log(paylaod)
    if (amount + paylaod.adjustment < 0) {
      setErrorMessage({ message: t("ERROR_COMMON_over_amount") })
    } else {
      dispatch(postEHSDollarLogs(paylaod))
        .unwrap()
        .then(() => onSave())
    }
  }

  const buttonList = [
    { label: t("TEXT_COMMON_cancel"), color: "gray_white", onClick: props.onClose },
    { label: t("TEXT_COMMON_confirm"), src: "vector", onClick: handleSave }
  ]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <Modal {...props} buttonList={buttonList} title={t("POPOVER_CASH_cash_title")}>
        <VSpace length={5} />
        <Text weight="300">{t("POPOVER_CASH_cash_subtitle")}</Text>
        <VSpace length={38} />
        <Row sx={{ gap: "15px", justifyContent: "center" }}>
          <ImgButton width="30px" src="icon-minus" onClick={() => handleManualChange("minus")} />
          <InputBase
            type="number"
            value={state.adjustment}
            onChange={(event) => handleChange("adjustment", event.target.value)}
            sx={{ maxWidth: "100px" }}
            padding="2px"
            bordercolor="gray"
            center="1"
          />
          <ImgButton width="30px" src="icon-plus" onClick={() => handleManualChange("plus")} />
        </Row>
        <VSpace length={20} />
        <VFieldContainer
          width="100%"
          height="80px"
          label={t("TABLE_remark")}
          type="input-textarea"
          value={state.remarks}
          handleChange={(id, type, value) => handleChange("remarks", value)}
        />
        <VSpace length={20} />
      </Modal>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalAdjustDollar
