const assignFieldToPayload = (payload, value, fieldPath) => {
  let currentNode = payload
  let paths = fieldPath.split(".")
  paths.forEach((path, index) => {
    if (index == paths.length - 1) {
      currentNode[path] = value
    } else {
      if (!currentNode[path]) currentNode[path] = {}
      currentNode = currentNode[path]
    }
  })
}

export default assignFieldToPayload
