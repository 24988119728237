import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { ServiceLinesApi } from "../apis"

export const getServiceLinesById = createAsyncThunk("serviceLines/getServiceLinesById", async (payload) => {
  return await ServiceLinesApi.getServiceLinesById(payload)
})

export const putServiceLinesById = createAsyncThunk(
  "serviceLines/putServiceLinesById",
  async ({ serviceId, lineInfo }, { rejectWithValue }) => {
    try {
      return await ServiceLinesApi.putServiceLinesById({ serviceId, lineInfo })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {}

const serviceLinesSlice = createSlice({
  name: "serviceLines",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = serviceLinesSlice.actions
export default serviceLinesSlice.reducer
