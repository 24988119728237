import { useRef, useState, useEffect, memo } from "react"
// Components
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import TableCellHeader from "./table-cell-header"
import TableCellBody from "./table-cell-body"
import TableCellCheckBox from "./table-cell-checkbox"
import {
  LabelField,
  TextField,
  DateField,
  ActionField,
  ActionOrderField,
  SelectField,
  IdLinkField,
  InputTextAreaField,
  InputField,
  PopoverCashField,
  PopoverScoreField,
  DashboardViewField,
  HtmlTextField,
  UserProfileField,
  DownloadField,
  AuditLogsField
} from "@components/fields"
import VisibilitySensor from "react-visibility-sensor"
// Styles
// Libs
import { useTheme } from "@mui/styles"

const createFieldComponent = ({ fieldType, ...fieldConfig }) => {
  switch (fieldType) {
    case "label":
    case "label-dashboard":
      return <LabelField {...fieldConfig} />
    case "text":
      return <TextField {...fieldConfig} />
    case "date":
      return <DateField {...fieldConfig} />
    case "action":
      return <ActionField {...fieldConfig} />
    case "action-order":
      return <ActionOrderField {...fieldConfig} />
    case "select":
      return <SelectField {...fieldConfig} />
    case "idLink":
      return <IdLinkField {...fieldConfig} />
    case "input-area":
      return <InputTextAreaField {...fieldConfig} />
    case "input":
      return <InputField {...fieldConfig} />
    case "popover-cash":
      return <PopoverCashField {...fieldConfig} />
    case "popover-score":
      return <PopoverScoreField {...fieldConfig} />
    case "dashboard-view":
      return <DashboardViewField {...fieldConfig} />
    case "html":
      return <HtmlTextField {...fieldConfig} />
    case "user-profile":
      return <UserProfileField {...fieldConfig} />
    case "download":
      return <DownloadField {...fieldConfig} />
    case "audit-logs":
      return <AuditLogsField {...fieldConfig} />
    default:
      return null
  }
}

const isAllSelected = (rows) => {
  return rows.every((item) => item.selected)
}

const TableList = ({
  columns,
  rows = [],
  handleSelectRow,
  maxWidth = "100%",
  maxHeight = "440px",
  currentPage = 0,
  rowsPerPage = 0,
  sx
}) => {
  const theme = useTheme()
  const refPaper = useRef()
  const [minIndex, setMinIndex] = useState(0)

  useEffect(() => {
    if (currentPage && rowsPerPage) {
      refPaper.current.scrollTo(0, 0)
    }
  }, [currentPage, rowsPerPage])

  if (refPaper.current?.clientHeight > 800) {
    refPaper.current.style.maxHeight = "800px"
  }

  let visibleList = []

  const findMinIndex = () => {
    if (Math.max(...visibleList) > 22) {
      setMinIndex(Math.max(...visibleList) - 22)
    } else if (minIndex !== 0) {
      setMinIndex(0)
    }
  }

  return (
    <Paper
      ref={refPaper}
      sx={{ maxWidth, display: "flex", width: "fit-content", border: "none", overflow: "auto", ...sx }}
    >
      <TableContainer sx={{ display: "inline-table" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {handleSelectRow ? (
                <TableCellCheckBox
                  type="header"
                  selected={isAllSelected(rows)}
                  onClick={() => handleSelectRow({ type: "ALL" })}
                />
              ) : null}
              {columns.map((column, index) => (
                <TableCellHeader
                  key={`${column.fieldPath}_${index}`}
                  text={column.label}
                  width={column.minWidth}
                  height={column.headerHeight}
                  handleSortChange={column.handleSortChange}
                  sx={labelStyle(column.headerStyle || column.fieldType, theme)}
                />
              ))}
            </TableRow>
          </TableHead>
          {columns.length ? (
            <TableBody>
              {rows?.map((row, rowIndex) => {
                return (
                  <VisibilitySensor
                    key={`row_${rowIndex}`}
                    partialVisibility={true}
                    containment={refPaper.current}
                    onChange={() => findMinIndex()}
                  >
                    {({ isVisible }) => {
                      if (isVisible) {
                        visibleList.push(rowIndex)
                      } else {
                        visibleList = visibleList.filter((itemShow) => itemShow !== rowIndex)
                      }
                      return (
                        <TableRow
                          hover={false}
                          tabIndex={rowIndex}
                          sx={{
                            "&:nth-of-type(even)": { backgroundColor: theme.palette.background.default },
                            "&:nth-of-type(odd)": { backgroundColor: theme.palette.background.gray_2 },
                            backgroundColor: row.selected && `${theme.palette.background.yellow} !important`
                          }}
                        >
                          {(isVisible ||
                            Math.max(...visibleList) + 1 == rowIndex ||
                            Math.min(...visibleList) - 1 == rowIndex) &&
                          rowIndex >= minIndex ? (
                            <>
                              {handleSelectRow ? (
                                <TableCellCheckBox
                                  selected={row.selected}
                                  onClick={() => handleSelectRow({ type: "ROW", data: row })}
                                />
                              ) : null}
                              {columns.map((column, columnIndex) => {
                                let fieldConfig = {
                                  fieldType: column.fieldType,
                                  fieldPath: column.fieldPath,
                                  fieldProps: column.fieldProps,
                                  value: column.passWholeRowData ? row : row[column.fieldPath]
                                }
                                if (column.fieldType === "input") {
                                  fieldConfig.id = row.id
                                }
                                if (typeof column.checkError === "function" && column.checkError(row)) {
                                  fieldConfig.bodyStyle = "error"
                                }
                                return (
                                  <TableCellBody
                                    sx={labelStyle(
                                      fieldConfig.bodyStyle || column.bodyStyle || column.fieldType,
                                      theme
                                    )}
                                    key={`row_${rowIndex}-${columnIndex}`}
                                    align={column.align}
                                    verticalalign={column.verticalalign}
                                    height={column.bodyHeight}
                                    width={column.minWidth}
                                  >
                                    {createFieldComponent(fieldConfig)}
                                  </TableCellBody>
                                )
                              })}
                            </>
                          ) : (
                            <td style={{ height: "40px", visibility: "hidden" }} />
                          )}
                        </TableRow>
                      )
                    }}
                  </VisibilitySensor>
                )
              })}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
    </Paper>
  )
}

const labelStyle = (type, theme) => {
  switch (type) {
    case "label":
    case "transparent":
      return {
        backgroundColor: `${theme.palette.background.paper} !important`,
        border: "none !important"
      }
    case "paper":
      return {
        backgroundColor: `${theme.palette.background.paper} !important`
      }
    case "yellow_1":
      return {
        backgroundColor: `${theme.palette.background.yellow_1} !important`
      }
    case "label-dashboard":
      return {
        backgroundColor: `${theme.palette.background.dark} !important`
      }
    case "error":
      return {
        borderColor: `${theme.border.red} !important`
      }
    default:
      return {}
  }
}

export default memo(TableList)
