import { useState, useReducer, useEffect } from "react"
// Components
import Box from "@mui/material/Box"
import BasicTabs from "@components/tabs"
import { HSpace, Row, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import ModalBase from "../modal-base"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
import BasicInfo from "./basic-info"
import AddressInfo from "./address-info"
import CertificateInfo from "./certificate-info"
import BankInfo from "./bank-info"
import OtherInfo from "./other-info"
import AddNewCertificate from "./add-new-certificate"
// Styles
// Libs
import { t } from "i18next"
import mainReducer, {
  initialState,
  resetAll,
  setProfileByApi,
  selecFieldChange,
  inputFieldChange,
  fileFieldChange,
  isSaveChecking,
  convertToPayload,
  handleSaveChecking
} from "./reducers"
import tabReducer, { tabInitialState, tabResetAll, setCurrentTab, setErrorTab } from "./reducers/tab-list"
import certReducer, { certInitialState, newCertResetAll, newCertIsSaveChecking } from "./reducers/add-new-certificate"
import { cloneDeep } from "lodash"
import { fieldStatus } from "@constants"
import { formatDateWithTime } from "@utils/formatter"

const ModalTMProfile = ({ open, profile, onClose, onSave }) => {
  const [state, reducerDispatch] = useReducer(mainReducer, initialState)
  const [tabState, tabReducerDispatch] = useReducer(tabReducer, tabInitialState)
  const [certState, certReducerDispatch] = useReducer(certReducer, certInitialState)
  const [showNewCertificate, setShowNewCertificate] = useState(false)

  useEffect(() => {
    if (open) {
      tabReducerDispatch(tabResetAll())
      reducerDispatch(resetAll())
      if (profile?.id) {
        reducerDispatch(setProfileByApi(cloneDeep(profile)))
      }
    }
  }, [open, profile])

  const handleTabChange = (index) => {
    tabReducerDispatch(setCurrentTab(index))
  }

  const handleInputChange = (id, type, data) => {
    reducerDispatch(inputFieldChange({ id, data }))
  }

  const handleSelectChange = (id, type, data) => {
    switch (type) {
      case "datePicker":
        if (data) {
          reducerDispatch(selecFieldChange({ id, data: data.format("YYYY-MM-DD") }))
        }
        break
      case "file":
      case "removeFile":
      case "saveNewFile":
        reducerDispatch(fileFieldChange({ id, type, data }))
        break
      case "select":
        reducerDispatch(selecFieldChange({ id, type, data }))
        break
    }
  }

  const handleSaveClick = () => {
    if (showNewCertificate) {
      certReducerDispatch(newCertIsSaveChecking(true))
      if (certState.name.fieldStatus !== fieldStatus.EMPTY && certState.url.fieldStatus !== fieldStatus.EMPTY) {
        handleSelectChange("certs", "saveNewFile", { name: certState.name, url: certState.url })
        handleNewCertBack()
      }
    } else {
      reducerDispatch(isSaveChecking(true))
      let { isPass, tabList } = handleSaveChecking(state)
      if (isPass) {
        onSave && onSave(convertToPayload(state))
      } else {
        tabReducerDispatch(setErrorTab(tabList))
      }
    }
  }

  const handleCloseClick = () => {
    onClose && onClose()
  }

  const handleNewCertBack = () => {
    setShowNewCertificate(false)
    certReducerDispatch(newCertResetAll())
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="720px" expand={true} sx={{ border: "none !important" }}>
        <Close onClick={handleCloseClick} />

        {showNewCertificate ? (
          <AddNewCertificate
            state={certState}
            reducerDispatch={certReducerDispatch}
            handleBackClick={() => handleNewCertBack()}
          />
        ) : (
          <BasicTabs list={tabState.list} handleTabChange={handleTabChange} defaultTab={tabState.currentTab}>
            <BasicInfo state={state} handleInputChange={handleInputChange} handleSelectChange={handleSelectChange} />
            <AddressInfo state={state} handleInputChange={handleInputChange} handleSelectChange={handleSelectChange} />
            <CertificateInfo state={state} handleInputChange={handleInputChange} />
            <BankInfo state={state} handleInputChange={handleInputChange} />
            <OtherInfo
              state={state}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              createdAt={profile?.createdAt}
            />
          </BasicTabs>
        )}

        <ModalButtonWrapper color="yellow" justifycontent="space-between">
          {tabState.currentTab === 0 && profile?.lastAppOpenedAt ? (
            <Text width="60px" textalign="center" whitespace="nowrap" style={{ alignSelf: "start" }}>
              {t("MODAL_TMProfile_Field_last_open_app", { date: formatDateWithTime(profile.lastAppOpenedAt) })}
            </Text>
          ) : (
            <Box />
          )}
          <Row>
            {tabState.currentTab === 2 && !showNewCertificate ? (
              <ButtonWithImage {...buttonStyle} srcx="group" alt="add icon" onClick={() => setShowNewCertificate(true)}>
                {t("TEXT_COMMON_add")}
              </ButtonWithImage>
            ) : null}
            <HSpace length={10} />
            <ButtonWithImage {...buttonStyle} srcx="save-black" alt="save icon" onClick={() => handleSaveClick()}>
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  textcolor: "primary",
  borderradius: "13px",
  padding: "7px 33px",
  border: "transparent"
}

export default ModalTMProfile
