import { createSlice } from "@reduxjs/toolkit"
import { t } from "i18next"
import { cloneDeep, findIndex } from "lodash"
import { payStateOptionListDoc } from "@constants"

const makeFieldsBase = (payload) => {
  let result = {
    invoiceNo: payload.invoiceNo || "",
    customer: payload.customer || { name: "" },
    state: payload.state || "",
    orders: payload.orders || [],
    remarks: payload.remarks || "",
    dollarAmount: payload.dollarAmount || "",
    isEditable: true
  }
  if (result.state === payStateOptionListDoc.F.id) {
    result.isEditable = false
  }
  result.orders.forEach((item) => {
    item.shouldPayAmount = item.amount - item.invoicePaidAmount
  })

  return result
}

export const initialState = makeFieldsBase({
  invoiceNo: "",
  customer: { name: "" },
  state: "",
  orders: [],
  // local
  dollarAmount: "",
  remarks: "",
  isEditable: true
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    resetAll() {
      return initialState
    },
    setInvoicesByApi(state, { payload }) {
      let clonePayload = cloneDeep(payload)
      return makeFieldsBase(clonePayload)
    },
    inputFieldChange(state, { payload }) {
      let { id, data } = payload
      state[id] = data

      if (id === "dollarAmount") {
        let currentDollarAmount = Number(data) * 100

        state.orders.forEach((item) => {
          item.shouldPayAmount = item.amount - item.invoicePaidAmount

          if (currentDollarAmount) {
            if (currentDollarAmount >= item.shouldPayAmount) {
              currentDollarAmount = currentDollarAmount - item.shouldPayAmount
              item.shouldPayAmount = 0
            } else {
              item.shouldPayAmount = item.shouldPayAmount - currentDollarAmount
              currentDollarAmount = 0
            }
          }
        })
      }
    }
  }
})

export const { resetAll, setInvoicesByApi, inputFieldChange } = mainReducer.actions

export default mainReducer.reducer
