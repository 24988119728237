import { styled } from "@mui/styles"

import { CheckboxIconBase } from "@components/checkbox"
import { Box } from "@mui/material"
import { Column, Row, Text, HSpace } from "@layout"

import { formatDateOnlyTC, formatTimeWithDuration } from "@utils/formatter"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"

export const PerfectMatchItem = styled(({ checked, handleChange, nameZh, phone, rating, assignedGroup, ...props }) => (
  <Box {...props}>
    <Row>
      {checked !== null && <CheckboxIconBase onChange={handleChange} checked={checked} />}
      <HSpace length="5" />
      <Column>
        <Text size="16px" weight="500">
          {nameZh}#{phone}
        </Text>
        {!isEmpty(rating) && (
          <Text size="16px" weight="500">
            {t("MODAL_Manual_Match_rating", { rating: rating / 100 })}
          </Text>
        )}
        {assignedGroup && <Text size="16px">{t(`OPTION_COMMON_team_${assignedGroup}`)}</Text>}
      </Column>
    </Row>
  </Box>
))(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.border.gray_3,
  width: "300px",
  minHeight: "94px",
  borderRadius: "15px",
  padding: "10px 15px",
  marginBottom: "30px"
}))

export const CounterOfferItem = styled(
  ({ checked, handleChange, date, time, nameZh, phone, rating, duration, ...props }) => (
    <Box {...props}>
      <Row>
        {checked !== null && <CheckboxIconBase onChange={handleChange} checked={checked} />}
        <HSpace length="5" />
        <Column sx={{ width: "100%" }}>
          <Text size="16px" weight="500">
            {nameZh}#{phone}
          </Text>
          <Text size="16px" weight="500">
            {t("MODAL_Manual_Match_rating", { rating: rating / 100 })}
          </Text>
          <Text size="16px" weight="500">
            {formatDateOnlyTC(date)}
          </Text>
          <Row sx={{ justifyContent: "space-between" }}>
            <Text size="16px">{formatTimeWithDuration(time, duration)}</Text>
          </Row>
        </Column>
      </Row>
    </Box>
  )
)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.border.gray_3,
  width: "300px",
  borderRadius: "15px",
  padding: "10px 15px",
  gap: "10px",
  marginBottom: "30px"
}))
