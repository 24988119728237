import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { EHSDollarLogsApi } from "../apis"

export const getEHSDollarLogs = createAsyncThunk("ehsDollarLogs/getEHSDollarLogs", async (payload) => {
  return await EHSDollarLogsApi.getEHSDollarLogs(payload)
})

export const postEHSDollarLogs = createAsyncThunk("ehsDollarLogs/postEHSDollarLogs", async (payload) => {
  return await EHSDollarLogsApi.postEHSDollarLogs(payload)
})

const initialState = {}

const ehsDollarLogsSlice = createSlice({
  name: "ehsDollarLogsSlice",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = ehsDollarLogsSlice.actions
export default ehsDollarLogsSlice.reducer
