import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { ExportsApi } from "../apis"
import { setModalSettingsState, modalSettingsObj } from "@slices/modal-settings"

export const getExportCustomers = createAsyncThunk(
  "export/getExportCustomers",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportCustomers" }))
      return await ExportsApi.getExportCustomers(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportCustomers" }))
    }
  }
)

export const getExportUsers = createAsyncThunk(
  "export/getExportUsers",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportUsers" }))
      return await ExportsApi.getExportUsers(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportUsers" }))
    }
  }
)

export const getExportInvoices = createAsyncThunk(
  "export/getExportInvoices",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportInvoices" }))
      return await ExportsApi.getExportInvoices(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportInvoices" }))
    }
  }
)

export const getExportCheques = createAsyncThunk(
  "export/getExportCheques",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportCheques" }))
      return await ExportsApi.getExportCheques(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportCheques" }))
    }
  }
)

export const getExportTMUsers = createAsyncThunk(
  "export/getExportTMUsers",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMUsers" }))
      return await ExportsApi.getExportTMUsers(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMUsers" }))
    }
  }
)

export const getExportTMRosters = createAsyncThunk(
  "export/getExportTMRosters",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMRosters" }))
      return await ExportsApi.getExportTMRosters(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMRosters" }))
    }
  }
)

export const getExportTMRemarks = createAsyncThunk(
  "export/getExportTMRemarks",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMRemarks" }))
      return await ExportsApi.getExportTMRemarks(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMRemarks" }))
    }
  }
)

export const getExportTMApplications = createAsyncThunk(
  "export/getExportTMApplications",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMApplications" }))
      return await ExportsApi.getExportTMApplications(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMApplications" }))
    }
  }
)

export const getExportTM418Requests = createAsyncThunk(
  "export/getExportTM418Requests",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTM418Requests" }))
      return await ExportsApi.getExportTM418Requests(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTM418Requests" }))
    }
  }
)

export const getExportServices = createAsyncThunk(
  "export/getExportServices",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportServices" }))
      return await ExportsApi.getExportServices(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportServices" }))
    }
  }
)

export const getExportDollarLogs = createAsyncThunk(
  "export/getExportDollarLogs",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportDollarLogs" }))
      return await ExportsApi.getExportDollarLogs(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportDollarLogs" }))
    }
  }
)

export const getExportReviews = createAsyncThunk(
  "export/getExportReviews",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportReviews" }))
      return await ExportsApi.getExportReviews(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportReviews" }))
    }
  }
)

export const getExportBlacklists = createAsyncThunk(
  "export/getExportBlacklists",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportBlacklists" }))
      return await ExportsApi.getExportBlacklists(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportBlacklists" }))
    }
  }
)

export const getExportDiscountRules = createAsyncThunk(
  "export/getExportDiscountRules",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportDiscountRules" }))
      return await ExportsApi.getExportDiscountRules(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportDiscountRules" }))
    }
  }
)

export const getExportSMS = createAsyncThunk("export/getExportSMS", async (payload, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportSMS" }))
    return await ExportsApi.getExportSMS(payload)
  } catch (error) {
    return rejectWithValue(error)
  } finally {
    dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportSMS" }))
  }
})

export const getExportPushNotifications = createAsyncThunk(
  "export/getExportPushNotifications",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(
        setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportPushNotifications" })
      )
      return await ExportsApi.getExportPushNotifications(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(
        setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportPushNotifications" })
      )
    }
  }
)

export const getExportNotices = createAsyncThunk(
  "export/getExportNotices",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportNotices" }))
      return await ExportsApi.getExportNotices(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportNotices" }))
    }
  }
)

export const getExportFaqs = createAsyncThunk(
  "export/getExportFaqs",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportFaqs" }))
      return await ExportsApi.getExportFaqs(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportFaqs" }))
    }
  }
)

export const getExportOrders = createAsyncThunk(
  "export/getExportOrders",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportOrders" }))
      return await ExportsApi.getExportOrders(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportOrders" }))
    }
  }
)

export const getExportTMMiscCharges = createAsyncThunk(
  "export/getExportTMMiscCharges",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMMiscCharges" }))
      return await ExportsApi.getExportTMMiscCharges(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "export/getExportTMMiscCharges" }))
    }
  }
)

const initialState = {}

const exportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = exportSlice.actions
export default exportSlice.reducer
