import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep, forEach } from "lodash"
import { isEmpty } from "@utils/validator"

export const initialState = {}

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: initialState,
  reducers: {
    setInitialState(state, { payload }) {
      return payload
    },
    setInitialStateByStore(state, { payload }) {
      let cloneInitialState = cloneDeep(payload.initialState)
      let cloneSearchToolParams = cloneDeep(payload.searchToolParams)

      forEach(cloneInitialState, (data, key) => {
        if (!isEmpty(cloneSearchToolParams[key])) {
          cloneInitialState[key] = cloneSearchToolParams[key]
        }
      })

      cloneInitialState.defaultNotIncludeField = {}
      forEach(cloneSearchToolParams, (data, key) => {
        if (!cloneInitialState.hasOwnProperty(key)) {
          cloneInitialState.defaultNotIncludeField[key] = data
        }
      })
      return cloneInitialState
    },
    inputFieldChange(state, { payload }) {
      let { id, data } = payload

      state[id] = data
    },
    selectFieldChange(state, { payload }) {
      let { id, data, selectItem = null } = payload

      state[id] = data

      switch (id) {
        case "district":
          state.partition = ""
          break
        case "serviceType":
          state.subService = []
          break
      }
    }
  }
})

export const getInitialState = (fieldList) => {
  if (fieldList?.length) {
    return fieldList.reduce((obj, currnt) => {
      if (currnt.multiple) {
        return { ...obj, [currnt.id]: [] }
      } else {
        return { ...obj, [currnt.id]: "" }
      }
    }, {})
  }
  return {}
}

export const { setInitialState, setInitialStateByStore, inputFieldChange, selectFieldChange } = mainReducer.actions

export default mainReducer.reducer
