import { makeStyles, styled, useTheme } from "@mui/styles"
import hideScroll from "@styles/hideScroll"
import Box from "@mui/material/Box"
import muiTabs from "@mui/material/Tabs"
import muiTab from "@mui/material/Tab"

const useStyles = makeStyles({
  TabDividerTop: {
    position: "absolute",
    bottom: "0.8px",
    width: "100%",
    height: "1px",
    backgroundColor: ({ theme, divider }) => theme?.customDivider[divider || "gray"]
  },
  TabDividerBottom: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "1px",
    zIndex: 2,
    backgroundColor: ({ theme }) => theme?.palette.background.default
  }
})

export const TabDivider = (props) => {
  const theme = useTheme()
  const classes = useStyles({ ...props, theme })
  return (
    <>
      <div className={classes.TabDividerTop} />
      <div className={classes.TabDividerBottom} />
    </>
  )
}

export const Tab = styled(muiTab)(({ theme, color, error = false }) => {
  let style = {
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px 7px",
    minWidth: "unset",
    minHeight: "unset",
    borderRadius: "10px 10px 0 0",
    marginRight: "30px",
    color: theme.tabs[color].text,
    backgroundColor: theme.tabs[color].background,
    display: "block"
  }
  if (error) {
    style["&::after"] = {
      content: "'*'",
      color: theme.palette.text.red
    }
  } else {
    style["&::after"] = {
      content: ""
    }
  }

  return style
})

export const Tabs = styled(muiTabs)(({ theme, color }) => ({
  minHeight: "unset",
  paddingLeft: "16px",
  "& .MuiTabs-scroller": {
    overflow: "auto !important",
    ...hideScroll
  },
  "& .Mui-selected": {
    color: theme.tabs[color].contrastText,
    backgroundColor: theme.tabs[color].contrastBackground,
    zIndex: 1,
    border: "1px solid",
    borderColor: theme.tabs[color].borderColor,
    borderRadius: "10px 10px 0 0",
    borderBottom: `1px solid ${theme.tabs[color].borderBottomColor}`
  },
  "& .MuiTabs-indicator": {
    display: "none",
    zIndex: 1,
    height: "100%",
    background: "none",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "10px 10px 0 0"
  }
}))

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props
  return (
    <Box
      sx={{
        overflow: "auto",
        maxHeight: "calc(100% - 30px)",
        height: "calc(100% - 30px)",
        padding: "10px 16px"
      }}
      hidden={value !== index}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}
