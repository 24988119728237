// Components
import Box from "@mui/material/Box"
import { VFieldContainer } from "@components/common"
// Styles
// Libs
import { t } from "i18next"

const BankInfo = ({ state, handleInputChange }) => {
  return (
    <Box>
      <VFieldContainer
        index={0}
        width="296px"
        id={"bankName"}
        value={state.bankName.value}
        label={t("MODAL_TMProfile_Field_bank_name")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={1}
        width="296px"
        id={"bankAccountName"}
        value={state.bankAccountName.value}
        label={t("MODAL_TMProfile_Field_bank_account_name")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={2}
        width="296px"
        id={"bankAccountNumber"}
        value={state.bankAccountNumber.value}
        label={t("MODAL_TMProfile_Field_bank_account_number")}
        type="input"
        handleChange={handleInputChange}
      />
    </Box>
  )
}

export default BankInfo
