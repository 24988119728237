import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useRouter } from "next/router"

import ModalOverlay from "../modal-overlay"
import ModalMessage from "../modal-message"
import { Column, VSpace, Row, Text } from "@layout"
import { InputSearch } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"
import BasicTabs from "@components/tabs"
import { InputBase, InputTextArea } from "@components/inputs"

import { getEHSDollarLogs, postEHSDollarLogs } from "@slices/ehs-dollar-logs"
import { t } from "i18next"
import { getCustomers } from "@slices/customers"
import { isEmpty } from "@utils/validator"

const initalState = { adjustment: 0, remarks: "" }

const ModalAdjustDollarWithList = ({ onSave, ...props }) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const [selected, setSelected] = useState({})
  const [state, setState] = useState(initalState)
  const [customerList, setCustomerList] = useState([])
  const [search, setSearch] = useState("")

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      setSelected({})
      setState(initalState)
      setSearch("")
      setCustomerList([])
      // dispatchGetCustomers()
    }
  }, [props.open])

  const dispatchGetCustomers = async (keyword) => {
    let type = router.pathname.includes("company") ? "organization" : "individual"
    let { results } = await dispatch(getCustomers({ type, keyword, limit: 0 })).unwrap()
    setCustomerList(results)
  }

  const dispatchGetEHSDollarLogsAmount = async () => {
    let { results } = await dispatch(
      getEHSDollarLogs({
        "customer.customerId": selected.customerId,
        sort: "createdAt",
        order: "desc",
        limit: 1
      })
    ).unwrap()
    return results[0]?.amountAfter || 0
  }

  const handleSelect = (customer) => {
    setSelected(selected.customerId === customer.customerId ? {} : customer)
  }

  const handleChange = (type, value) => {
    if (type === "adjustment") {
      let reg = new RegExp(/^(\-)?\d+(\.\d{1})?$/)
      if (value && !reg.test(value)) return
    }
    setState({ ...state, [type]: value })
  }

  const handleSave = async () => {
    if (!selected.customerId) {
      setErrorMessage({ message: t("ERROR_COMMON_please_select_customer") })
      return
    }

    let paylaod = {
        customerId: selected.customerId,
        adjustment: state.adjustment * 100,
        remarks: state.remarks
      },
      amountAfter = await dispatchGetEHSDollarLogsAmount()

    console.log(paylaod)

    if (paylaod.adjustment + amountAfter < 0) {
      setErrorMessage({ message: t("ERROR_COMMON_over_amount") })
    } else {
      dispatch(postEHSDollarLogs(paylaod))
        .unwrap()
        .then(() => onSave())
    }
  }

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  const buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSave }]

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <BasicTabs list={[{ label: t("TAB_basic_info") }]}>
          <Column sx={{ padding: "25px 22px" }}>
            <InputSearch
              width="65%"
              value={search}
              handleChange={setSearch}
              handleSearch={() => dispatchGetCustomers(search)}
            />
            <VSpace length="15" />
            <Row align="flex-start" sx={{ width: "100%" }}>
              <Column sx={{ width: "50%" }}>
                {customerList.map((item) => (
                  <Row key={`modal adjust dollar customer ${item.customerId}`} sx={{ margin: "10px 0" }}>
                    <CheckboxBase
                      round={false}
                      label={`${item.name} #${item.phone}`}
                      onChange={() => handleSelect(item)}
                      checked={selected?.customerId === item.customerId}
                    />
                  </Row>
                ))}
              </Column>
              <Column sx={{ width: "275px" }}>
                <Row sx={{ justifyContent: "space-between" }}>
                  <Text weight="500">{t("POPOVER_CASH_cash_title")}</Text>
                  <InputBase
                    type="number"
                    padding="2px"
                    bordercolor="gray"
                    center="1"
                    sx={{ maxWidth: "100px" }}
                    value={state.adjustment}
                    onChange={(event) => handleChange("adjustment", event.target.value)}
                  />
                </Row>
                <VSpace length={5} />
                <Text weight="300">{t("POPOVER_CASH_cash_subtitle")}</Text>
                <VSpace length={11} />
                <Text weight="500">{t("TABLE_remark")}</Text>
                <VSpace length={11} />
                <InputTextArea
                  width="100%"
                  height="146px"
                  onChange={(event) => handleChange("remarks", event.target.value)}
                  defaultValue={state.remarks}
                />
                <VSpace length={5} />
              </Column>
            </Row>
          </Column>
        </BasicTabs>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalAdjustDollarWithList
