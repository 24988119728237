import { styled } from "@mui/styles"
import Box from "@mui/material/Box"

export const AddressButton = styled("button")(({ theme, selected, error = "false" }) => {
  let style = {
    fontSize: "14px",
    fontWeight: 300,
    textAlign: "left",
    padding: "5px",
    border: "none",
    borderRadius: "2px",
    cursor: "pointer",
    marginBottom: "5px",
    backgroundColor: theme.palette.background.default
  }
  if (selected) {
    style.cursor = "default"
    style.border = `1px solid ${theme.border.yellow}`
    style.backgroundColor = theme.palette.background.yellow_1
  }
  if (error == "true") {
    style["&::after"] = {
      content: "'*'",
      color: theme.palette.text.red
    }
  } else {
    style["&::after"] = {
      content: ""
    }
  }
  return style
})
