import { Fragment } from "react"

import { Text, Column } from "@layout"
import { Box } from "@mui/material"

import { map, isObject, isArray } from "lodash"
import { convertIdToLabel, formatDateWithTime } from "@utils/formatter"
import { auditLogsList } from "@constants"
import { isEmpty } from "@utils/validator"

const isLink = (value) =>
  /^(http)/.test(value) ? (
    <a href={value} target="_blank">
      {value}
    </a>
  ) : (
    value
  )

const AuditLogsField = ({ fieldPath, fieldProps, value }) => {
  // audit logs need to set - passWholeRowData: true

  const isMultipleLine = isObject(value[fieldPath])
  const list_order = auditLogsList(value.targetType, value.category, "key").reduce(
    (obj, curr) => ({ ...obj, [curr.id]: curr.order }),
    {}
  )
  let displayValue = isEmpty(value[fieldPath]) ? "-" : value[fieldPath]

  const format = (data, type) => {
    // type = "key" or "value"
    const { targetType, category } = value
    let list = auditLogsList(targetType, category, type)
    return list ? convertIdToLabel(data, list) : data
  }

  const formatter = (data, k) => {
    if (isArray(data)) {
      if (data[0]?.label) {
        data = data.map((e) => ({
          label: format(k ? `${k}_${e.label}` : e.label, "key"),
          value: formatter(e.value, e.label)
        }))
      } else {
        switch (k) {
          case "certs":
            // certs = [{name , url}]
            data = data.map(({ name, url }) => ({ label: name, value: url }))
            break
          case "applyJobTypes":
            // applyJobTypes = [{id , name}]
            data = data.map(({ name }) => name).join(", ")
            break
          default:
            data = data.map((e) => format(e, "value")).join(", ")
            break
        }
      }
    } else if (isObject(data)) {
      data = map(data, (val, key) => ({
        label: format(k ? `${k}_${key}` : key, "key"),
        value: formatter(val, key)
      }))
    } else {
      switch (k) {
        case "rating":
          data = data / 100
          break
        case "signInAt":
        case "signOutAt":
          data = formatDateWithTime(data)
          break
      }
      return isEmpty(data) ? "-" : format(data, "value")
    }
    return data
  }

  if (isMultipleLine) {
    displayValue = map(displayValue, (value, label) => ({ label, value }))
    displayValue.sort((a, b) => list_order[a.label] - list_order[b.label])
    displayValue = formatter(displayValue)
  } else {
    displayValue = isEmpty(value[fieldPath]) ? "-" : format(displayValue, "value")
  }
  return (
    <>
      {isMultipleLine ? (
        <Column>
          {displayValue.map((val) => (
            <Fragment key={`${val.label} - ${value.id}`}>
              {isArray(val?.value) ? (
                <>
                  <Text {...fieldProps?.style}>{val.label} :</Text>
                  <Box sx={{ paddingLeft: "14px", display: "flex", flexDirection: "column" }}>
                    {val.value.map((val_val) => (
                      <Text key={`${val_val.label} - ${value.id}`} {...fieldProps?.style}>
                        {val_val.label} : {isLink(val_val.value)}
                      </Text>
                    ))}
                  </Box>
                </>
              ) : (
                <Text {...fieldProps?.style}>
                  {val.label} : {isLink(val.value)}
                </Text>
              )}
            </Fragment>
          ))}
        </Column>
      ) : (
        <Text {...fieldProps?.style}>{displayValue}</Text>
      )}
    </>
  )
}

export default AuditLogsField
