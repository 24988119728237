import { SelectBase } from "@components/selects"
import { CheckboxBase } from "@components/checkbox"
import { ButtonWithImage } from "@components/buttons"
import { VSpace, Text, Row } from "@layout"
import { InputWrapper, InputText, InputTextArea, InputPhone, InputDatePicker } from "@components/inputs"
import { InputAdornment } from "@mui/material"
import { formatFieldToPrice } from "@utils/formatter"
import { useTheme } from "@mui/styles"
import File from "@components/file"
import dynamic from "next/dynamic"

export const VFieldContainer = ({
  id,
  label,
  index,
  type,
  inputType = "text",
  height,
  width,
  list,
  value,
  format,
  handleChange,
  required,
  disabled,
  fieldWeight,
  placeholder = "",
  multiple = false,
  display = true,
  error = false,
  ...rest
}) => {
  const component = () => {
    switch (type) {
      case "select":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
              <SelectBase
                size="14px"
                weight={fieldWeight || "300"}
                value={value}
                list={list}
                error={error}
                multiple={multiple}
                onSelect={(event, selectItem) => handleChange(id, type, event.value, selectItem)}
                disabled={disabled}
                {...rest}
              />
            </InputWrapper>
          )
        )
      case "input":
      case "input-time":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
              <InputText
                type={inputType}
                value={inputType === "number" ? value : value || ""}
                error={error}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(event) => handleChange(id, type, event.target.value)}
                {...rest}
              />
            </InputWrapper>
          )
        )
      case "input-dollar":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" margin="0 170px 0 0" required={required}>
              <InputText
                type="number"
                value={formatFieldToPrice(value)}
                error={error}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={(event) => handleChange(id, type, Number(event.target.value))}
              />
            </InputWrapper>
          )
        )
      case "text":
        return (
          display && (
            <Text whitespace="nowrap" weight="500">
              {label}: {format ? format(value, list) : value}
            </Text>
          )
        )
      case "input-textarea":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
              <InputTextArea
                width="100%"
                height={height}
                value={value}
                error={error}
                disabled={disabled}
                onChange={(event) => handleChange(id, type, event.target.value)}
              />
            </InputWrapper>
          )
        )
      case "phone":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
              <InputPhone error={error} value={value} handleChange={handleChange} />
            </InputWrapper>
          )
        )
      case "datePicker":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
              <InputDatePicker
                id={id}
                value={value}
                disabled={disabled}
                error={error}
                handleChange={(value) => handleChange(id, type, value)}
                {...rest}
              />
            </InputWrapper>
          )
        )
      case "checkbox":
        return (
          display && (
            <InputWrapper error={error} width={width} label={label} size="14px" weight="500" required={required}>
              {list.map((item) => (
                <Row key={item.label} sx={{ margin: "10px 0" }}>
                  <CheckboxBase
                    round={false}
                    label={item.label}
                    onChange={() => handleChange(id, type, item.id)}
                    checked={value?.includes(item.id)}
                  />
                </Row>
              ))}
            </InputWrapper>
          )
        )
      case "checkbox-single":
        return (
          display && (
            <CheckboxBase
              round={false}
              label={label}
              onChange={(event) => handleChange(id, type, event.target.checked)}
              checked={value}
            />
          )
        )
      case "button":
        return (
          display && (
            <ButtonWithImage
              width={width}
              imgW="21px"
              imgH="21px"
              label={label}
              onClick={() => handleChange(id, type)}
              src="edit-black"
              color="secondary_black"
              error={Number(error)}
              {...rest}
            />
          )
        )
      case "file":
        return (
          display && (
            <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
              <File
                error={error}
                id="imageUrl"
                fileURL={value}
                fileName="service photo"
                handleAddFile={handleChange}
                handleRemoveFile={handleChange}
              />
            </InputWrapper>
          )
        )
      case "editor":
        return (
          display &&
          value !== null && (
            <InputWrapper
              error={error}
              width={width}
              height={height}
              label={label}
              size="14px"
              weight="500"
              required={required}
              {...rest}
            >
              <TextEditor error={error} initValue={value} handleEditorChange={(html) => handleChange(id, type, html)} />
            </InputWrapper>
          )
        )
    }
  }
  return (
    <>
      {!!index && display && <VSpace length={15} />}
      {component()}
    </>
  )
}

export const OneLineVFieldContainer = ({ label, index, width, children, required, error = false }) => {
  const theme = useTheme()

  return (
    <>
      {!!index && <VSpace length={15} />}
      <InputWrapper width={width} label={label} size="14px" weight="500" required={required}>
        <Row sx={{ border: `1px solid ${error ? theme.border.red : theme.border.gray_3}`, borderRadius: "2px" }}>
          {children}
        </Row>
      </InputWrapper>
    </>
  )
}

const TextEditor = dynamic(
  () => {
    return import("@components/html-editor").then((mod) => mod.TextEditor)
  },
  { ssr: false }
)
