import { useState } from "react"
import { ButtonBase } from "@components/buttons"
import { useTheme } from "@mui/styles"

import { PopoverCash } from "@components/popover"

const PopoverCashField = ({ fieldPath, fieldProps, value }) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ButtonBase label={fieldProps.text} onClick={handlePopoverClick} {...fieldProps.style} />
      <PopoverCash
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        onConfirm={(popoverData) => fieldProps.handleClickConfirm({ popoverData, value })}
      />
    </>
  )
}

export default PopoverCashField
