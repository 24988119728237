import { InputText } from "@components/inputs"
import { isEmpty } from "@utils/validator"

const InputField = ({ fieldPath, fieldProps, value, id }) => {
  let defaultValue = isEmpty(value) ? "" : value
  if (defaultValue?.hasOwnProperty(fieldPath)) {
    defaultValue = defaultValue[fieldPath]
  }

  if (fieldProps?.format) {
    defaultValue = fieldProps.format(defaultValue)
  }

  return (
    <InputText
      type="number"
      bordercolor="transparent"
      sx={{ padding: "0 !important" }}
      background="transparent"
      {...fieldProps?.style}
      onChange={(event) => fieldProps?.handleChange(id, event.target.value)}
      value={defaultValue}
    />
  )
}

export default InputField
