import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
// Components
import { ModalContent, ModalButtonWrapper } from "./common"
import ModalBase from "./modal-base"
import { ButtonWithImage } from "@components/buttons"
import { Text, Column, Row, VSpace } from "@layout"
import { ImgBase } from "@components/images"
import { Box, Button } from "@mui/material"
// Libs
import { t } from "i18next"
import { styled } from "@mui/styles"
import { isEmpty } from "@utils/validator"

import { getCustomerDollarBalanceById } from "@slices/customers"

const ModalPaymentMethod = ({
  onConfirm,
  onClose,
  isIndividual,
  customerId,
  open,
  finalPrice,
  setMessageOpen,
  setErrorMessage
}) => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState("")
  const [EHSDollar, setEHSDollar] = useState(null)

  useEffect(() => {
    if (open) {
      setSelected("")
      setEHSDollar(null)
      dispatchGetEHSDollar(customerId)
    }
  }, [open])

  const dispatchGetEHSDollar = async (customerId) => {
    let res = await dispatch(getCustomerDollarBalanceById(customerId)).unwrap()

    if (res?.balance) {
      setEHSDollar(Number(res.balance) / 100)
    } else {
      setEHSDollar(0)
    }
  }

  const handleSelect = (event) => {
    setSelected(event.target.id)
  }

  const handleClickConfirm = () => {
    if (selected === "ehs_dollar") {
      if (EHSDollar >= finalPrice) {
        onConfirm(selected)
      } else {
        setErrorMessage({ message: t("ERROR_COMMON_insufficient_account_balance") })
        setMessageOpen(true)
      }
    } else {
      onConfirm(selected)
    }
  }

  const buttonList = [
    {
      label: t("TEXT_COMMON_cancel"),
      src: "white-delete",
      textcolor: "white",
      onClick: () => onClose(),
      imgW: "15px",
      color: "secondary"
    },
    {
      label: t("TEXT_COMMON_confirm"),
      src: "check-white",
      textcolor: "white",
      onClick: handleClickConfirm,
      imgW: "15px",
      disabled: isEmpty(selected)
    }
  ]

  return (
    <ModalBase open={open} onClose={onClose}>
      <ModalContent maxwidth="534px">
        <Column sx={{ padding: "12px 18px", minHeight: "300px" }}>
          <Box sx={{ gap: "12px", display: "flex", alignItems: "center" }}>
            <ImgBase src="card" />
            <Text size="18px" weight="500">
              {t("MODAL_Payment_Method_how_to_pay")}
            </Text>
          </Box>
          <VSpace length={45} />
          <PaymentMethodItem id="ehs_dollar" onClick={handleSelect} selected={selected === "ehs_dollar"}>
            {t("MODAL_Payment_Method_EHS")}
          </PaymentMethodItem>
          {isIndividual ? (
            <PaymentMethodItem id="credit_card" onClick={handleSelect} selected={selected === "credit_card"}>
              {t("MODAL_Payment_Method_APP")}
            </PaymentMethodItem>
          ) : null}
          {isIndividual ? null : (
            <PaymentMethodItem id="invoice" onClick={handleSelect} selected={selected === "invoice"}>
              {t("MODAL_Payment_Method_institution")}
            </PaymentMethodItem>
          )}
        </Column>
        {EHSDollar !== null ? (
          <ModalButtonWrapper color="gray" justifycontent="space-between">
            <Row>
              {t("MODAL_Payment_Method_account_balance")}: ${EHSDollar}
            </Row>
            <Row sx={{ gap: "33px" }}>
              {buttonList?.map((btnProp) => (
                <ButtonWithImage sx={{ width: "115px" }} key={`modal button ${btnProp.label}`} {...btnProp} />
              ))}
            </Row>
          </ModalButtonWrapper>
        ) : null}
      </ModalContent>
    </ModalBase>
  )
}

const PaymentMethodItem = styled(Button)(({ theme, selected }) => ({
  border: "1px solid",
  borderColor: theme.border.yellow,
  padding: "18px",
  borderRadius: "3px",
  marginBottom: "15px",
  cursor: "pointer",
  justifyContent: "flex-start",
  fontSize: "16px",
  fontWeight: "400",
  color: theme.palette.text.brown_2,
  backgroundColor: theme.palette.background[selected ? "yellow_light" : "default"],
  "&:hover": {
    backgroundColor: theme.palette.background.yellow_light
  }
}))

export default ModalPaymentMethod
