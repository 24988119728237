import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getLinkableUsers, postLinkUsers } from "@slices/users"
import { t } from "i18next"

import ModalOverlay from "./modal-overlay"
import BasicTabs from "@components/tabs"
import { Column, VSpace, Row } from "@layout"
import { InputSearch } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"
import { cloneDeep } from "lodash"

const ModalLinkUser = ({ customerId, onSave, ...props }) => {
  const dispatch = useDispatch()
  const [userIds, setUserIds] = useState([])
  const [userList, setUserList] = useState([])
  const [search, setSearch] = useState("")

  // console.log({ userIds, userList })

  useEffect(() => {
    if (props.open) {
      setSearch("")
      setUserIds([])
      dispatcLinkableUsers()
    }
  }, [props.open])

  const dispatcLinkableUsers = async (keyword) => {
    const { results } = await dispatch(getLinkableUsers({ customerId, keyword })).unwrap()
    setUserList(results)
  }

  const handleSaveClick = () => {
    dispatch(postLinkUsers({ customerId, userIds })).then(() => onSave())
  }

  const handleChange = (value) => {
    let payload = cloneDeep(userIds) || [],
      indexOf = payload.indexOf(value)

    if (indexOf > -1) {
      payload.splice(indexOf, 1)
    } else {
      payload.push(value)
    }
    setUserIds(payload)
  }

  const buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSaveClick }]

  return (
    <ModalOverlay {...props} buttonList={buttonList}>
      <BasicTabs list={[{ label: t("TAB_basic_info") }]}>
        <Column sx={{ padding: "25px 22px" }}>
          <InputSearch
            width="60%"
            value={search}
            handleChange={setSearch}
            handleSearch={() => dispatcLinkableUsers(search)}
          />
          <VSpace length="15" />
          {userList.map(({ name, phone, userId }, key) => (
            <Row key={`modal link users ${userId} ${key}`} sx={{ margin: "10px 0" }}>
              <CheckboxBase
                round={false}
                label={`${name} #${phone}`}
                onChange={() => handleChange(userId)}
                checked={userIds.includes(userId)}
              />
            </Row>
          ))}
        </Column>
      </BasicTabs>
    </ModalOverlay>
  )
}

export default ModalLinkUser
