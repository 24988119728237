import Modal from "./modal"
import { Text, VSpace } from "@layout"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"

const ModalMessage = ({ message, open, onClose, onConfirm, errorMessage, title = "" }) => {
  const modalConfig = {
    title: isEmpty(errorMessage) ? title : "Error Message",
    titleSize: "18px",
    buttonList: [
      {
        label: t("TEXT_COMMON_confirm"),
        onClick: () => {
          onClose()
          onConfirm && onConfirm()
        }
      }
    ],
    onClose,
    open
  }

  return (
    <Modal {...modalConfig}>
      {isEmpty(errorMessage) ? (
        <Text size="18px" weight="normal">
          {message}
        </Text>
      ) : (
        <>
          <VSpace length="35" />
          <Text size="18px" weight="normal">
            {errorMessage.data ? errorMessage.data.message : errorMessage.message}
          </Text>
        </>
      )}
    </Modal>
  )
}
export default ModalMessage
