import { Text } from "@layout"
import { isEmpty } from "@utils/validator"

const DownloadField = ({ fieldPath, fieldProps, value }) => {
  let displayValue = isEmpty(value) ? "-" : value
  let styles = typeof fieldProps.style === "function" ? fieldProps.style(value[fieldPath]) : fieldProps.style

  styles = {
    size: "16px",
    weight: "500",
    cursor: "pointer",
    color: "blue_light",
    ...styles
  }

  if (fieldProps?.format) {
    displayValue = fieldProps.format(value)
  }

  return (
    <>
      {displayValue === "-" ? (
        <Text>{displayValue}</Text>
      ) : (
        <Text {...styles} onClick={() => fieldProps.handleClick(value)}>
          {displayValue}
        </Text>
      )}
    </>
  )
}

export default DownloadField
