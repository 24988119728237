import { useEffect, useState } from "react"
// Components
import ModalBase from "./modal-base"
import { ImgExternal } from "@components/images"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
// Libs
import { t } from "i18next"

const ModalFileCheck = ({ open, onClose, file }) => {
  const [state, setState] = useState(null)

  useEffect(() => {
    if (open) {
      setState(file)
    }
  }, [open])

  return (
    <>
      <ModalBase open={open} onClose={onClose}>
        <ModalContent maxwidth="742px" sx={{ padding: "0px !important" }}>
          <Close onClick={onClose} />
          <ImgExternal src={state} alt={"picture"} height="calc(90vh - 40px)" width="100%" />
        </ModalContent>
      </ModalBase>
    </>
  )
}

export default ModalFileCheck
