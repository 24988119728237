import { useState, useReducer, useEffect, Fragment } from "react"
import { useSelector } from "react-redux"
// Components
import Box from "@mui/material/Box"
import BasicTabs from "@components/tabs"
import { ButtonWithImage } from "@components/buttons"
import { HSpace, Row, VSpace, Text } from "@layout"
import ModalBase from "../modal-base"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
import { CheckboxBase } from "@components/checkbox"
import { InputText } from "@components/inputs"
// Styles
// Libs
import { t } from "i18next"
import mainReducer, {
  initialState,
  setInitialState,
  checkFieldChange,
  inputFieldChange,
  convertToPayload,
  isInvalidSaveChecking
} from "./reducers"
import { ServiceDetailsList } from "@selector"
import { isInvalidDigits } from "@utils/validator"

const tabList = [{ label: t("TAB_service_detail") }]

const ModalServiceDetail = ({ open, onClose, onSave, detailIds, type }) => {
  const serviceDetailsList = useSelector(ServiceDetailsList)
  const [isSaveChecking, setIsSaveChecking] = useState(false)

  const [state, reducerDispatch] = useReducer(mainReducer, initialState)

  useEffect(() => {
    if (open) {
      const serviceDetailsListFilter = serviceDetailsList.filter((item) => item.type === type)

      reducerDispatch(setInitialState({ list: serviceDetailsListFilter, detailIds }))
    }
  }, [serviceDetailsList, type, detailIds, open])

  const handleSaveClick = () => {
    if (isInvalidSaveChecking(state)) {
      setIsSaveChecking(true)
    } else {
      onSave && onSave(convertToPayload(state))
      handleCloseClick()
    }
  }

  const handleCloseClick = () => {
    setIsSaveChecking(false)
    onClose && onClose()
  }

  return (
    <ModalBase open={open}>
      <ModalContent maxwidth="720px" expand={true} sx={{ border: "none !important" }}>
        <Close onClick={handleCloseClick} />

        <BasicTabs list={tabList}>
          <Box sx={{ padding: "12px 98px 12px 38px" }}>
            <Row>
              <HSpace length={94} />
              <Text style={{ width: "calc(100% - 164px)" }}>{t("MODAL_ServiceDetail_Field_detail_name")}</Text>
              <Text textalign="center" style={{ width: "70px" }}>
                {t("MODAL_ServiceDetail_Field_sequence")}
              </Text>
            </Row>
            <VSpace length={15} />
            {state.map((item, index) => (
              <Fragment key={`detail_item_${index}`}>
                <Row>
                  <CheckboxBase
                    round={false}
                    label={item.name}
                    checked={item.checked}
                    boxProps={{ gap: "74px", width: "calc(100% - 70px)" }}
                    onChange={(event) => reducerDispatch(checkFieldChange({ index }))}
                  />
                  <Box sx={{ width: "70px" }}>
                    <InputText
                      value={item.sequence}
                      error={isSaveChecking && item.checked && isInvalidDigits(item.sequence)}
                      textalign="center"
                      onChange={(event) =>
                        reducerDispatch(inputFieldChange({ index, id: "sequence", value: event.target.value }))
                      }
                    />
                  </Box>
                </Row>
                <VSpace length={18} />
              </Fragment>
            ))}
          </Box>
        </BasicTabs>

        <ModalButtonWrapper color="yellow">
          <Row>
            <ButtonWithImage {...buttonStyle} srcx="save-black" alt="save icon" onClick={() => handleSaveClick()}>
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </Row>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  textcolor: "primary",
  borderradius: "13px",
  padding: "7px 33px",
  border: "transparent"
}

export default ModalServiceDetail
