import {
  orderStatusList,
  districtList,
  stateOptionList,
  customerStateOptionList,
  userGenderOptionList,
  TC_titleOptionList,
  TC_applyStatusOptionList,
  groupOptionList,
  group418OptionList,
  servicesTypeOptionList,
  discountTypeOptionList,
  paymentStatusOptionList,
  payStateOptionList,
  chequeStateOptionList,
  discountTargetOptionList,
  TMMiscChargeTypeOptionList,
  monthOptionList
} from "@constants"

// FIXED_ID_CANT_CHANGE = ["district", "partition", "serviceType", "subService"]

const adminListConfig = [
  // done
  { type: "text", id: "email", label: "TABLE_admin_login_name" },
  { type: "text", id: "name", label: "TABLE_admin_display_name" }
]

const orderListConfig = [
  // done
  { type: "select", id: "status", label: "TABLE_order_state", multiple: true, list: orderStatusList },
  { type: "text", id: "orderNo", label: "TABLE_orderId" },
  { type: "select", id: "paymentStatus", label: "TABLE_payment_state", multiple: true, list: paymentStatusOptionList },
  { type: "text", id: "customer.name", label: "TABLE_customer_name" },
  { type: "text", id: "customer.phone", label: "TABLE_customer_phone" },
  { type: "text", id: "user.name", label: "TABLE_user_name" },
  { type: "text", id: "user.phone", label: "TABLE_user_phone" },
  { type: "text", id: "tmUser.nameZh", label: "TABLE_TM_user_name" },
  { type: "text", id: "tmUser.phone", label: "TABLE_TM_user_phone" },
  { type: "text", id: "fulfilmentNo", label: "TABLE_fulfilment_no" },
  { type: "select", id: "district", label: "TABLE_service_area", multiple: false, list: districtList },
  { type: "select-partition", id: "partition", label: "TABLE_service_partition", multiple: false, list: [] },
  { type: "datePicker", id: "date", label: "TABLE_working_date" }
]
const orderTmRemarkConfig = [
  // done
  { type: "text", id: "orderNo", label: "TABLE_orderId" },
  { type: "text", id: "customer.name", label: "MODAL_CustimerProfile_name" },
  { type: "text", id: "user.name", label: "TABLE_user_name" },
  { type: "text", id: "tmUser.nameZh", label: "TABLE_TM_user_name" }
]
const orderPaymentListConfig = [
  { type: "text", id: "customerNumber", label: "TABLE_customer_number" },
  { type: "text", id: "invoiceNumber", label: "TABLE_invoice_number" },
  { type: "text", id: "chequeNumber", label: "TABLE_cheque_number" },
  { type: "select", id: "paymentMethod", label: "TABLE_payment_method", multiple: true, list: [] }, // TODO: paymentMethodList
  { type: "text", id: "amount", label: "TABLE_amount" },
  { type: "text", id: "orderId", label: "TABLE_orderId" },
  { type: "text", id: "fulfilmentNo", label: "TABLE_fulfilment_no" }
]

const customerListConfig = [
  // done
  { type: "select", id: "state", label: "TABLE_state", list: customerStateOptionList },
  { type: "text", id: "customerNo", label: "TABLE_customerId" },
  { type: "text", id: "name", label: "TABLE_customer_name" },
  { type: "text", id: "phone", label: "TABLE_customer_phone" }
]
const customerAccountBalanceConfig = [
  // done
  { type: "text", id: "customer.customerNo", label: "TABLE_customerId" },
  { type: "text", id: "customer.name", label: "TABLE_customer_name" },
  { type: "text", id: "customer.phone", label: "TABLE_customer_phone" }
]
const customerDeleteAccountConfig = [
  // done
  { type: "text", id: "customerNo", label: "TABLE_customerId" },
  { type: "text", id: "name", label: "TABLE_customer_name" },
  { type: "text", id: "phone", label: "TABLE_customer_phone" }
]
const customerRatingListConfig = [
  // done
  { type: "text", id: "orderNo", label: "TABLE_orderId" },
  { type: "text", id: "customer.customerNo", label: "TABLE_customerId" },
  { type: "text", id: "customer.name", label: "TABLE_customer_name" },
  { type: "text", id: "customer.phone", label: "TABLE_customer_phone" },
  { type: "text", id: "tmUser.nameZh", label: "TABLE_TM_name" },
  { type: "text", id: "tmUser.staffNo", label: "TABLE_TM_id" },
  { type: "text", id: "tmUser.phone", label: "TABLE_TM_phone" }
]

const companyCustomerListConfig = [
  // done
  { type: "text", id: "customerNo", label: "TABLE_customerId" },
  { type: "text", id: "name", label: "TABLE_customer_name" },
  { type: "text", id: "phone", label: "TABLE_customer_phone" }
]
const companyCustomerPaymentNoticeConfig = [
  // done
  { type: "text", id: "invoiceNo", label: "TABLE_payment_notice_number" },
  { type: "select", id: "state", label: "TABLE_payment_status", multiple: true, list: payStateOptionList },
  { type: "text", id: "customer.customerNo", label: "TABLE_customerId" },
  { type: "text", id: "customer.name", label: "TABLE_customer_name" },
  { type: "text", id: "customer.phone", label: "TABLE_customer_phone" }
]
const companyCustomerChequeListConfig = [
  //done
  { type: "select", id: "state", label: "TABLE_payment_status", multiple: true, list: chequeStateOptionList },
  { type: "text", id: "customer.customerNo", label: "TABLE_customerId" },
  { type: "text", id: "customer.name", label: "TABLE_customer_name" }
]

const tmUserListConfig = [
  //done
  { type: "text", id: "staffNo", label: "TABLE_TM_userId" },
  { type: "text", id: "nameZh", label: "TABLE_TM_user_name" },
  { type: "select", id: "title", label: "TABLE_title", multiple: true, list: TC_titleOptionList },
  { type: "text", id: "phone", label: "TABLE_TM_user_phone" },
  { type: "text", id: "address", label: "TABLE_address" },
  { type: "select", id: "assignedGroup", label: "TABLE_TM_user_team", multiple: true, list: groupOptionList },
  { type: "select", id: "assignedGroup418", label: "TABLE_418", multiple: true, list: group418OptionList },
  { type: "select", id: "approvedJobTypeIds", label: "TABLE_licensed_service", multiple: true }, // call api
  { type: "text", id: "point", label: "TABLE_point" },
  { type: "select", id: "activeJobTypeIds", label: "TABLE_willing_to_serve", multiple: true }, // call api
  { type: "select", id: "gender", label: "TABLE_specify_user_gender", multiple: true, list: userGenderOptionList }
]
const tmReportListConfig = [
  //done
  { type: "text", id: "staffNo", label: "TABLE_TM_userId" },
  { type: "text", id: "nameZh", label: "TABLE_TM_user_name" },
  { type: "text", id: "phone", label: "TABLE_TM_user_phone" }
]
const tmUser418Config = [
  // done
  { type: "text", id: "tmUser.nameZh", label: "TABLE_TM_user_name" },
  { type: "text", id: "tmUser.staffNo", label: "TABLE_TM_userId" },
  { type: "text", id: "createdBy", label: "TABLE_required_sender" },
  { type: "text", id: "tmUser.phone", label: "TABLE_TM_user_phone" }
]
const tmScoreConfig = [
  // done
  { type: "text", id: "tmUser.staffNo", label: "TABLE_TM_userId" },
  { type: "text", id: "tmUser.nameZh", label: "TABLE_TM_user_name" },
  { type: "text", id: "tmUser.phone", label: "TABLE_TM_user_phone" }
]
const TMApplicationConfig = [
  // done
  { type: "text", id: "nameZh", label: "TABLE_applicant_name" },
  { type: "text", id: "phone", label: "TABLE_applicant_phone" },
  { type: "select", id: "applyStatus", label: "TABLE_apply_status", multiple: true, list: TC_applyStatusOptionList } // TODO: applyStatusList
]
const tmMiscChargeConfig = [
  { type: "text", id: "tmMiscChargeNo", label: "TABLE_misc_charge_id" },
  { type: "text", id: "tmUser.staffNo", label: "TABLE_TM_userId" },
  { type: "text", id: "tmUser.nameZh", label: "TABLE_TM_user_name" },
  { type: "text", id: "tmUser.phone", label: "TABLE_TM_user_phone" },
  { type: "select", id: "type", label: "TABLE_type", multiple: true, list: TMMiscChargeTypeOptionList },
  { type: "text", id: "transactionDate.from", label: "TABLE_transaction_date" }
]
const tmSalaryConfig = [
  { type: "text", id: "tmUser.phone", label: "TABLE_TM_user_phone" },
  { type: "text", id: "tmUser.staffNo", label: "TABLE_TM_userId" }
]

const tmDeleteAccountConfig = [
  // done
  { type: "text", id: "staffNo", label: "TABLE_TM_userId" },
  { type: "text", id: "nameZh", label: "TABLE_TM_user_name" },
  { type: "text", id: "phone", label: "TABLE_TM_user_phone" }
]

const eCommerceListConfig = [
  // done
  { type: "text", id: "serviceNo", label: "TABLE_service_no" },
  { type: "select", id: "serviceType", label: "TABLE_service_type", multiple: false, list: servicesTypeOptionList },
  { type: "select", id: "subService", label: "TABLE_service_name", multiple: false }, // call api
  { type: "select", id: "tmJobTypeIds", label: "TABLE_TM_user_type", multiple: true } // call api
]
const eCommerceDiscountConfig = [
  // done
  { type: "text", id: "discountRuleNo", label: "TABLE_discount_code" },
  { type: "text", id: "name", label: "TABLE_discount_name" },
  { type: "select", id: "target", label: "TABLE_discount_person", list: discountTargetOptionList },
  { type: "select", id: "state", label: "TABLE_state", list: stateOptionList },
  { type: "datePicker", id: "notBefore", label: "TABLE_start_time" },
  { type: "datePicker", id: "notAfter", label: "TABLE_end_time" },
  { type: "select", id: "type", label: "TABLE_type", list: discountTypeOptionList },
  { type: "text", id: "quotaLeft", label: "TABLE_remain_available_count" }
]

const messageSMSConfig = [{ type: "text", id: "smsNo", label: "TABLE_SMS_id" }] // done
const messagePushConfig = [{ type: "text", id: "pushNotificationNo", label: "TABLE_push_id" }] // done
const messageNoticeConfig = [{ type: "text", id: "noticeNo", label: "TABLE_notice_id" }] // done

export const setSearchField = (searchType) => {
  switch (searchType) {
    case "admin-list":
      return adminListConfig
    case "order-list":
      return orderListConfig
    case "order-tm-remark":
      return orderTmRemarkConfig
    case "order-payment-list":
      return orderPaymentListConfig
    case "customer-list":
      return customerListConfig
    case "customer-account-balance":
      return customerAccountBalanceConfig
    case "customer-delete-account":
      return customerDeleteAccountConfig
    case "customer-rating-list":
      return customerRatingListConfig
    case "company-customer-list":
      return companyCustomerListConfig
    case "company-customer-payment-notification":
      return companyCustomerPaymentNoticeConfig
    case "company-customer-cheque-list":
      return companyCustomerChequeListConfig
    case "TM-user-list":
      return tmUserListConfig
    case "TM-report-list":
      return tmReportListConfig
    case "TM-user-418":
      return tmUser418Config
    case "TM-score":
      return tmScoreConfig
    case "TM-un-employed":
      return TMApplicationConfig
    case "TM-misc-charge":
      return tmMiscChargeConfig
    case "TM-salary":
      return tmSalaryConfig
    case "TM-delete-account":
      return tmDeleteAccountConfig
    case "e-commerce-list":
      return eCommerceListConfig
    case "e-commerce-discount":
      return eCommerceDiscountConfig
    case "message-release":
      return messageSMSConfig
    case "message-push":
      return messagePushConfig
    case "message-notice":
      return messageNoticeConfig
    default:
      return []
  }
}
