import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { ServicesApi } from "../apis"

export const getServices = createAsyncThunk("services/getServices", async (payload) => {
  return await ServicesApi.getServices(payload)
})

export const getServicesForRoute = createAsyncThunk(
  "services/getServicesForRoute",
  async ({ serviceType }, { dispatch, rejectWithValue }) => {
    let { results } = await dispatch(getServices({ limit: 0, type: serviceType, nameOnly: true })).unwrap()
    let response = { lv1: [], lv2: [], lv3: [] }

    try {
      results = results.map((item) => {
        return {
          label: item.name,
          level: item.level,
          id: item.serviceId
        }
      })
      results.forEach((item) => {
        if (item.level === 1) {
          response.lv1.push(item)
        } else if (item.level === 2) {
          response.lv2.push(item)
        } else {
          response.lv3.push(item)
        }
      })
    } catch (error) {
      return rejectWithValue(error)
    }

    return response
  }
)

export const getServicesForOrder = createAsyncThunk(
  "services/getServicesForOrder",
  async (payload = {}, { dispatch, rejectWithValue }) => {
    let { results } = await dispatch(getServices({ ...payload, limit: 0 })).unwrap()

    try {
      results = results.map((item) => {
        let default_customer_vip = item.pricing.basic.default_customer_vip || []
        let default_tm_vip = item.pricing.basic.default_tm_vip || []
        return {
          label: item.name,
          type: item.type,
          level: item.level,
          id: item.serviceId,
          isRefLetterRequired: item.isRefLetterRequired,
          isVip: default_customer_vip.some((item) => item > 0) || default_tm_vip.some((item) => item > 0),
          details: item.combinedDetails || []
        }
      })
    } catch (error) {
      return rejectWithValue(error)
    }

    return results
  }
)

export const postService = createAsyncThunk("services/postService", async (payload) => {
  return await ServicesApi.postService(payload)
})

export const getServiceById = createAsyncThunk("services/getServiceById", async (payload, { rejectWithValue }) => {
  try {
    return await ServicesApi.getServiceById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const putServiceById = createAsyncThunk("services/putServiceById", async (payload, { rejectWithValue }) => {
  try {
    return await ServicesApi.putServiceById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

const initialState = {}

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = servicesSlice.actions
export default servicesSlice.reducer
