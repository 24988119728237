import { Box, IconButton } from "@mui/material"
import { styled } from "@mui/styles"
import { ImgBase } from "@components/images"
import { SelectBase } from "@components/selects"
import { Row, VSpace, Text } from "@layout"
import { InputWrapper, InputText, InputDatePicker } from "@components/inputs"
import { useTheme } from "@mui/styles"
import { t } from "i18next"
import { partitionList } from "@constants"

export const ModalContent = styled(Box)(({ theme, maxwidth, expand }) => ({
  position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  paddingTop: "15px",
  paddingBottom: "80px",
  width: "100%",
  height: expand && "calc(100vh - 100px)",
  maxWidth: maxwidth || "unset",
  flexGrow: 0,
  borderRadius: "3px",
  boxShadow: "6px 12px 30px 0 rgba(239, 195, 101, 0.24)",
  border: "solid 1px",
  borderColor: theme.border.yellow,
  backgroundColor: theme.palette.background.default,
  boxSizing: "border-box"
}))

export const ModalButtonWrapper = styled("div")(({ theme, height, padding, color = "gray", justifycontent }) => ({
  position: "absolute",
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: justifycontent || "flex-end",
  gap: "33px",
  width: "100%",
  height: height || "auto",
  padding: padding || "20px",
  backgroundColor: theme.palette.background[`${color}_opacity`]
}))

export const Close = (props) => {
  return (
    <IconButton sx={{ position: "absolute", top: "-40px", right: "-40px" }} {...props}>
      <ImgBase width="25px" src="nav-icon" alt="modal close" />
    </IconButton>
  )
}

export const HFieldContainer = ({
  state,
  type,
  id,
  label,
  handleChange,
  onKeyPress,
  multiple = false,
  list = [],
  ...rest
}) => {
  const component = () => {
    switch (type) {
      case "select":
        return (
          <SelectBase
            size="14px"
            weight="300"
            value={state[id]}
            list={list}
            multiple={multiple}
            onSelect={(event, selectItem) => handleChange(id, type, event.value, selectItem)}
            {...rest}
          />
        )
      case "select-partition":
        return (
          <SelectBase
            size="14px"
            weight="300"
            value={state[id]}
            list={partitionList(state.district)}
            onSelect={(event, selectItem) => handleChange(id, "select", event.value, selectItem)}
            {...rest}
          />
        )
      case "text":
        return (
          <InputText
            value={state[id]}
            onChange={(event) => handleChange(id, type, event.target.value)}
            onKeyPress={onKeyPress}
            {...rest}
          />
        )
      case "datePicker":
        return (
          <InputDatePicker
            id={id}
            value={state[id]}
            handleChange={(value) => handleChange(id, type, value)}
            {...rest}
          />
        )
    }
  }

  return (
    <Row sx={{ justifyContent: "space-between" }}>
      <Text size="14px" weight="500">
        {t(label)}
      </Text>
      <Box sx={{ width: "130px", minWidth: "130px" }}>{component()}</Box>
    </Row>
  )
}
