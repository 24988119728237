import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeFieldWithInvalid } from "@utils/makeField"
import { invalidateEmail, isInvalidPhone } from "@utils/validator"
import { forEach, every } from "lodash"
import { isEmpty } from "@utils/validator"

const makeFieldsBase = (payload) => {
  return {
    // state: makeFieldWithInvalid(payload.state, "state", fieldStatus.VALID, false, false),
    name: makeFieldWithInvalid(payload.name, "name", fieldStatus.VALID, true, false),
    title: makeFieldWithInvalid(payload.title, "title", fieldStatus.VALID, true, false),
    type: makeFieldWithInvalid(payload.type, "type", fieldStatus.VALID, true, false),
    phone: makeFieldWithInvalid(payload.phone, "phone", fieldStatus.VALID, true, false),
    email: makeFieldWithInvalid(payload.email, "email", fieldStatus.VALID, true, false),
    eclNo: makeFieldWithInvalid(payload.eclNo, "eclNo", fieldStatus.VALID, false, false),
    createdAt: makeFieldWithInvalid(payload.createdAt, "createdAt", fieldStatus.VALID, false, false),
    lastAppOpenedAt: makeFieldWithInvalid(payload.lastAppOpenedAt, "lastAppOpenedAt", fieldStatus.VALID, false, false)
  }
}

export const initialState = makeFieldsBase({
  // state: "",
  name: "",
  title: "",
  type: "",
  phone: "",
  email: "",
  eclNo: ""
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState,
  reducers: {
    defaultSetting(state, { payload }) {
      return payload ? makeFieldsBase(payload) : initialState
    },
    fieldChange(state, { payload }) {
      let { id, value } = payload
      state[id].value = value
      state[id].fieldStatus = fieldStatus.VALID
    },
    validator(state, { payload }) {
      forEach(state, (item, key) => {
        if (item.isRequired) {
          if (item.fieldPath === "email" && invalidateEmail(item.value)) {
            state[item.fieldPath].fieldStatus = fieldStatus.ERROR
          } else if (item.fieldPath === "phone" && isInvalidPhone(item.value)) {
            state[item.fieldPath].fieldStatus = fieldStatus.EMPTY
          } else if (isEmpty(item.value)) {
            state[item.fieldPath].fieldStatus = fieldStatus.EMPTY
          }
          state[item.fieldPath].invalid = item.fieldStatus !== fieldStatus.VALID
        }
      })
    }
  }
})

export const validateCheck = (state) => {
  return every(state, (item) => {
    if (
      item.isRequired &&
      ((item.fieldPath === "email" && invalidateEmail(item.value)) ||
        (item.fieldPath === "phone" && isInvalidPhone(item.value)) ||
        isEmpty(item.value))
    ) {
      return false
    } else {
      return true
    }
  })
}
export const convertToPayload = (state) => {
  let payload = {}
  forEach(state, ({ value }, key) => {
    payload[key] = value
  })
  return payload
}

export const { fieldChange, validator, defaultSetting } = mainReducer.actions
export default mainReducer.reducer
