import { t } from "i18next"
import { convertIdToLabel } from "@utils/formatter"
import { payStateOptionList, payStateOptionListDoc, paymentStatusOptionList } from "@constants"

const createModalInvoiceColumns = () => {
  return [
    {
      fieldType: "text",
      fieldPath: "paymentStatus",
      fieldProps: {
        format: (value) => (value ? convertIdToLabel(value, paymentStatusOptionList) : "-")
      },
      label: t("TABLE_payment_state"),
      minWidth: 198
    },
    {
      fieldType: "idLink",
      fieldPath: "",
      fieldProps: {
        domain: "/order/list",
        path: ({ id }) => id,
        format: ({ orderNo }) => orderNo || ""
      },
      label: t("TABLE_orderId"),
      passWholeRowData: true,
      minWidth: 154
    },
    {
      fieldType: "date",
      fieldPath: "date",
      fieldProps: {
        format: (value) => value || "-"
      },
      label: t("TABLE_service_date"),
      minWidth: 134
    },
    {
      fieldType: "date",
      fieldPath: "time",
      fieldProps: {
        format: (value) => value || "-"
      },
      label: t("TABLE_service_time"),
      minWidth: 134
    },
    {
      fieldType: "text",
      fieldPath: "amount",
      fieldProps: {
        format: (value) => (value ? `$${Number(value) / 100}` : `$0`)
      },
      label: t("TABLE_service_money"),
      minWidth: 120
    },
    {
      fieldType: "text",
      fieldPath: "shouldPayAmount",
      fieldProps: {
        format: (value) => `$${Number(value) / 100}`
      },
      label: t("TABLE_should_pay_money"),
      minWidth: 190
    }
  ]
}

export default createModalInvoiceColumns
