import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeField } from "@utils/makeField"

export const makeCertFieldsBase = (payload) => {
  return {
    isSaveChecking: false,
    name: makeField(payload.name, "name", fieldStatus.EMPTY, true),
    url: makeField(payload.url, "url", fieldStatus.EMPTY, true)
  }
}

export const certInitialState = makeCertFieldsBase({
  name: "",
  url: ""
})

const certReducer = createSlice({
  name: "certReducer",
  initialState: certInitialState,
  reducers: {
    newCertResetAll() {
      return certInitialState
    },
    newCertFieldChange(state, { payload }) {
      let { id, data } = payload

      state[id].value = data
      state[id].fieldStatus = state[id].isRequired && data === "" ? fieldStatus.EMPTY : fieldStatus.VALID
    },
    newCertIsSaveChecking(state, { payload }) {
      state.isSaveChecking = payload
    }
  }
})

export const { newCertResetAll, newCertFieldChange, newCertIsSaveChecking } = certReducer.actions
export default certReducer.reducer
