import { useEffect, useState, useReducer } from "react"
import { useDispatch } from "react-redux"

import { VFieldContainer } from "@components/common"
import { ModalOverlay, ModalMessage } from "@components/modals"
import BasicTabs from "@components/tabs"
import { Column } from "@layout"

import { postCustomer, putCustomerById } from "@slices/customers"
import { titleOptionList, typeOptionList, typeOptionDoc, customerStateOptionList } from "@constants"
import mainReducer, {
  initialState,
  fieldChange,
  validator,
  defaultSetting,
  validateCheck,
  convertToPayload
} from "./reducers"
import { formatDateWithTime, convertIdToLabel } from "@utils/formatter"
import { t } from "i18next"
import { isEmpty } from "@utils/validator"

const ModalCustomerProfile = ({ profile, update, isCustomerPage, ...props }) => {
  const dispatch = useDispatch()

  const [state, reducerDispatch] = useReducer(mainReducer, initialState)

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      reducerDispatch(defaultSetting(profile))
      reducerDispatch(
        fieldChange({ id: "type", value: isCustomerPage ? typeOptionDoc.individual.id : typeOptionDoc.organization.id })
      )
    }
  }, [props.open])

  let fieldsList = [
    // { id: "state", label: t("MODAL_CustimerProfile_account_type"), type: "select", list: customerStateOptionList },
    { id: "name", label: t("MODAL_CustimerProfile_name"), type: "input" },
    { id: "title", label: t("MODAL_CustimerProfile_title"), type: "select", list: titleOptionList },
    {
      id: "type",
      label: t("MODAL_CustimerProfile_type"),
      type: Boolean(profile) ? "text" : "select",
      format: convertIdToLabel,
      list: typeOptionList
    },
    { id: "phone", label: t("MODAL_CustimerProfile_phone"), type: "phone" },
    { id: "email", label: t("MODAL_CustimerProfile_email"), type: "input" },
    { id: "eclNo", label: t("MODAL_CustimerProfile_eclNo"), type: "input" },
    { id: "createdAt", label: t("MODAL_CustimerProfile_createdAt"), type: "date", display: Boolean(profile) },
    {
      id: "lastAppOpenedAt",
      label: t("MODAL_CustimerProfile_lastAppOpenedAt"),
      type: "text",
      format: formatDateWithTime,
      display: Boolean(profile)
    }
  ]

  const handleChange = (id, type, value) => {
    // console.log("handleChange : ", { id, type, value })
    reducerDispatch(fieldChange({ id, value }))
  }

  const handleSave = () => {
    console.log("handleSave : ", { state })
    if (validateCheck(state)) {
      dispatchProfile()
    } else {
      reducerDispatch(validator())
    }
  }
  const dispatchProfile = async () => {
    try {
      let response
      if (profile) {
        const payload = {
          customerId: profile.customerId,
          customerInfo: convertToPayload(state)
        }
        response = await dispatch(putCustomerById(payload)).unwrap()
      } else {
        const payload = convertToPayload(state)
        response = await dispatch(postCustomer(payload)).unwrap()
      }
      update(response)
      props.onClose(response)
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const buttonList = [{ label: t("TEXT_COMMON_save"), srcx: "save-black", onClick: handleSave }]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <BasicTabs list={[{ label: t("TAB_basic_info") }]}>
          <Column>
            {state &&
              fieldsList.map((config, index) => (
                <VFieldContainer
                  key={`modal field ${config.id}`}
                  index={index}
                  width="200px"
                  value={state[config.id]?.value}
                  error={state[config.id]?.invalid}
                  handleChange={handleChange}
                  required={state[config.id]?.isRequired}
                  {...config}
                />
              ))}
          </Column>
        </BasicTabs>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalCustomerProfile
