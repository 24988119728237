import { useState, useReducer } from "react"
// Components
import Box from "@mui/material/Box"
import { Row, VSpace, HSpace, Text, FieldLabel } from "@layout"
import { BackButton } from "./add-new-certificate.styles"
import { InputText } from "@components/inputs"
import File from "@components/file"
// Styles
// Libs
import { t } from "i18next"
import { fieldStatus } from "@constants"
import { newCertResetAll, newCertFieldChange } from "./reducers/add-new-certificate"

const AddNewCertificate = ({ state, reducerDispatch, handleBackClick }) => {

  const handleFileChange = (id, type, data) => {
    if (type === "file" && data) {
      reducerDispatch(newCertFieldChange({ id, data: data.url }))
    } else if (type === "removeFile") {
      reducerDispatch(newCertFieldChange({ id, data: data }))
    }
  }

  return (
    <Box sx={{ padding: "10px" }}>
      <BackButton onClick={handleBackClick} src="back" alt="back" />
      <VSpace length={20} />
      <Box>
        <Row>
          <FieldLabel width="96px" weight="500" required={true}>
            {t("MODAL_TMProfile_Field_certificate_name")}
          </FieldLabel>
          <HSpace length={10} />
          <InputText
            value={state.name.value}
            sx={{ width: "237px" }}
            onChange={(event) => reducerDispatch(newCertFieldChange({ id: "name", data: event.target.value }))}
            error={state.isSaveChecking && state.name.fieldStatus === fieldStatus.EMPTY}
          />
        </Row>
        <VSpace length={27} />
        <Row>
          <HSpace length={75} />
          <File
            id="url"
            fileName={state.name.value}
            fileURL={state.url.value}
            handleAddFile={handleFileChange}
            handleRemoveFile={handleFileChange}
            error={state.isSaveChecking && state.url.fieldStatus === fieldStatus.EMPTY}
          />
        </Row>
      </Box>
    </Box>
  )
}

export default AddNewCertificate
