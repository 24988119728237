import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { InvoicesApi } from "../apis"
import { setModalSettingsState, modalSettingsObj } from "@slices/modal-settings"

export const getInvoices = createAsyncThunk("invoices/getInvoices", async (payload) => {
  return await InvoicesApi.getInvoices(payload)
})

export const postInvoices = createAsyncThunk(
  "invoices/postInvoices",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "invoices/postInvoices" }))
      return await InvoicesApi.postInvoices(payload)
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setModalSettingsState({ type: modalSettingsObj.isLoadingOpen, value: "invoices/postInvoices" }))
    }
  }
)

export const getInvoicesById = createAsyncThunk("invoices/getInvoicesById", async (payload, { rejectWithValue }) => {
  try {
    return await InvoicesApi.getInvoicesById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const putInvoicesById = createAsyncThunk("invoices/putInvoicesById", async (payload, { rejectWithValue }) => {
  try {
    return await InvoicesApi.putInvoicesById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const postInvoicePdfById = createAsyncThunk(
  "invoices/postInvoicePdfById",
  async (payload, { rejectWithValue }) => {
    try {
      return await InvoicesApi.postInvoicePdfById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {}

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = invoicesSlice.actions
export default invoicesSlice.reducer
