import { styled } from "@mui/styles"
import TableCell from "@mui/material/TableCell"

const TableCellBody = styled(TableCell)(({ theme, padding, height, width, verticalalign }) => ({
  border: `0.5px solid ${theme.border.gray_3} `,
  padding: `${padding || "6px"} !important`,
  wordBreak: "break-word",
  verticalAlign: verticalalign || "top",
  boxSizing: "border-box",
  height: height || "unset",
  width: width || "unset"
}))

export default TableCellBody
