import { useState } from "react"
import { useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import { HSpace, Row, VSpace, Text, Column } from "@layout"
import { InputWrapper, InputTextArea, InputText, InputFile } from "@components/inputs"
import { UploadWrapper } from "./index.styles"
import { ImgBase, ImgExternal } from "@components/images"
// Styles
// Libs
import { t } from "i18next"
import { useTheme } from "@mui/styles"
import { getFileExtension } from "@utils/formatter"
import { fileFilter, maxAllowedFileSize, allowedFileExt } from "@constants/file"
import { postResources } from "@slices/resources"

const File = ({ id, error, fileURL, fileName, handleAddFile, handleRemoveFile }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const elementId = `inputFile_${id}`

  const [fileError, setFileError] = useState(false)
  const [uploading, setUploading] = useState(false)

  const handleUploadFile = async (file) => {
    setUploading(true)
    let result = await dispatch(postResources(file)).unwrap()
    // let result = {
    //   url: "https://ehs-dev.redso.com.hk/resources/eb29cdb8-939d-4592-bc73-c1e5f3e5e3df.png"
    // }
    if (result?.url) {
      handleAddFile &&
        handleAddFile(id, "file", {
          name: file.name || "",
          url: result.url
        })
    }
    setUploading(false)
  }

  return (
    <>
      <Box sx={{ width: "296px" }}>
        {uploading ? (
          <Text weight="500">{t("TEXT_COMMON_uploading")}</Text>
        ) : (
          <>
            {fileURL ? (
              <Row sx={{ justifyContent: "start", alignItems: "start !important" }}>
                <ImgExternal src={fileURL} alt={fileName || id} width="200px" />
                {handleRemoveFile ? (
                  <>
                    <HSpace length={10} />
                    <ImgBase
                      src="codicon-error"
                      alt="delete file icon"
                      width="32px"
                      height="32px"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleRemoveFile && handleRemoveFile(id, "removeFile", "")
                      }}
                    />
                  </>
                ) : null}
              </Row>
            ) : (
              <UploadWrapper
                error={Number(error)}
                onClick={(e) => {
                  e.preventDefault()
                  setFileError(false)
                  document.getElementById(elementId).value = null
                  document.getElementById(elementId).click()
                }}
              >
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ImgBase src="icon-plus" alt="upload file icon" width="56px" height="56px" />
                  </Box>
                  <VSpace length={17} />
                  <Text size="18px" weight="700" color="yellow">
                    {t("TEXT_COMMON_select_file")}
                  </Text>
                </Box>
              </UploadWrapper>
            )}
            {fileError || error ? (
              <Text weight="500" color="red">
                {t("ERROR_COMMON_file_limit")}
              </Text>
            ) : null}
          </>
        )}
      </Box>

      <InputFile
        id={elementId}
        type={"file"}
        accept={fileFilter}
        onChange={(event) => {
          event.stopPropagation()
          event.preventDefault()

          let file = event.target?.files[0] || event.blob
          let ext = file && getFileExtension(file.name)

          if (file?.size <= maxAllowedFileSize && ext && allowedFileExt.includes(ext)) {
            handleUploadFile(file)
          } else {
            setFileError(true)
          }
        }}
      />
    </>
  )
}

export default File
