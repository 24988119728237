import { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import ModalBase from "./modal-base"
import { InputFile } from "@components/inputs"
import { ButtonWithImage } from "@components/buttons"
import { ImgBase } from "@components/images"
import { Text, Column, Divider, Row, HSpace, VSpace } from "@layout"
import { UploadWrapper } from "@components/file/index.styles"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { ModalReviewImport, ModalMessage } from "@components/modals"
// Libs
import { t } from "i18next"
import { isEmpty } from "@utils/validator"
import { setStatusBarData } from "@slices/status-bar"
import { importTemplateUrl, importFileFilter } from "@constants"

const ModalImport = ({ open, onSave, onClose, templateKey, dispatchPostImport }) => {
  const dispatch = useDispatch()

  const refFile = useRef(null)
  const [fileName, setFileName] = useState(null)
  const [reviewData, setReviewData] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [reviewImportOpen, setReviewImportOpen] = useState(false)

  const elementId = `inputFile_import`

  useEffect(() => {
    if (open) initialFile()
  }, [open])

  const initialFile = () => {
    refFile.current = null
    setFileName(null)
  }

  const handleSave = async ({ preview }) => {
    if (refFile.current) {
      if (dispatchPostImport) {
        try {
          let { results } = await dispatchPostImport({ file: refFile.current, params: { preview } })
          if (preview) {
            setReviewData(results)
            setReviewImportOpen(true)
          } else {
            dispatch(setStatusBarData({ open: true, reviewData: results }))
            onSave && onSave()
            onClose && onClose()
          }
        } catch (error) {
          setErrorMessage(error)
        }
      } else {
        onSave && onSave(refFile.current[0])
        onClose && onClose()
      }
    }
  }

  const handleCloseClick = () => {
    onClose && onClose()
  }

  const handleSelectFile = async (event) => {
    if (event.target?.files) {
      refFile.current = event.target.files
      setFileName(event.target.files[0].name)
    }
  }

  const handleCancelFile = () => {
    refFile.current = null
    setFileName(null)
  }

  const modalReviewImportConfig = {
    onClose: () => {
      setReviewImportOpen(false)
      onClose && onClose()
    },
    onSave: () => {
      setReviewImportOpen(false)
      handleSave({ preview: false })
    },
    open: reviewImportOpen,
    readOnly: false,
    data: reviewData
  }

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage(null)
  }

  return (
    <>
      <ModalBase open={open} onClose={onClose}>
        <ModalContent maxwidth="534px">
          <Close onClick={handleCloseClick} />

          <Column sx={{ padding: "1px 29px 5px" }}>
            <Text weight="500">{t("MODAL_Import_File_title")}</Text>
          </Column>
          <Divider color="yellow" />
          <Row sx={{ width: "100%", padding: "30px 29px 0px" }}>
            <Text width="96px" weight="500">
              {t("MODAL_Import_File_sample")}
            </Text>
            <HSpace length={18} />
            {templateKey ? (
              <a href={importTemplateUrl[templateKey]?.url}>
                <Text>{t(importTemplateUrl[templateKey]?.text)}</Text>
              </a>
            ) : null}
          </Row>
          <Row
            sx={{
              width: "100%",
              padding: "20px 29px",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            {fileName ? (
              <Row sx={{ width: "100%" }}>
                <Text width="100%" wordbreak="break-word">
                  {fileName}
                </Text>
                <ImgBase
                  src="no"
                  alt="cancel import file"
                  width="18px"
                  height="18px"
                  cursor="pointer"
                  onClick={() => handleCancelFile()}
                />
              </Row>
            ) : (
              <UploadWrapper
                onClick={(e) => {
                  e.preventDefault()
                  document.getElementById(elementId).value = null
                  document.getElementById(elementId).click()
                }}
              >
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ImgBase src="icon-plus" alt="upload file icon" width="56px" height="56px" />
                  </Box>
                  <VSpace length={17} />
                  <Text size="18px" weight="700" color="yellow">
                    {t("TEXT_COMMON_select_file")}
                  </Text>
                </Box>
              </UploadWrapper>
            )}
          </Row>
          <ModalButtonWrapper color="yellow">
            <ButtonWithImage
              sx={{ width: "115px" }}
              textcolor="brown_1"
              src="save-black"
              label={t("TEXT_COMMON_save")}
              onClick={() => handleSave({ preview: true })}
            />
          </ModalButtonWrapper>

          <InputFile
            id={elementId}
            type={"file"}
            accept={importFileFilter}
            onChange={(event) => {
              event.stopPropagation()
              event.preventDefault()

              handleSelectFile(event)
            }}
          />
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
      <ModalReviewImport {...modalReviewImportConfig} />
    </>
  )
}

export default ModalImport
