// Components
import Box from "@mui/material/Box"
import { Text } from "@layout"
import { VFieldContainer } from "@components/common"
// Styles
// Libs
import { t } from "i18next"
import { districtList, partitionList, fieldStatus } from "@constants"

const AddressInfo = ({ state, handleInputChange, handleSelectChange }) => {
  return (
    <Box>
      <VFieldContainer
        index={0}
        width="212px"
        id="addressRegion"
        value={state.addressRegion.value}
        label={t("TEXT_COMMON_district")}
        type="select"
        list={districtList}
        handleChange={(id, type, data) => {
          handleSelectChange(id, type, data)
          handleSelectChange("addressDistrict", "select", "")
        }}
      />
      <VFieldContainer
        index={1}
        width="212px"
        id="addressDistrict"
        value={state.addressDistrict.value}
        label={t("TEXT_COMMON_partition")}
        type="select"
        list={partitionList(state.addressRegion.value)}
        handleChange={handleSelectChange}
      />
      <VFieldContainer
        index={2}
        width="212px"
        id="addressEstate"
        value={state.addressEstate.value}
        label={t("TEXT_COMMON_estate")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={3}
        width="212px"
        id="addressStreet"
        value={state.addressStreet.value}
        label={t("TEXT_COMMON_street")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={4}
        width="212px"
        id="addressBuilding"
        value={state.addressBuilding.value}
        label={t("TEXT_COMMON_building")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={5}
        width="212px"
        id="addressBlock"
        value={state.addressBlock.value}
        label={t("TEXT_COMMON_block")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={6}
        width="212px"
        id="addressFloor"
        value={state.addressFloor.value}
        label={t("TEXT_COMMON_floor")}
        type="input"
        handleChange={handleInputChange}
      />
      <VFieldContainer
        index={7}
        width="212px"
        id="addressUnit"
        value={state.addressUnit.value}
        label={t("TEXT_COMMON_unit")}
        type="input"
        handleChange={handleInputChange}
      />
    </Box>
  )
}

export default AddressInfo
