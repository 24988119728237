import { ImgBase } from "@components/images"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/styles"

const TableCellCheckBox = ({ type, selected, ...rest }) => {
  const theme = useTheme()

  let boxStyle = {
    top: "0px",
    cursor: "pointer",
    display: "table-cell",
    padding: "15px 8px 11px",
    border: `0.5px solid ${theme.border.gray_3}`
  }
  if (type === "header") {
    boxStyle = {
      ...boxStyle,
      zIndex: 2,
      position: "sticky",
      verticalAlign: "top",
      backgroundColor: `${theme.palette.background.gray_2}`
    }
  }

  return (
    <Box component="th" sx={{ ...boxStyle }} {...rest}>
      <ImgBase src={selected ? "black-check" : "white-content-check"} width="15px" />
    </Box>
  )
}

export default TableCellCheckBox
