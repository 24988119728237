import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ModalOverlay from "./modal-overlay"
import { ModalMessage } from "@components/modals"
import { Column, VSpace, Row, Text } from "@layout"
import { InputSearch, InputWrapper, InputText } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"

import { t } from "i18next"
import { postUsableDiscounts, postUsableDiscountByCode } from "@slices/discounts"
import { isEmpty } from "@utils/validator"

const ModalDiscountListing = ({ onConfirm, orderState, ...props }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState()
  const [discountCode, setDiscountCode] = useState("")
  const [list, setList] = useState([])
  const [search, setSearch] = useState("")

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      dispatcGet()
      setState()
      setSearch("")
      setDiscountCode("")
    }
  }, [props.open])

  const dispatcGet = async (keyword) => {
    const { results } = await dispatch(postUsableDiscounts({ ...orderState, keyword })).unwrap()
    setList(results)
  }

  const handleChange = (value) => {
    if (value.discountRuleId === state?.discountRuleId) {
      setState("")
    } else {
      setState(value)
    }
  }

  const handConfirmClick = async () => {
    // console.log("handConfirmClick", { discountCode, state })
    if ((discountCode && state) || (!discountCode && !state)) {
      setErrorMessage({ message: t("ERROR_COMMON_please_select_discount") })
    } else if (discountCode) {
      let payload = { discountCode, discountInfo: orderState }
      await dispatch(postUsableDiscountByCode(payload))
        .unwrap()
        .then((res) => {
          onConfirm && onConfirm(res)
        })
        .catch(({ data }) => {
          setErrorMessage(data)
        })
    } else if (state) {
      onConfirm && onConfirm(state)
    }
  }

  const buttonList = [{ label: t("TEXT_COMMON_confirm"), srcx: "save-black", onClick: handConfirmClick }]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }
  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <Column sx={{ padding: "10px 22px", height: "-webkit-fill-available" }}>
          <Text weight={500}>{t("MODAL_Discount_Listing_title")}</Text>
          <VSpace length="20" />
          <InputWrapper label={t("MODAL_Discount_Listing_discount_code")} size="14px" weight="500">
            <InputText value={discountCode} onChange={(event) => setDiscountCode(event.target.value)} />
          </InputWrapper>
          <VSpace length="30" />
          <Text weight={500}>{t("MODAL_Discount_Listing_discount")}</Text>
          <VSpace length="10" />
          <InputSearch width="60%" value={search} handleChange={setSearch} handleSearch={() => dispatcGet(search)} />
          <VSpace length="15" />
          <Row>
            <Text width="200px" weight="500">
              {t("MODAL_Discount_Listing_discount_name")}
            </Text>
            <Text width="200px" weight="500">
              {t("MODAL_Discount_Listing_discount")}
            </Text>
            <Text width="200px" weight="500">
              {t("MODAL_Discount_Listing_discount_period")}
            </Text>
          </Row>
          <Column sx={{ overflowY: "scroll" }}>
            {list.map((item) => (
              <Row key={`modal search listing ${item.discountRuleId}`} sx={{ margin: "10px 0" }}>
                <Row sx={{ width: "200px" }}>
                  <CheckboxBase
                    round={false}
                    label={`${item.name} ${item.phone ? `#${item.phone}` : ""}`}
                    onChange={() => handleChange(item)}
                    checked={state?.discountRuleId === item.discountRuleId}
                  />
                </Row>
                <Text width="200px">{item.description}</Text>
                <Text width="200px">{item.notAfter}</Text>
              </Row>
            ))}
          </Column>
        </Column>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalDiscountListing
