import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
// Components
import { TableList, Pagination } from "./index"
import { Column, VSpace } from "@layout"
import { ModalDisplaySetting } from "@components/modals"
// Libs
import { orderByOptionList } from "@constants"
import { SearchToolParams } from "@selector"
import { resetSearchTool, setSearchToolData } from "@slices/search-tool"
import { isEmpty, cloneDeep, forEach } from "lodash"
import { getQuerySearchParams } from "@utils/hyperlink"
import { formatDateOnly } from "@utils/formatter"

const initialPage = { currentPage: 1, rowsPerPage: 50, numResults: 0, sort: null }

const TableListWithPagination = ({
  rows,
  config,
  fuctions = {},
  dispatchGet,
  displayList,
  children,
  update = 0,
  isDetailPage = false
}) => {
  const dispatch = useDispatch()

  const [displaySettingOpen, setDisplaySettingOpen] = useState(false)
  const [displaySettingState, setDisplaySettingState] = useState(displayList)

  const searchToolParams = useSelector(SearchToolParams)
  const searchToolPayload = convertSearchToolParamsToPayload(searchToolParams)

  const [pages, setPages] = useState(initialPage)
  const [asc, setAsc] = useState(false)

  useEffect(() => {
    let querySearchParams = getQuerySearchParams()
    if (querySearchParams) {
      dispatch(setSearchToolData(querySearchParams))
      dispatchGet({ offset: pages.currentPage, limit: pages.rowsPerPage, ...querySearchParams }).then((res) =>
        setPages({ ...pages, numResults: res })
      )
    } else {
      dispatchGet({ offset: pages.currentPage, limit: pages.rowsPerPage }).then((res) =>
        setPages({ ...pages, numResults: res })
      )
    }
  }, [])

  useEffect(() => {
    if (update) {
      dispatchGet(
        {
          offset: pages.currentPage,
          limit: pages.rowsPerPage,
          ...getSortOrder(pages.sort, asc),
          ...searchToolPayload
        },
        "update"
      ).then((res) => setPages({ ...pages, numResults: res }))
    }
  }, [update])

  const handlePageChange = (event, currentPage) => {
    dispatchGet({
      offset: currentPage,
      limit: pages.rowsPerPage,
      ...getSortOrder(pages.sort, asc),
      ...searchToolPayload
    }).then((res) => setPages({ ...pages, currentPage, numResults: res }))
  }

  const handleRowsChange = (limit) => {
    dispatchGet({ offset: 1, limit, ...getSortOrder(pages.sort, asc), ...searchToolPayload }).then((res) =>
      setPages({ currentPage: 1, rowsPerPage: limit, numResults: res })
    )
  }

  const handleResetClick = (type) => {
    switch (type) {
      case "ALL":
        dispatch(resetSearchTool())
        dispatchGet({ offset: 1, limit: 50 }, "RESET_ALL").then((res) => setPages({ ...initialPage, numResults: res }))
        break
      case "SORT":
        dispatchGet({
          offset: pages.currentPage,
          limit: pages.rowsPerPage,
          ...searchToolPayload
        }).then((res) => setPages({ ...pages, sort: null, numResults: res }))
        break
      case "FILTER":
        dispatch(resetSearchTool())
        dispatchGet({
          offset: 1,
          limit: pages.rowsPerPage,
          ...getSortOrder(pages.sort, asc)
        }).then((res) => setPages({ ...pages, currentPage: 1, numResults: res }))
        break
    }
  }

  const modalDisplaySettingConfig = {
    onClose: () => setDisplaySettingOpen(false),
    onConfirm: (data) => setDisplaySettingState(data),
    open: displaySettingOpen,
    itemList: displayList
  }

  const handleSortChange = async (sort) => {
    let currentAsc = !asc
    if (sort !== pages.sort && pages.sort !== null) {
      currentAsc = false
    }
    setAsc(currentAsc)

    dispatchGet({
      offset: pages.currentPage,
      limit: pages.rowsPerPage,
      sort,
      order: orderByOptionList[Number(currentAsc)],
      ...searchToolPayload
    }).then((res) => setPages({ ...pages, sort, numResults: res }))
  }

  const columns = config({ canShowList: displaySettingState, handleSortChange, ...fuctions })

  return (
    <Column sx={{ padding: "0 16px", maxHeight: "100%" }}>
      <Pagination
        key={`pagination ${pages.currentPage}`}
        handleResetClick={handleResetClick}
        handleRowsChange={handleRowsChange}
        handlePageChange={handlePageChange}
        handleShowSetting={() => setDisplaySettingOpen(true)}
        isDetailPage={isDetailPage}
        {...pages}
      />
      <VSpace length={15} />
      {children && children}
      <TableList rows={rows} columns={columns} currentPage={pages.currentPage} rowsPerPage={pages.rowsPerPage} />

      <ModalDisplaySetting {...modalDisplaySettingConfig} />
    </Column>
  )
}

const getSortOrder = (sort, asc) => {
  if (sort) {
    return { sort, order: orderByOptionList[Number(asc)] }
  }
  return {}
}

export default TableListWithPagination

const convertSearchToolParamsToPayload = (state) => {
  let cloneState = cloneDeep(state)
  let result = {}

  forEach(cloneState, (data, key) => {
    if (!isEmpty(data)) {
      switch (key) {
        case "serviceType":
          result["type"] = data
          break
        case "subService":
          result["name"] = data
          break
        case "partition":
        case "district":
          result[`userAddress.${key}`] = data
          break
        case "date":
        case "notBefore":
        case "notAfter":
          result[key] = formatDateOnly(data)
          break
        case "defaultNotIncludeField":
          if (Object.keys(data).length) {
            result = { ...result, ...data }
          }
          break
        default:
          result[key] = data
          break
      }
    }
  })

  return result
}
