import { useEffect, useState, useReducer } from "react"
import { useDispatch } from "react-redux"

import ModalOverlay from "../modal-overlay"
import ModalMessage from "../modal-message"
import { Column, VSpace, Row } from "@layout"
import { InputSearch } from "@components/inputs"
import { CheckboxBase } from "@components/checkbox"
import BasicTabs from "@components/tabs"
import { VFieldContainer } from "@components/common"

import { t } from "i18next"
import { isEmpty } from "@utils/validator"
import { getTMUsers } from "@slices/TM-users"
import { postTMMiscCharges } from "@slices/TM-misc-charges"
import { TMMiscChargeTypeOptionList } from "@constants"
import mainReducer, {
  initialState,
  fieldChange,
  validator,
  defaultSetting,
  validateCheck,
  convertToPayload
} from "./reducers"

const ModalTMMiscCharge = ({ onSave, ...props }) => {
  const dispatch = useDispatch()

  const [list, setList] = useState([])
  const [state, reducerDispatch] = useReducer(mainReducer, initialState)
  const [search, setSearch] = useState("")

  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (props.open) {
      reducerDispatch(defaultSetting())
      setSearch("")
      setList([])
    }
  }, [props.open])

  const dispatchGetTMUsers = async (keyword) => {
    let { results } = await dispatch(getTMUsers({ keyword, limit: 100 })).unwrap()
    setList(results)
  }

  const handleChange = (id, type, value) => {
    switch (id) {
      case "tmUserId":
        value = value === state.tmUserId.value ? "" : value
        break
      default:
        break
    }
    // console.log({ id, type, value })
    reducerDispatch(fieldChange({ id, value }))
  }

  const handleSave = async () => {
    if (isEmpty(state.tmUserId.value)) {
      setErrorMessage({ message: t("ERROR_COMMON_please_select_tm") })
      return
    }
    if (validateCheck(state)) {
      reducerDispatch(validator())
      let payload = convertToPayload(state)
      dispatch(postTMMiscCharges(payload))
        .unwrap()
        .then(() => onSave())
    } else {
      reducerDispatch(validator())
    }
  }

  const fieldsList = [
    {
      id: "amount",
      label: t("TABLE_amount"),
      type: "input",
      inputType: "number",
      regex: (value) => /^(\-)?\d+(\.\d{1})?$/.test(value)
    },
    {
      id: "transactionDate",
      label: t("TABLE_transaction_date"),
      type: "datePicker"
    },
    {
      id: "type",
      label: t("TABLE_type"),
      type: "select",
      list: TMMiscChargeTypeOptionList
    },
    {
      id: "remarks",
      label: t("TABLE_remark"),
      type: "input"
    }
  ]

  const modalMessageConfig = {
    errorMessage,
    open: !isEmpty(errorMessage),
    onClose: () => setErrorMessage()
  }

  const buttonList = [{ label: t("TEXT_COMMON_confirm"), srcx: "save-black", onClick: handleSave }]

  return (
    <>
      <ModalOverlay {...props} buttonList={buttonList}>
        <BasicTabs list={[{ label: t("FOOTER_Button_add_misc_employee") }]}>
          <Column sx={{ padding: "25px 22px" }}>
            <InputSearch
              width="65%"
              value={search}
              handleChange={setSearch}
              handleSearch={() => dispatchGetTMUsers(search)}
            />
            <VSpace length="15" />
            <Row align="flex-start" sx={{ width: "100%" }}>
              <Column sx={{ width: "50%" }}>
                {list.map((item) => (
                  <Row key={`TM-id-${item.id}`} sx={{ margin: "10px 0" }}>
                    <CheckboxBase
                      round={false}
                      label={`${item.nameZh} #${item.phone}`}
                      onChange={() => handleChange("tmUserId", "checkbox", item.id)}
                      checked={state.tmUserId.value === item.id}
                    />
                  </Row>
                ))}
              </Column>
              <Column sx={{ width: "275px" }}>
                {fieldsList.map((config, index) => (
                  <VFieldContainer
                    key={`modal-misc-charge-${config.id}`}
                    index={index}
                    width="150px"
                    value={state[config.id].value}
                    error={state[config.id].invalid}
                    required={state[config.id].isRequired}
                    handleChange={handleChange}
                    {...config}
                  />
                ))}
              </Column>
            </Row>
          </Column>
        </BasicTabs>
      </ModalOverlay>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

export default ModalTMMiscCharge
