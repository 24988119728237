// Components
import Box from "@mui/material/Box"
import { Text } from "@layout"
import { VFieldContainer } from "@components/common"
import { CheckboxListFilledText } from "@components/checkbox"
// Styles
// Libs
import { t } from "i18next"
import {
  yesNoOptionList,
  petOptionList,
  buildingTypeOptionList,
  districtList,
  partitionList,
  fieldStatus
} from "@constants"

const AddressContent = ({ state, handleChange, handleCheckBoxChange }) => {
  return (
    <>
      <Box sx={{ width: "190px", padding: "0px 20px 0px 0px" }}>
        <VFieldContainer
          index={0}
          width="150px"
          id="district"
          value={state.district.value}
          label={t("TEXT_COMMON_district")}
          type="select"
          list={districtList}
          handleChange={(id, type, data) => {
            handleChange(id, type, data)
            handleChange("partition", "select", "")
          }}
          required={state.district.isRequired}
          error={state.isSaveChecking && state.district.fieldStatus === fieldStatus.EMPTY}
        />
        <VFieldContainer
          index={1}
          width="150px"
          id="partition"
          value={state.partition.value}
          label={t("TEXT_COMMON_partition")}
          type="select"
          list={partitionList(state.district.value)}
          handleChange={handleChange}
          required={state.partition.isRequired}
          error={state.isSaveChecking && state.partition.fieldStatus === fieldStatus.EMPTY}
        />
        <VFieldContainer
          index={2}
          width="150px"
          id="estate"
          value={state.estate.value}
          label={t("TEXT_COMMON_estate")}
          type="input"
          handleChange={handleChange}
          required={state.estate.isRequired}
        />
        <VFieldContainer
          index={3}
          width="150px"
          id="street"
          value={state.street.value}
          label={t("TEXT_COMMON_street")}
          type="input"
          handleChange={handleChange}
          required={state.street.isRequired}
        />
        <VFieldContainer
          index={4}
          width="150px"
          id="building"
          value={state.building.value}
          label={t("TEXT_COMMON_building")}
          type="input"
          handleChange={handleChange}
          required={state.building.isRequired}
          error={state.isSaveChecking && state.building.fieldStatus === fieldStatus.EMPTY}
        />
        <VFieldContainer
          index={5}
          width="150px"
          id="block"
          value={state.block.value}
          label={t("TEXT_COMMON_block")}
          type="input"
          handleChange={handleChange}
          required={state.block.isRequired}
        />
        <VFieldContainer
          index={6}
          width="150px"
          id="floor"
          value={state.floor.value}
          label={t("TEXT_COMMON_floor")}
          type="input"
          handleChange={handleChange}
          required={state.floor.isRequired}
          error={state.isSaveChecking && state.floor.fieldStatus === fieldStatus.EMPTY}
        />
        <VFieldContainer
          index={7}
          width="150px"
          id="unit"
          value={state.unit.value}
          label={t("TEXT_COMMON_unit")}
          type="input"
          handleChange={handleChange}
          required={state.unit.isRequired}
          error={state.isSaveChecking && state.unit.fieldStatus === fieldStatus.EMPTY}
        />
      </Box>
      <Box sx={{ paddingLeft: "25px" }}>
        <CheckboxListFilledText
          label={t("MODAL_AddUser_Field_hasLift")}
          list={yesNoOptionList}
          id="hasLift"
          selected={state.hasLift.value}
          index={0}
          required={state.hasLift.isRequired}
          handleChange={handleCheckBoxChange}
        />
        <VFieldContainer
          index={1}
          width="150px"
          id="saleableArea"
          value={state.saleableArea.value}
          label={t("MODAL_AddUser_Field_area")}
          type="input"
          handleChange={handleChange}
          required={state.saleableArea.isRequired}
          error={state.isSaveChecking && state.saleableArea.fieldStatus === fieldStatus.EMPTY}
        />
        <CheckboxListFilledText
          label={t("MODAL_AddUser_Field_buildingType")}
          list={buildingTypeOptionList}
          id="buildingType"
          selected={state.buildingType.value}
          index={2}
          required={state.buildingType.isRequired}
          handleChange={handleCheckBoxChange}
        />
        <CheckboxListFilledText
          label={t("MODAL_AddUser_Field_hasPet")}
          list={yesNoOptionList}
          id="hasPet"
          selected={state.hasPet.value}
          index={3}
          required={state.hasPet.isRequired}
          handleChange={handleCheckBoxChange}
        />
        <CheckboxListFilledText
          label={t("MODAL_AddUser_Field_pet_types")}
          list={petOptionList}
          multiSelect={true}
          id="petTypes"
          selected={state.petTypes.value}
          index={4}
          handleChange={handleCheckBoxChange}
          required={state.petTypes.isRequired}
          error={state.isSaveChecking && state.petTypes.fieldStatus === fieldStatus.EMPTY}
        />
        <CheckboxListFilledText
          label={t("MODAL_AddUser_Field_pet_isCaged")}
          list={yesNoOptionList}
          id="petIsCaged"
          selected={state.petIsCaged.value}
          index={5}
          required={state.petIsCaged.isRequired}
          handleChange={handleCheckBoxChange}
        />
      </Box>
    </>
  )
}

export default AddressContent
