// Components
import Box from "@mui/material/Box"
import { Row, VSpace, Text } from "@layout"
import { VFieldContainer, OneLineVFieldContainer } from "@components/common"
import { CheckboxListFilledText } from "@components/checkbox"
import { InputText } from "@components/inputs"
import { SelectBase } from "@components/selects"
// Styles
// Libs
import { t } from "i18next"
import {
  yesNoOptionList,
  movingOptionList,
  physicalOptionList,
  mentalOptionList,
  titleOptionList,
  phoneOptionList,
  fieldStatus
} from "@constants"
import { fieldChange, checkBoxFieldChange } from "./reducers"
import { formatPhoneToField, formatPhoneToData } from "@utils/formatter"

const HealthCondition = ({ state, reducerDispatch }) => {
  const handleChange = (id, type, data) => {
    reducerDispatch(fieldChange({ id, data }))
  }

  const handleCheckBoxChange = ({ id, selected, data }) => {
    reducerDispatch(checkBoxFieldChange({ id, selected, data }))
  }

  return (
    <Box sx={{ padding: "10px" }}>
      <Row sx={{ alignItems: "start !important", justifyContent: "space-between" }}>
        <Box sx={{ width: "300px" }}>
          <CheckboxListFilledText
            index={0}
            label={t("MODAL_AddUser_Field_condition_moving")}
            list={movingOptionList}
            id="conditionMoving"
            selected={state.conditionMoving.value}
            handleChange={handleCheckBoxChange}
            required={state.conditionMoving.isRequired}
          />
          <VFieldContainer
            index={1}
            width="150px"
            id="weight"
            value={state.weight.value}
            label={t("MODAL_AddUser_Field_weight")}
            type="input"
            handleChange={handleChange}
            required={state.weight.isRequired}
            error={state.isSaveChecking && state.weight.fieldStatus === fieldStatus.EMPTY}
          />
          <CheckboxListFilledText
            index={2}
            label={t("MODAL_AddUser_Field_condition_physical")}
            multiSelect={true}
            list={physicalOptionList}
            id="conditionPhysical"
            selected={state.conditionPhysical.value}
            handleChange={handleCheckBoxChange}
            required={state.conditionPhysical.isRequired}
          />
          <CheckboxListFilledText
            index={3}
            label={t("MODAL_AddUser_Field_hasIncontinence")}
            list={yesNoOptionList}
            id="hasIncontinence"
            selected={state.hasIncontinence.value}
            handleChange={handleCheckBoxChange}
            required={state.hasIncontinence.isRequired}
          />
          <CheckboxListFilledText
            index={4}
            label={t("MODAL_AddUser_Field_condition_mental")}
            list={mentalOptionList}
            id="conditionMental"
            selected={state.conditionMental.value}
            handleChange={handleCheckBoxChange}
            required={state.conditionMental.isRequired}
          />
        </Box>
        <Box sx={{ width: "300px" }}>
          <VFieldContainer
            index={0}
            height="110px"
            width="300px"
            id={"remarks"}
            value={state.remarks.value}
            label={t("MODAL_AddUser_Field_remarks")}
            type="input-textarea"
            handleChange={handleChange}
            required={state.remarks.isRequired}
          />
          <CheckboxListFilledText
            index={1}
            label={t("MODAL_AddUser_Field_hasMaid")}
            list={yesNoOptionList}
            id="hasMaid"
            selected={state.hasMaid.value}
            handleChange={handleCheckBoxChange}
            required={state.hasMaid.isRequired}
          />
          <VSpace length={15} />
          <Text size="14px" weight="500">
            {t("MODAL_AddUser_Field_carer")}
          </Text>
          <OneLineVFieldContainer width="300px" index={2} label={t("MODAL_AddUser_Field_carer_name")}>
            <InputText
              sx={{ width: "225px", height: "29px", border: "none !important" }}
              value={state.carerName.value}
              onChange={(event) => handleChange("carerName", "input", event.target.value)}
            />
            <SelectBase
              width="75px"
              size="14px"
              weight="300"
              value={state.carerTitle.value}
              border="none"
              list={titleOptionList}
              placeHolder={t("TEXT_COMMON_title")}
              onSelect={(event) => handleChange("carerTitle", "select", event.value)}
            />
          </OneLineVFieldContainer>
          <OneLineVFieldContainer width="300px" index={3} label={t("MODAL_AddUser_Field_carer_phone")}>
            <SelectBase
              width="75px"
              size="14px"
              weight="300"
              value={formatPhoneToField(state.carerPhone.value).countryCode}
              border="none"
              list={phoneOptionList}
              onSelect={(event) =>
                handleChange(
                  "carerPhone",
                  "select",
                  formatPhoneToData(state.carerPhone.value, "countryCode", event.value)
                )
              }
            />
            <InputText
              sx={{ width: "225px", height: "29px", border: "none !important" }}
              value={formatPhoneToField(state.carerPhone.value).number}
              onChange={(event) =>
                handleChange(
                  "carerPhone",
                  "input",
                  formatPhoneToData(state.carerPhone.value, "number", event.target.value)
                )
              }
            />
          </OneLineVFieldContainer>
          <VFieldContainer
            index={3}
            width="150px"
            id="carerRelationship"
            value={state.carerRelationship.value}
            label={t("MODAL_AddUser_Field_relationship")}
            type="input"
            handleChange={handleChange}
          />
        </Box>
      </Row>
    </Box>
  )
}

export default HealthCondition
