import { useEffect, useState, useRef } from "react"
// Components
import ModalBase from "../modal-base"
import { TableList } from "@components/tables"
import { Text, Column, VSpace } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
// Libs
import { t } from "i18next"
import { forEach, cloneDeep } from "lodash"
import { getEven } from "@utils/formatter"

const ModalReviewImport = ({ open, onSave, onClose, data, readOnly }) => {
  const refModalContent = useRef()
  const [columnsConfig, setColumnsConfig] = useState(null)
  const [reviewState, setReviewState] = useState([])

  if (refModalContent.current?.clientWidth % 2) {
    refModalContent.current.style.maxWidth = `${getEven(refModalContent.current.clientWidth)}px`
  }

  useEffect(() => {
    if (open) {
      setReviewState([])
      setColumnsConfig(null)

      if (data) {
        let cloneData = cloneDeep(data)
        transferColumnData(cloneData)
      }
    }
  }, [open, data])

  const transferColumnData = (data) => {
    let columns = [],
      review = []

    if (data.length) {
      data.forEach((dataItem) => {
        forEach(dataItem.raw, (value, key) => {
          dataItem.raw[key] = value !== null ? value.toString().split("\n").join("<br/>") : ""
        })
        review.push({ ...dataItem.raw, errors: dataItem.errors || [] })
      })

      forEach(data[0].raw, (value, key) => {
        columns.push({
          fieldType: "html",
          fieldPath: key,
          fieldProps: {},
          label: key,
          checkError: (data) => {
            if (data.errors.length) {
              return data.errors.some((item) => item.key == key)
            }
            return false
          },
          minWidth: 137
        })
      })
    }

    setReviewState(review)
    setColumnsConfig(columns)
  }

  const handleSave = () => {
    onSave && onSave()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <>
      <ModalBase open={open} onClose={onClose}>
        <ModalContent ref={refModalContent} maxwidth="90%" expand={true} sx={{ border: "none !important" }}>
          <Close onClick={handleClose} />

          <Column sx={{ padding: "3px 15px 8px" }}>
            <Text weight="500">{t("MODAL_Review_Import_title")}</Text>
          </Column>

          {columnsConfig ? (
            <Column sx={{ padding: "0px 15px", maxHeight: "calc(100% - 37px)" }}>
              <TableList rows={reviewState} columns={columnsConfig} />
            </Column>
          ) : null}

          <VSpace length={5} />
          <ModalButtonWrapper color="yellow">
            {readOnly ? (
              <ButtonWithImage
                sx={{ width: "115px" }}
                textcolor="brown_1"
                label={t("TEXT_COMMON_confirm")}
                onClick={handleClose}
              />
            ) : (
              <>
                <ButtonWithImage
                  sx={{ width: "115px" }}
                  textcolor="brown_1"
                  src="no"
                  label={t("TEXT_COMMON_cancel")}
                  onClick={() => onClose()}
                />
                <ButtonWithImage
                  sx={{ width: "115px" }}
                  textcolor="brown_1"
                  src="save-black"
                  label={t("TEXT_COMMON_save")}
                  onClick={handleSave}
                />
              </>
            )}
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
    </>
  )
}

export default ModalReviewImport
