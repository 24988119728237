import Router from "next/router"
import isBrowser from "@utils/isBrowser"
import { forEach, isEmpty } from "lodash"

export const goCreateOrderByCustomer = (customerInfo) => {
  if (customerInfo?.customerId) {
    localStorage.setItem("orderCustomerState", JSON.stringify(customerInfo))
    Router.push({ pathname: "/order/create" })
  }
}

export const getQuerySearchParams = () => {
  if (isBrowser) {
    let queryObject = Router.query

    delete queryObject.tab

    if (isEmpty(queryObject)) {
      return null
    } else {
      forEach(queryObject, (value, key) => {
        try {
          queryObject[key] = JSON.parse(value)
        } catch (error) {
          console.log("not JSON")
        }
      })
      return queryObject
    }
  }
}

export const getJsonArray = (data) => {
  return JSON.stringify(data)
}
