import { Text } from "@layout"
import { isEmpty } from "@utils/validator"

const LabelField = ({ fieldPath, fieldProps, value }) => {
  let displayValue = isEmpty(value) ? "-" : value

  if (fieldProps?.format) {
    displayValue = isEmpty(value) ? "-" : fieldProps.format(value)
  }

  return (
    <Text weight="500" {...fieldProps?.style}>
      {displayValue}
    </Text>
  )
}

export default LabelField
