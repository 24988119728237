import { useEffect, useState, useReducer } from "react"
import { useDispatch } from "react-redux"
// Components
import Box from "@mui/material/Box"
import ModalBase from "../modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
import { InputTextArea, InputText } from "@components/inputs"
import { createModalInvoiceColumns } from "@utils/tableConfigs/modal"
import { TableList } from "@components/tables"
import { ModalMessage, ModalYesNo } from "@components/modals"
// Styles
// Libs
import { t } from "i18next"
import { getInvoicesById, putInvoicesById } from "@slices/invoices"
import { getCustomerDollarBalanceById } from "@slices/customers"
import mainReducer, { initialState, resetAll, setInvoicesByApi, inputFieldChange } from "./reducers"
import { payStateOptionListDoc } from "@constants"

const ModalInvoice = ({ open, onClose, onSave, selectedInvoiceId, customerId }) => {
  const dispatch = useDispatch()

  const [EHSDollar, setEHSDollar] = useState(0)
  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [invoiceDetail, reducerDispatch] = useReducer(mainReducer, initialState)
  // console.log(invoiceDetail)

  const [YesNoOpen, setYesNoOpen] = useState(false)

  useEffect(() => {
    if (open) {
      initialData()
      dispatchGetInvoicesById(selectedInvoiceId)
      dispatchGetEHSDollar(customerId)
    }
  }, [open])

  const initialData = () => {
    setEHSDollar(0)
    reducerDispatch(resetAll())
  }

  const dispatchGetInvoicesById = async (selectedInvoiceId) => {
    try {
      const res = await dispatch(getInvoicesById(selectedInvoiceId)).unwrap()
      reducerDispatch(setInvoicesByApi(res))
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const dispatchPutInvoicesById = async (selectedInvoiceId, invoiceDetail) => {
    try {
      const res = await dispatch(
        putInvoicesById({
          invoiceId: selectedInvoiceId,
          invoiceInfo: {
            ehsDollar: Number(invoiceDetail.dollarAmount) * 100,
            remarks: invoiceDetail.remarks
          }
        })
      ).unwrap()
      onClose()
      onSave()
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const dispatchGetEHSDollar = async (customerId) => {
    let res = await dispatch(getCustomerDollarBalanceById(customerId)).unwrap()

    if (res?.balance) {
      setEHSDollar(Number(res.balance) / 100)
    } else {
      setEHSDollar(0)
    }
  }

  const handleSaveClick = () => {
    if (invoiceDetail.dollarAmount > EHSDollar) {
      setErrorMessage({ message: t("MODAL_Invoice_dollar_not_enough") })
    } else {
      setYesNoOpen(true)
    }
  }

  const columnsConfig = createModalInvoiceColumns()

  const modalMessageConfig = {
    message,
    errorMessage,
    open: !!errorMessage || !!message,
    onClose: () => {
      setMessage(null)
      setErrorMessage(null)
    }
  }

  const modalYesNoConfig = {
    icon: "yellow-circle-add",
    title: t("MODAL_Invoice_check_pay_or_not"),
    content: "<br/>",
    open: YesNoOpen,
    onClose: () => setYesNoOpen(false),
    onSave: () => dispatchPutInvoicesById(selectedInvoiceId, invoiceDetail)
  }

  return (
    <>
      <ModalBase open={open} onClose={onClose}>
        <ModalContent maxwidth="990px" expand={true} sx={{ border: "none !important" }}>
          <Close onClick={onClose} />

          <Box sx={{ height: "100%" }}>
            <Box sx={{ padding: "0px 12px" }}>
              <Text size="28px" weight="500">
                {t("MODAL_Invoice_Field_invoice_number", { invoice: invoiceDetail.invoiceNo })}
              </Text>
              <VSpace length={18} />
            </Box>
            <Box sx={{ padding: "0px 12px", height: "calc(100% - 53px)", overflowY: "auto", overflowX: "hidden" }}>
              <Text size="14px" weight="500">
                {t("MODAL_Invoice_Field_customer", { name: invoiceDetail.customer.name })}
              </Text>
              <VSpace length={8} />
              <Text
                size="14px"
                weight="500"
                color={invoiceDetail.state === payStateOptionListDoc.F.id ? "green" : "red_dark"}
              >
                {t("MODAL_Invoice_Field_status", {
                  status: invoiceDetail.state ? payStateOptionListDoc[invoiceDetail.state].label : ""
                })}
              </Text>
              <VSpace length={14} />
              <Box sx={{ paddingRight: "90px" }}>
                <Row sx={{ justifyContent: "space-between" }}>
                  <Text size="14px" weight="500">
                    {t("MODAL_Invoice_Field_dollar_balance", { money: EHSDollar })}
                  </Text>
                  <Row>
                    <Text size="14px" weight="500">
                      {t("MODAL_Invoice_Field_use_dollar")}
                    </Text>
                    <HSpace length={63} />
                    <InputText
                      type="number"
                      value={invoiceDetail.dollarAmount}
                      borderradius="4px"
                      bordercolor="gray"
                      placeholder={t("TEXT_COMMON_Amount")}
                      sx={{ width: "125px", height: "25px" }}
                      onChange={(event) =>
                        reducerDispatch(inputFieldChange({ id: "dollarAmount", data: event.target.value }))
                      }
                      disabled={!invoiceDetail.isEditable}
                    />
                  </Row>
                </Row>
              </Box>
              <VSpace length={13} />
              <Text size="14px" weight="500">
                {t("MODAL_Invoice_Field_appointments")}
              </Text>
              <VSpace length={7} />
              <TableList columns={columnsConfig} rows={invoiceDetail.orders} maxHeight="unset" />
              <VSpace length={20} />
              <Text size="14px" weight="500">
                {t("MODAL_Invoice_Field_remark")}
              </Text>
              <VSpace length={7} />
              <InputTextArea
                width={"515px"}
                height={"100px"}
                value={invoiceDetail.remarks}
                onChange={(event) => reducerDispatch(inputFieldChange({ id: "remarks", data: event.target.value }))}
              />
              <VSpace length={150} />
            </Box>
          </Box>

          <ModalButtonWrapper justifycontent="flex-end">
            <ButtonWithImage
              {...buttonStyle}
              imgW="20px"
              imgH="20px"
              color="paper_brown_1"
              src="save-black"
              alt="save icon"
              onClick={() => handleSaveClick()}
            >
              {t("TEXT_COMMON_save")}
            </ButtonWithImage>
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
      <ModalYesNo {...modalYesNoConfig} />
    </>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "10px 22px",
  border: "transparent",
  imgW: "15px",
  imgH: "15px"
}

export default ModalInvoice
