import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { UsersApi } from "../apis"

export const getUsersAll = createAsyncThunk("users/getUsersAll", async (customerId) => {
  return await UsersApi.getUsersAll()
})

export const getUsers = createAsyncThunk("users/getUsers", async (payload) => {
  if (typeof payload === "object") {
    return await UsersApi.getUsers(payload.customerId, payload.keyword)
  } else {
    // only customerId
    return await UsersApi.getUsers(payload)
  }
})

export const postUser = createAsyncThunk(
  "users/postUser",
  async ({ customerId, userInfo }, { dispatch, rejectWithValue }) => {
    try {
      await await UsersApi.postUser({ customerId, userInfo })
      return await UsersApi.getUsers(customerId)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async ({ customerId, userId }, { rejectWithValue }) => {
    try {
      let res = await UsersApi.getUserById({ customerId, userId })
      if (res?.userId) {
        return res
      } else {
        throw new Error("Error while users/getUserById")
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const putUserById = createAsyncThunk(
  "users/putUserById",
  async ({ customerId, userId, userInfo }, { dispatch, rejectWithValue }) => {
    try {
      await UsersApi.putUserById({ customerId, userId, userInfo })
      return await UsersApi.getUsers(customerId)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postUserAddressById = createAsyncThunk(
  "users/postUserAddressById",
  async (payload, { rejectWithValue }) => {
    try {
      return await UsersApi.postUserAddressById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getLinkableUsers = createAsyncThunk("users/getLinkableUsers", async (payload) => {
  return await UsersApi.getLinkableUsers(payload)
})

export const postLinkUsers = createAsyncThunk("users/postLinkUsers", async (payload) => {
  return await UsersApi.postLinkUsers(payload)
})

const initialState = {}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = usersSlice.actions
export default usersSlice.reducer
