import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeFieldWithInvalid } from "@utils/makeField"
import { formatDateOnly } from "@utils/formatter"
import { forEach, every } from "lodash"
import { isEmpty } from "@utils/validator"

const makeFieldsBase = (payload) => {
  return {
    tmUserId: makeFieldWithInvalid(payload.tmUserId, "tmUserId", fieldStatus.VALID, true, false),
    amount: makeFieldWithInvalid(payload.amount, "amount", fieldStatus.VALID, true, false),
    transactionDate: makeFieldWithInvalid(payload.transactionDate, "transactionDate", fieldStatus.VALID, true, false),
    type: makeFieldWithInvalid(payload.type, "type", fieldStatus.VALID, true, false),
    remarks: makeFieldWithInvalid(payload.remarks, "remarks", fieldStatus.VALID, false, false)
  }
}

export const initialState = makeFieldsBase({
  tmUserId: "",
  amount: "",
  transactionDate: "",
  type: "",
  remarks: ""
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState,
  reducers: {
    defaultSetting(state, { payload }) {
      return payload ? makeFieldsBase(payload) : initialState
    },
    fieldChange(state, { payload }) {
      let { id, value } = payload

      state[id].value = value
      state[id].fieldStatus = isEmpty(value) ? fieldStatus.EMPTY : fieldStatus.VALID
    },
    validator(state, { payload }) {
      forEach(state, (item) => {
        if (item.isRequired) {
          if (item.fieldPath === "amount") {
            let regex = state.type.value === "MISC_TM" ? /^(\-)?\d+(\.\d{1})?$/ : /^\d+(\.\d{1})?$/
            state[item.fieldPath].fieldStatus = fieldStatus[regex.test(item.value) ? "VALID" : "ERROR"]
          } else if (isEmpty(item.value)) {
            state[item.fieldPath].fieldStatus = fieldStatus.EMPTY
          }
          state[item.fieldPath].invalid = item.fieldStatus !== fieldStatus.VALID
        }
      })
    }
  }
})

export const validateCheck = (state) => {
  return every(state, (item) => {
    !(item.isRequired && !isEmpty(item.value))
    if (item.isRequired) {
      if (item.fieldPath === "amount") {
        let regex = state.type.value === "MISC_TM" ? /^(\-)?\d+(\.\d{1})?$/ : /^\d+(\.\d{1})?$/
        return regex.test(item.value)
      } else {
        return !isEmpty(item.value)
      }
    } else {
      return true
    }
  })
}
export const convertToPayload = (state) => {
  let payload = {}
  forEach(state, ({ value }, key) => {
    if (key === "transactionDate") {
      payload[key] = formatDateOnly(value)
    } else if (key === "amount") {
      payload[key] = Math.round(Number(value) * 100)
    } else {
      payload[key] = value
    }
  })
  return payload
}

export const { fieldChange, validator, defaultSetting } = mainReducer.actions
export default mainReducer.reducer
