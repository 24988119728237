import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
// Components
import ModalBase from "./modal-base"
import { Row, Text, Divider, Column } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { VFieldContainer } from "@components/common"
import { ModalContent, ModalButtonWrapper, Close } from "./common"
import { ModalMessage } from "@components/modals"
// Styles
// Libs
import { t } from "i18next"
import { isEmpty } from "@utils/validator"
import { formatDateOnly } from "@utils/formatter"
import {
  getExportCustomers,
  getExportUsers,
  getExportInvoices,
  getExportCheques,
  getExportTMUsers,
  getExportTMRosters,
  getExportTMRemarks,
  getExportTMApplications,
  getExportTM418Requests,
  getExportServices,
  getExportDollarLogs,
  getExportReviews,
  getExportBlacklists,
  getExportDiscountRules,
  getExportSMS,
  getExportPushNotifications,
  getExportNotices,
  getExportFaqs,
  getExportOrders,
  getExportTMMiscCharges
} from "@slices/export"

const ModalExportTime = ({ open, onClose, type, params = {} }) => {
  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [exportClick, setExportClick] = useState(false)

  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (open) {
      setStartDate("")
      setEndDate("")
      setExportClick(false)
    }
  }, [open])

  const handleSelectChange = (id, type, value) => {
    if (value) {
      switch (id) {
        case "startDate":
          setStartDate(formatDateOnly(value))
          break
        case "endDate":
          setEndDate(formatDateOnly(value))
          break
      }
    }
  }

  const handleExportClick = () => {
    setExportClick(true)

    let paramsResult = { startDate, endDate, ...params },
      dispatchGetExport = getExportFn(type)
    if (startDate && endDate && dispatchGetExport) {
      dispatch(dispatchGetExport(paramsResult))
        .unwrap()
        .then((res) => {
          setMessage(t("FOOTER_export_success_message"))
        })
        .catch((error) => {
          if (error?.data?.message) {
            setErrorMessage({ message: error.data.message })
          }
        })
    }
  }

  const modalMessageConfig = {
    message,
    errorMessage,
    open: !isEmpty(errorMessage) || !isEmpty(message),
    onClose: () => {
      if (!isEmpty(message)) {
        onClose()
      }
      setMessage(null)
      setErrorMessage(null)
    }
  }

  return (
    <>
      <ModalBase open={open}>
        <ModalContent maxwidth="346px" sx={{ border: "none !important" }}>
          <Close onClick={onClose} />
          <Column sx={{ padding: "1px 30px 5px" }}>
            <Text weight="500">{t("MODAL_Export_Time_title")}</Text>
          </Column>
          <Divider color="yellow" />

          <Row sx={{ width: "100%", paddingTop: "23px", paddingBottom: "32px", justifyContent: "center", gap: "18px" }}>
            <VFieldContainer
              width="130px"
              id={"startDate"}
              value={startDate}
              label={t("MODAL_Export_Time_startDate")}
              type="datePicker"
              error={exportClick && !startDate}
              handleChange={handleSelectChange}
            />
            <VFieldContainer
              width="130px"
              id={"endDate"}
              value={endDate}
              label={t("MODAL_Export_Time_endDate")}
              type="datePicker"
              error={exportClick && !endDate}
              handleChange={handleSelectChange}
            />
          </Row>

          <ModalButtonWrapper color="yellow">
            <ButtonWithImage
              sx={{ width: "115px" }}
              textcolor="brown_1"
              src="download"
              label={t("MODAL_Export_Time_button_export")}
              onClick={handleExportClick}
            />
          </ModalButtonWrapper>
        </ModalContent>
      </ModalBase>
      <ModalMessage {...modalMessageConfig} />
    </>
  )
}

const getExportFn = (type) => {
  switch (type) {
    case "customer-list":
      return getExportCustomers
    case "customer-linkedUser":
      return getExportUsers
    case "customer-payment-notification-list":
      return getExportInvoices
    case "customer-cheque-list":
      return getExportCheques
    case "tmUser-list":
      return getExportTMUsers
    case "tmUser-report-list":
      return getExportTMRosters
    case "order-list":
      return getExportOrders
    case "order-tmRemark-list":
      return getExportTMRemarks
    case "tmUser-application-list":
      return getExportTMApplications
    case "tmUser-418-list":
      return getExportTM418Requests
    case "tm-misc-charges":
      return getExportTMMiscCharges
    case "eCommerce-services-list":
      return getExportServices
    case "customer-accountBalance-list":
      return getExportDollarLogs
    case "customer-rating-list":
      return getExportReviews
    case "customer-blackWhiteList":
      return getExportBlacklists
    case "eCommerce-discount-list":
      return getExportDiscountRules
    case "message-sms-list":
      return getExportSMS
    case "message-push-list":
      return getExportPushNotifications
    case "message-notice-list":
      return getExportNotices
    case "system-faq-list":
      return getExportFaqs
  }
  return null
}

export default ModalExportTime
