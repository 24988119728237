import { createSlice } from "@reduxjs/toolkit"
import { fieldStatus } from "@constants"
import { makeFieldWithInvalid } from "@utils/makeField"
import { forEach, every } from "lodash"
import { isEmpty } from "@utils/validator"

const makeFieldsBase = (payload) => {
  return {
    name: makeFieldWithInvalid(payload.name, "name", fieldStatus.VALID, true, false),
    imageUrl: makeFieldWithInvalid(payload.imageUrl, "imageUrl", fieldStatus.VALID, true, false),
    roles: makeFieldWithInvalid(payload.roles, "roles", fieldStatus.VALID, true, false),
    responsibility: makeFieldWithInvalid(payload.responsibility, "responsibility", fieldStatus.VALID, true, false),
    order: makeFieldWithInvalid(payload.order, "order", fieldStatus.VALID, false, false),
    published: makeFieldWithInvalid(payload.published, "published", fieldStatus.VALID, false, false)
  }
}

export const initialState = makeFieldsBase({
  name: "",
  imageUrl: "",
  roles: "",
  responsibility: "",
  order: "",
  published: ""
})

const mainReducer = createSlice({
  name: "mainReducer",
  initialState,
  reducers: {
    defaultSetting(state, { payload }) {
      return payload ? makeFieldsBase(payload) : initialState
    },
    fieldChange(state, { payload }) {
      let { id, type, value } = payload
      state[id].value = value
      state[id].fieldStatus = fieldStatus.VALID
      if (type === "file" && value) state[id].invalid = false
    },
    validator(state, { payload }) {
      forEach(state, (item, path) => {
        if (item.isRequired) {
          if (item.value === "") {
            state[path].fieldStatus = fieldStatus.EMPTY
          }
        }
        state[path].invalid = item.fieldStatus !== fieldStatus.VALID
      })
    }
  }
})

export const validateCheck = (state) => {
  return every(state, ({ value, isRequired }) => (isRequired ? !isEmpty(value) : true))
}
export const convertToPayload = (state) => {
  let payload = {}
  forEach(state, ({ value }, key) => {
    if (value) {
      payload[key] = value
    }
  })
  return payload
}

export const { fieldChange, validator, defaultSetting } = mainReducer.actions
export default mainReducer.reducer
