// Components
import Box from "@mui/material/Box"
import { VFieldContainer, OneLineVFieldContainer } from "@components/common"
import { InputText } from "@components/inputs"
import { SelectBase } from "@components/selects"
// Styles
// Libs
import { t } from "i18next"
import { titleOptionList, phoneOptionList, fieldStatus } from "@constants"
import { fieldChange } from "./reducers"
import { formatPhoneToField, formatPhoneToData } from "@utils/formatter"
import { isInvalidPhone } from "@utils/validator"

const BasicInfo = ({ state, reducerDispatch }) => {
  const handleChange = (id, type, data) => {
    reducerDispatch(fieldChange({ id, data }))
  }

  return (
    <Box sx={{ padding: "10px" }}>
      <VFieldContainer
        index={0}
        width="200px"
        id={"name"}
        value={state.name.value}
        label={t("MODAL_AddUser_Field_name")}
        type="input"
        handleChange={handleChange}
        required={state.name.isRequired}
        error={state.isSaveChecking && state.name.fieldStatus === fieldStatus.EMPTY}
      />
      <VFieldContainer
        index={1}
        width="200px"
        id={"title"}
        value={state.title.value}
        label={t("MODAL_AddUser_Field_title")}
        type="select"
        list={titleOptionList}
        handleChange={handleChange}
        required={state.title.isRequired}
        error={state.isSaveChecking && state.title.fieldStatus === fieldStatus.EMPTY}
      />
      <OneLineVFieldContainer
        width="200px"
        index={2}
        label={t("MODAL_AddUser_Field_phone")}
        required={state.phone.isRequired}
        error={state.isSaveChecking && isInvalidPhone(state.phone.value)}
      >
        <SelectBase
          width="75px"
          size="14px"
          weight="300"
          value={formatPhoneToField(state.phone.value).countryCode}
          border="none"
          list={phoneOptionList}
          onSelect={(event) =>
            handleChange("phone", "select", formatPhoneToData(state.phone.value, "countryCode", event.value))
          }
        />
        <InputText
          sx={{ width: "125px", height: "29px", border: "none !important" }}
          value={formatPhoneToField(state.phone.value).number}
          onChange={(event) =>
            handleChange("phone", "input", formatPhoneToData(state.phone.value, "number", event.target.value))
          }
        />
      </OneLineVFieldContainer>
      <VFieldContainer
        index={3}
        width="200px"
        id={"yob"}
        value={state.yob.value}
        label={t("MODAL_AddUser_Field_yob")}
        type="input"
        handleChange={handleChange}
        required={state.yob.isRequired}
        error={state.isSaveChecking && state.yob.fieldStatus === fieldStatus.EMPTY}
      />
      <VFieldContainer
        index={3}
        width="200px"
        id={"relationship"}
        value={state.relationship.value}
        label={t("MODAL_AddUser_Field_relationship")}
        type="input"
        required={state.relationship.isRequired}
        handleChange={handleChange}
      />
    </Box>
  )
}

export default BasicInfo
