import { useState, useEffect, useReducer } from "react"
// Components
import Box from "@mui/material/Box"
import ModalBase from "../modal-base"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ButtonWithImage } from "@components/buttons"
import { ModalContent, ModalButtonWrapper, Close } from "../common"
import { ImgBase } from "@components/images"
import { VFieldContainer } from "@components/common"
// Styles
// Libs
import { t } from "i18next"
import mainReducer, {
  initialState,
  resetAll,
  inputFieldChange,
  isSaveChecking,
  convertToPayload,
  handleSaveChecking
} from "./reducers"
import { fieldStatus } from "@constants"

const ModalAddCheque = ({ open, onClose, onSave }) => {
  const [state, reducerDispatch] = useReducer(mainReducer, initialState)
  console.log(state)

  useEffect(() => {
    reducerDispatch(resetAll())
  }, [open])

  const handleInputChange = (id, type, data) => {
    reducerDispatch(inputFieldChange({ id, data }))
  }

  const handleSaveClick = () => {
    reducerDispatch(isSaveChecking(true))
    
    if (handleSaveChecking(state)) {
      onSave && onSave(convertToPayload(state))
      handleCloseClick()
    }
  }

  const handleCloseClick = () => {
    onClose && onClose()
  }

  return (
    <ModalBase open={open} onClose={onClose}>
      <ModalContent maxwidth="720px" sx={{ border: "none !important" }}>
        <Close onClick={handleCloseClick} />

        <Box sx={{ padding: "20px 30px 15px 30px" }}>
          <Row>
            <ImgBase src={"yellow-circle-add"} alt={"exceed 418"} width={"27px"} height={"27px"} />
            <HSpace length={20} />
            <Text size="18px" weight="400">
              {t("MODAL_Add_Cheque_title")}
            </Text>
          </Row>
          <VSpace length={12} />
          <Row sx={{ gap: "42px" }}>
            <VFieldContainer
              index={0}
              width="150px"
              id={"bank"}
              value={state.bank.value}
              label={t("MODAL_Add_Cheque_bank_name")}
              type="input"
              handleChange={handleInputChange}
              error={state.isSaveChecking && state.bank.fieldStatus !== fieldStatus.VALID}
              required={state.bank.isRequired}
            />
            <VFieldContainer
              index={0}
              width="245px"
              inputType="number"
              id={"number"}
              value={state.number.value}
              label={t("MODAL_Add_Cheque_cheque_number")}
              type="input"
              handleChange={handleInputChange}
              error={state.isSaveChecking && state.number.fieldStatus !== fieldStatus.VALID}
              required={state.number.isRequired}
            />
            <VFieldContainer
              index={0}
              width="150px"
              inputType="number"
              id={"amount"}
              value={state.amount.value}
              label={t("MODAL_Add_Cheque_cheque_money")}
              type="input"
              handleChange={handleInputChange}
              regex={(val) => /^[0-9]+(.[0-9]{0,1})?$/.test(val)}
              error={state.isSaveChecking && state.amount.fieldStatus !== fieldStatus.VALID}
              required={state.amount.isRequired}
            />
          </Row>
          <VFieldContainer
            index={1}
            height="62px"
            width="630px"
            id={"remarks"}
            value={state.remarks.value}
            label={t("MODAL_Add_Cheque_remark")}
            type="input-textarea"
            handleChange={handleInputChange}
          />
        </Box>

        <ModalButtonWrapper color="gray" justifycontent="flex-end">
          <ButtonWithImage
            {...buttonStyle}
            imgW="15px"
            imgH="15px"
            srcx="save-black"
            alt="save icon"
            onClick={handleSaveClick}
          >
            {t("TEXT_COMMON_save")}
          </ButtonWithImage>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalBase>
  )
}

const buttonStyle = {
  weight: 500,
  borderradius: "13px",
  padding: "7px 28px",
  border: "transparent"
}

export default ModalAddCheque
