import { Fragment } from "react"
import { HSpace, Row, VSpace, Text, FieldLabel } from "@layout"
import { ButtonBase } from "@components/buttons"
import { cloneDeep } from "lodash"

const CheckboxListFilledText = ({
  label,
  index,
  list,
  id,
  multiSelect,
  selected = [],
  handleChange,
  error,
  disabled,
  required = false
}) => {
  return (
    <>
      {!!index && <VSpace length={15} />}
      {label ? (
        <>
          <FieldLabel size="14px" weight="500" required={required}>
            {label}
          </FieldLabel>
          <VSpace length={5} />
        </>
      ) : null}
      <Row sx={{ gap: "6px 10px", flexWrap: "wrap", pointerEvents: disabled ? "none" : "auto" }}>
        {list.map((listItem) => (
          <Fragment key={`${id}_${listItem.id}`}>
            {multiSelect
              ? handleMultiItem({ id, selected, listItem, handleChange, error })
              : handleSingleItem({ id, selected, listItem, handleChange, error })}
          </Fragment>
        ))}
      </Row>
    </>
  )
}

const handleMultiItem = ({ id, selected, listItem, handleChange, error }) => {
  let cloneSelected = cloneDeep(selected)

  return (
    <>
      {selected.includes(listItem.id) ? (
        <ButtonBase
          {...buttonStyle}
          label={listItem.label}
          color="primary_dark"
          error={error ? "true" : "false"}
          onClick={() => {
            let filterData = cloneSelected.filter((item) => item !== listItem.id)
            handleChange({ id, selected: true, data: filterData })
          }}
        />
      ) : (
        <ButtonBase
          {...buttonStyle}
          label={listItem.label}
          variant="outlined"
          textcolor="primary"
          error={error ? "true" : "false"}
          onClick={() => {
            cloneSelected.push(listItem.id)
            handleChange({ id, selected: false, data: cloneSelected })
          }}
        />
      )}
    </>
  )
}

const handleSingleItem = ({ id, selected, listItem, handleChange, error }) => {
  return (
    <>
      {selected === listItem.id ? (
        <ButtonBase
          {...buttonStyle}
          label={listItem.label}
          color="primary_dark"
          error={error ? "true" : "false"}
          onClick={() => handleChange({ id, selected: true, data: listItem.id })}
        />
      ) : (
        <ButtonBase
          {...buttonStyle}
          label={listItem.label}
          variant="outlined"
          textcolor="primary"
          error={error ? "true" : "false"}
          onClick={() => handleChange({ id, selected: false, data: listItem.id })}
        />
      )}
    </>
  )
}

const buttonStyle = {
  padding: "5px 22px",
  weight: "500",
  border: "gray_3",
  borderradius: "2px"
}

export default CheckboxListFilledText
