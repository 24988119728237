import { Fragment } from "react"
import usePagination from "@mui/material/usePagination"
// Components
import { ButtonOutlined } from "@components/buttons"
import { Row, Text, HSpace } from "@layout"
import { List } from "./pagination.styles"
// Libs
import { t } from "i18next"

const rowsPerPageList = [50, 100, 500]

const Pagination = ({
  currentPage,
  numResults,
  rowsPerPage,
  handleShowSetting,
  handleResetClick,
  handleRowsChange,
  handlePageChange,
  isDetailPage = false
}) => {
  const { items } = usePagination({
    count: Math.ceil(numResults / rowsPerPage),
    defaultPage: currentPage,
    showFirstButton: true,
    showLastButton: true,
    onChange: handlePageChange
  })

  return (
    <Row sx={{ justifyContent: "space-between" }}>
      {isDetailPage ? null : (
        <Text color="blue_light" weight={500} cursor={"pointer"} onClick={() => handleShowSetting()}>
          {t("TEXT_COMMON_show_setting")}
        </Text>
      )}
      <Row>
        <Row sx={{ gap: "20px" }}>
          <ButtonOutlined label={t("TEXT_COMMON_reset_all")} onClick={() => handleResetClick("ALL")} />
          <ButtonOutlined label={t("TEXT_COMMON_reset_sort")} onClick={() => handleResetClick("SORT")} />
          {isDetailPage ? null : (
            <ButtonOutlined label={t("TEXT_COMMON_reset_filter")} onClick={() => handleResetClick("FILTER")} />
          )}
        </Row>
        <HSpace length={20} />
        <Row sx={{ gap: "14px" }}>
          {rowsPerPageList.map((item, index) => (
            <Fragment key={`rowsPerPage_${item}`}>
              {index ? <Text color="gray">{"|"}</Text> : null}
              <Text
                color={rowsPerPage === item ? "primary" : "blue_light"}
                weight={500}
                cursor={"pointer"}
                onClick={() => handleRowsChange(item)}
              >
                {item}
              </Text>
            </Fragment>
          ))}
        </Row>
        <HSpace length={20} />
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = (
                <Text color="blue_light" weight={500} {...item}>
                  {"..."}
                </Text>
              )
            } else if (type === "page") {
              children = (
                <Text color={selected ? "primary" : "blue_light"} weight={500} cursor={"pointer"} {...item}>
                  {page}
                </Text>
              )
            } else {
              children = (
                <Text
                  color="blue_light"
                  weight={500}
                  cursor={"pointer"}
                  {...item}
                  style={{
                    pointerEvents: item.disabled ? "none" : "auto"
                  }}
                >
                  {effectButton(type)}
                </Text>
              )
            }

            return <li key={index}>{children}</li>
          })}
        </List>
      </Row>
    </Row>
  )
}

const effectButton = (type) => {
  switch (type) {
    case "first":
      return "|<<"
    case "previous":
      return "<"
    case "next":
      return ">"
    case "last":
      return ">>|"
  }
}

export default Pagination
